import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Users, Calendar, ChevronDown, ChevronUp, TrendingUp, UserRound, Activity, Filter, Mail, Phone, AlertCircle } from 'lucide-react';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import SEO from '../components/SEO';

const Teams = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { leagueId } = useParams();
    const [leagueName, setLeagueName] = useState('');
    const [groupATeams, setGroupATeams] = useState([]);
    const [groupBTeams, setGroupBTeams] = useState([]);
    const [teamMatches, setTeamMatches] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeFilter, setActiveFilter] = useState('all');

    useEffect(() => {
        const pathParts = location.pathname.split('/');
        const teamId = pathParts[pathParts.length - 1];

        // If we're on a specific team URL, redirect to the teams page with search param
        if (pathParts.length > 4 && !isNaN(teamId)) {
            navigate(`/league/${leagueId}/teams?expandedTeam=${teamId}`, { replace: true });
        }
    }, [location, leagueId, navigate]);

    useEffect(() => {
        const fetchLeagueName = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/`);
                if (!response.ok) throw new Error('Failed to fetch league name');
                const data = await response.json();
                setLeagueName(data.name);
            } catch (error) {
                console.error('Error fetching league name:', error);
                setError('Failed to fetch league name');
            }
        };

        const fetchTeamsInGroup = async (group) => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/teams/?group=${group}`);
                if (!response.ok) throw new Error(`Failed to fetch teams for group ${group}`);
                const data = await response.json();
                // Sortera data innan vi returnerar det
                return data.sort((a, b) => {
                    const nameA = a.team?.name || '';
                    const nameB = b.team?.name || '';
                    return nameA.localeCompare(nameB);
                });
            } catch (error) {
                console.error(`Error fetching teams for group ${group}:`, error);
                return [];
            }
        };

        const fetchMatches = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/matches/`);
                if (!response.ok) throw new Error('Failed to fetch matches');
                const data = await response.json();

                const matchesByTeam = {};
                data.forEach(match => {
                    if (!matchesByTeam[match.team1_name]) matchesByTeam[match.team1_name] = [];
                    if (!matchesByTeam[match.team2_name]) matchesByTeam[match.team2_name] = [];

                    const matchData = {
                        id: match.id,
                        date: match.date,
                        home_team: { name: match.team1_name },
                        away_team: { name: match.team2_name },
                        home_score: match.team1_score,
                        away_score: match.team2_score,
                        status: match.team1_score !== null && (match.team1_score > 0 || match.team2_score > 0) ? 'completed' : 'scheduled',
                        round_number: match.round_number
                    };

                    matchesByTeam[match.team1_name].push(matchData);
                    matchesByTeam[match.team2_name].push(matchData);
                });

                // Add this sorting code before setting the state
                // Sort all matches by date (oldest to newest)
                Object.keys(matchesByTeam).forEach(teamName => {
                    matchesByTeam[teamName].sort((a, b) => new Date(a.date) - new Date(b.date));
                });

                setTeamMatches(matchesByTeam);
            } catch (error) {
                console.error('Error fetching matches:', error);
            }
        };

        const fetchAllData = async () => {
            try {
                setLoading(true);
                await fetchLeagueName();
                const [groupA, groupB] = await Promise.all([
                    fetchTeamsInGroup('A'),
                    fetchTeamsInGroup('B')
                ]);
                setGroupATeams(groupA);
                setGroupBTeams(groupB);
                await fetchMatches();
            } catch (error) {
                console.error('Error fetching all data:', error);
                setError('Failed to load team data');
            } finally {
                setLoading(false);
            }
        };

        if (leagueId) {
            fetchAllData();
        }
    }, [leagueId]);

    const calculateTeamForm = (matches, teamName) => {
        if (!matches) return [];

        // Filter out unplayed matches (where scores are 0-0 or null)
        const playedMatches = matches.filter(match =>
            match.status === 'completed' &&
            (match.home_score !== 0 || match.away_score !== 0) &&
            match.home_score !== null &&
            match.away_score !== null
        );

        // Sort matches by date, most recent first
        const sortedMatches = playedMatches.sort((a, b) =>
            new Date(b.date) - new Date(a.date)
        );

        // Take only the 5 most recent matches
        const recentMatches = sortedMatches.slice(0, 5);

        // Calculate form for each match
        return recentMatches.map(match => {
            const isHomeTeam = match.home_team.name === teamName;
            const teamScore = isHomeTeam ? match.home_score : match.away_score;
            const opponentScore = isHomeTeam ? match.away_score : match.home_score;

            if (teamScore > opponentScore) return 'W';
            if (teamScore < opponentScore) return 'L';
            return 'D';
        }).reverse();
    };

    const getFormColor = (result) => {
        switch (result) {
            case 'W': return 'bg-green-500';
            case 'L': return 'bg-red-500';
            case 'D': return 'bg-yellow-500';
            default: return 'bg-gray-300';
        }
    };

    const TeamCard = ({ team }) => {
        const [activeTab, setActiveTab] = useState('members');
        const teamForm = calculateTeamForm(teamMatches[team.team.name], team.team.name);
        const searchParams = new URLSearchParams(location.search);
        const expandedTeamId = searchParams.get('expandedTeam');
        const isExpanded = expandedTeamId === team.team.id.toString();
        const teamRef = useRef(null);
        const [isMounted, setIsMounted] = useState(false);

        useEffect(() => {
            setIsMounted(true);
            return () => setIsMounted(false);
        }, []);

        useEffect(() => {
            if (isExpanded && isMounted && teamRef.current) {
                const scrollToTeam = () => {
                    try {
                        teamRef.current?.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center'
                        });
                    } catch (error) {
                        console.error('Scroll error:', error);
                    }
                };

                // Längre timeout för att säkerställa att DOM är redo
                const timeoutId = setTimeout(scrollToTeam, 300);
                return () => clearTimeout(timeoutId);
            }
        }, [isExpanded, isMounted]);

        const toggleExpand = () => {
            // Update URL when expanding/collapsing
            if (!isExpanded) {
                // Add team ID to URL when expanding
                searchParams.set('expandedTeam', team.team.id);
                navigate(`/league/${leagueId}/teams?${searchParams.toString()}`);
            } else {
                // Remove team ID from URL when collapsing
                searchParams.delete('expandedTeam');
                navigate(`/league/${leagueId}/teams?${searchParams.toString()}`);
            }
        };

        const MatchCard = ({ match, teamName }) => (
            <div className="bg-white rounded-lg shadow border border-gray-100 p-4 hover:shadow-md transition-all">
                {/* Match date */}
                <div className="flex justify-between items-center mb-3 text-xs text-gray-500">
                    <span>
                        {new Date(match.date).toLocaleDateString('sv-SE')}
                    </span>
                    <span className="px-2 py-1 bg-gray-100 rounded-full">
                        Omgång {match.round_number || '-'}
                    </span>
                </div>

                {/* Teams and scores */}
                <div className="flex flex-col">
                    <div className="flex justify-between items-center mb-2">
                        <div className={`font-medium ${match.home_team.name === teamName ? 'text-blue-700' : 'text-gray-700'}`}>
                            {match.home_team.name}
                        </div>
                        <div className="font-bold text-lg px-3 py-1 bg-gray-100 rounded-md">
                            {match.status === 'completed' ? match.home_score : '-'}
                        </div>
                    </div>

                    <div className="flex justify-between items-center">
                        <div className={`font-medium ${match.away_team.name === teamName ? 'text-blue-700' : 'text-gray-700'}`}>
                            {match.away_team.name}
                        </div>
                        <div className="font-bold text-lg px-3 py-1 bg-gray-100 rounded-md">
                            {match.status === 'completed' ? match.away_score : '-'}
                        </div>
                    </div>
                </div>

                {/* Status */}
                <div className="mt-3 flex justify-end">
                    <span className={`text-xs px-2 py-1 rounded-full ${match.status === 'completed'
                        ? 'bg-green-100 text-green-700'
                        : 'bg-blue-100 text-blue-700'
                        }`}>
                        {match.status === 'completed' ? 'Spelad' : 'Kommande'}
                    </span>
                </div>
            </div>
        );

        return (
            <div ref={teamRef} className="bg-white rounded-xl shadow-lg overflow-hidden">
                <button
                    onClick={toggleExpand}
                    className="w-full p-5 flex items-center justify-between hover:bg-gray-50 transition-colors"
                >
                    <div className="flex items-center">
                        <div className="bg-gray-100 rounded-full w-10 h-10 flex items-center justify-center mr-3">
                            <span className="font-semibold text-gray-700">{team.team.name?.charAt(0) || 'T'}</span>
                        </div>
                        <div>
                            <h3 className="text-xl font-bold text-gray-900">
                                {team.team.name} <span className="text-sm font-normal text-gray-400">- {team.groupid || team.group || 'A'}</span>
                            </h3>
                        </div>
                    </div>

                    {isExpanded ? (
                        <ChevronUp className="w-5 h-5 text-gray-500" />
                    ) : (
                        <ChevronDown className="w-5 h-5 text-gray-500" />
                    )}
                </button>

                {isExpanded && (
                    <div className="p-6 border-t border-gray-100">
                        {/* Custom Tabs */}
                        <div className="mb-6">
                            <div className="flex border-b border-gray-200">
                                <button
                                    onClick={() => setActiveTab('members')}
                                    className={`flex items-center gap-2 px-4 py-2 font-medium text-sm ${activeTab === 'members'
                                        ? 'border-b-2 border-blue-500 text-blue-600'
                                        : 'text-gray-500 hover:text-gray-700'
                                        }`}
                                >
                                    <Users className="w-4 h-4" />
                                    Medlemmar
                                </button>
                                <button
                                    onClick={() => setActiveTab('matches')}
                                    className={`flex items-center gap-2 px-4 py-2 font-medium text-sm ${activeTab === 'matches'
                                        ? 'border-b-2 border-blue-500 text-blue-600'
                                        : 'text-gray-500 hover:text-gray-700'
                                        }`}
                                >
                                    <Calendar className="w-4 h-4" />
                                    Matcher
                                </button>
                            </div>
                        </div>

                        {/* Tab Content */}
                        <div className="mt-4">
                            {activeTab === 'members' && (
                                <div>
                                    <div className="mb-4 bg-blue-50 p-3 rounded-lg">
                                        <h4 className="font-medium text-blue-800 mb-1 flex items-center">
                                            <UserRound className="w-4 h-4 mr-2" />
                                            Lagmedlemmar
                                        </h4>
                                        <p className="text-sm text-blue-700">
                                            {team.team.members?.length || 0} registrerade spelare
                                        </p>
                                    </div>

                                    {team.team.members?.length > 0 ? (
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                                            {team.team.members.map(member => (
                                                <div
                                                    key={member.id}
                                                    className="bg-gray-50 rounded-lg p-3 hover:bg-gray-100 transition-colors"
                                                >
                                                    <div className="flex items-start">
                                                        <div className="bg-gray-200 rounded-full w-10 h-10 flex items-center justify-center mr-3 flex-shrink-0">
                                                            <span className="font-semibold text-gray-700">
                                                                {member.first_name?.charAt(0) || ''}{member.last_name?.charAt(0) || ''}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <p className="font-medium text-gray-900">
                                                                {member.first_name} {member.last_name}
                                                            </p>
                                                            {member.role && (
                                                                <p className="text-sm text-gray-500">{member.role}</p>
                                                            )}
                                                            <div className="mt-1 space-y-1">
                                                                {member.email && (
                                                                    <div className="flex items-center text-xs text-gray-500">
                                                                        <Mail className="w-3 h-3 mr-1" />
                                                                        {member.email}
                                                                    </div>
                                                                )}
                                                                {member.phone && (
                                                                    <div className="flex items-center text-xs text-gray-500">
                                                                        <Phone className="w-3 h-3 mr-1" />
                                                                        {member.phone}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="text-center py-6 text-gray-500">
                                            Ingen information om lagmedlemmar tillgänglig
                                        </div>
                                    )}
                                </div>
                            )}

                            {activeTab === 'matches' && (
                                <div className="space-y-5">
                                    {/* Form Display */}
                                    <div className="flex items-center gap-3 mb-4 bg-gray-50 p-4 rounded-lg">
                                        <TrendingUp className="w-5 h-5 text-blue-600" />
                                        <div>
                                            <h4 className="font-medium text-gray-900 mb-1">Senaste form</h4>
                                            <div className="flex gap-1">
                                                {teamForm.length > 0 ? (
                                                    teamForm.map((result, index) => (
                                                        <span
                                                            key={index}
                                                            className={`w-7 h-7 ${getFormColor(result)} rounded-full flex items-center justify-center text-white text-sm font-bold`}
                                                        >
                                                            {result}
                                                        </span>
                                                    ))
                                                ) : (
                                                    <span className="text-sm text-gray-500">Ingen matchhistorik</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Recent Matches */}
                                    <div>
                                        <h4 className="font-medium text-gray-900 mb-3 flex items-center">
                                            <Activity className="w-4 h-4 mr-2 text-blue-600" />
                                            Matchhistorik
                                        </h4>

                                        {teamMatches[team.team.name]?.length > 0 ? (
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                                                {teamMatches[team.team.name]?.slice(0, 15).map(match => (
                                                    <MatchCard
                                                        key={match.id}
                                                        match={match}
                                                        teamName={team.team.name}
                                                    />
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="text-center py-6 text-gray-500 bg-gray-50 rounded-lg">
                                                Inga matcher tillgängliga
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const filteredTeams = () => {
        if (activeFilter === 'all') {
            return {
                groupA: groupATeams,
                groupB: groupBTeams
            };
        } else if (activeFilter === 'groupA') {
            return {
                groupA: groupATeams,
                groupB: []
            };
        } else if (activeFilter === 'groupB') {
            return {
                groupA: [],
                groupB: groupBTeams
            };
        }
        return { groupA: [], groupB: [] };
    };

    const renderGroup = (teams, groupName) => {
        if (!teams || teams.length === 0) return null;

        return (
            <div className="mb-8 w-full">
                <h3 className="text-xl font-bold mb-4 flex items-center">
                    <Users className="w-5 h-5 mr-2 text-blue-600" />
                    Grupp {groupName}
                </h3>
                <div className="grid grid-cols-1 gap-4">
                    {teams.map(team => (
                        <TeamCard key={team.id} team={team} />
                    ))}
                </div>
            </div>
        );
    };

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <img
                    src={require('../images/curling.gif')}
                    alt="Loading..."
                    className="w-24 h-24"
                />
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="text-red-500 bg-white p-6 rounded-lg shadow-md">
                    <AlertCircle className="w-8 h-8 mb-2 mx-auto" />
                    <p>{error}</p>
                </div>
            </div>
        );
    }

    const hasNoTeams = (!groupATeams || groupATeams.length === 0) &&
        (!groupBTeams || groupBTeams.length === 0);

    const { groupA, groupB } = filteredTeams();

    return (
        <>
            <SEO
                title={`Lag i ${leagueName} | Curlingtävling`}
                description={`Se alla curlinglag som tävlar i ${leagueName}. Information om klubbar, spelare och statistik för varje lag i ligan.`}
                keywords={`curlinglag, ${leagueName} lag, curlingklubbar, curlingspelare, laglista curling`}
                canonicalUrl={`https://sweepscore.se/league/${leagueId}/teams`}
            />

            <div className="min-h-screen bg-gray-100 flex flex-col">
                <Helmet>
                    <title>{`Lag - ${leagueName}`} | SweepScore</title>
                    <meta name="description" content={`Se alla deltagande lag i ${leagueName}. Utforska lagmedlemmar, matchhistorik och statistik.`} />
                </Helmet>

                <main className="flex-grow container mx-auto px-4 py-16 pt-24 lg:pt-32">
                    <div className="bg-gradient-to-r from-gray-800 to-gray-700 rounded-xl shadow-lg p-5 text-white overflow-hidden mb-6 relative">
                        <div className="absolute top-0 right-0 w-64 h-64 bg-white opacity-5 rounded-full -mr-32 -mt-32"></div>
                        <div className="absolute bottom-0 left-0 w-40 h-40 bg-white opacity-5 rounded-full -ml-20 -mb-20"></div>
                        <div className="relative z-10">
                            <div className="flex justify-between items-center">
                                <h1 className="text-2xl md:text-3xl font-bold flex items-center">
                                    <Users className="mr-2 h-6 w-6" /> Lag
                                </h1>

                                <span className="text-sm text-blue-300">{leagueName}</span>
                            </div>
                        </div>
                    </div>

                    {hasNoTeams ? (
                        <div className="bg-white rounded-xl shadow-lg p-8 text-center">
                            <AlertCircle className="w-16 h-16 text-gray-400 mx-auto mb-4" />
                            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Inga lag tillgängliga</h2>
                            <p className="text-gray-600 max-w-lg mx-auto">
                                Det finns inga lag kopplade till denna tävling. Lag kommer att visas
                                när de har registrerats i systemet.
                            </p>
                        </div>
                    ) : (
                        <>
                            {/* Filter buttons only when both groups have teams */}
                            {groupATeams.length > 0 && groupBTeams.length > 0 && (
                                <div className="mb-6 bg-white rounded-lg shadow-md p-4">
                                    <div className="flex items-center gap-3">
                                        <Filter className="w-5 h-5 text-gray-600" />
                                        <div className="flex flex-wrap gap-2">
                                            <button
                                                onClick={() => setActiveFilter('all')}
                                                className={`px-3 py-1.5 text-sm font-medium rounded-md ${activeFilter === 'all'
                                                    ? 'bg-blue-100 text-blue-800'
                                                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                                    }`}
                                            >
                                                Alla lag
                                            </button>
                                            <button
                                                onClick={() => setActiveFilter('groupA')}
                                                className={`px-3 py-1.5 text-sm font-medium rounded-md ${activeFilter === 'groupA'
                                                    ? 'bg-blue-100 text-blue-800'
                                                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                                    }`}
                                            >
                                                Grupp A ({groupATeams.length})
                                            </button>
                                            <button
                                                onClick={() => setActiveFilter('groupB')}
                                                className={`px-3 py-1.5 text-sm font-medium rounded-md ${activeFilter === 'groupB'
                                                    ? 'bg-blue-100 text-blue-800'
                                                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                                    }`}
                                            >
                                                Grupp B ({groupBTeams.length})
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {renderGroup(groupA, 'A')}
                            {renderGroup(groupB, 'B')}
                        </>
                    )}
                </main>
                <Footer />
            </div>
        </>
    );
};

export default Teams;