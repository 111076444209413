import React, { useEffect } from 'react';
import CreateUser from './CreateUser';
import ManageUsers from './ManageUsers';
import SubTabButton from '../Common/SubTabButton';
import { UserPlus, Settings } from 'lucide-react';

const UsersTab = ({ activeSubTab }) => {
  useEffect(() => {
    // Initialize tab display on component mount
    const tabcontent = document.getElementsByClassName("tabcontent");
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    
    // Show the active tab
    const initialTab = activeSubTab || 'CreateNewUser';
    const element = document.getElementById(initialTab);
    if (element) {
      element.style.display = "block";
    }
  }, [activeSubTab]);

  const openSubTab = (event, tabName) => {
    const tabcontent = document.getElementsByClassName("tabcontent");
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    const tablinks = document.getElementsByClassName("tablinks");
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    document.getElementById(tabName).style.display = "block";
    event.currentTarget.className += " active";
  };

  const subTabsConfig = [
    { id: 'CreateNewUser', label: 'Skapa ny användare', icon: <UserPlus size={16} /> },
    { id: 'ManageUsers', label: 'Hantera användare', icon: <Settings size={16} /> }
  ];

  return (
    <div>
      {/* Horizontal SubTabs */}
      <div className="subtab rounded-t-lg mb-4">
        {subTabsConfig.map(tab => (
          <SubTabButton 
            key={tab.id}
            onClick={(e) => openSubTab(e, tab.id)} 
            label={tab.label} 
            icon={tab.icon}
            isActive={activeSubTab === tab.id}
          />
        ))}
      </div>

      {/* Tab Content */}
      <div className="bg-white rounded-b-lg shadow">
        <div id="CreateNewUser" className="tabcontent">
          <CreateUser />
        </div>

        <div id="ManageUsers" className="tabcontent">
          <ManageUsers />
        </div>
      </div>
    </div>
  );
};

export default UsersTab;