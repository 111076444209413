import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Footer from '../components/Footer';
import { Trophy, Calendar, Crown, Activity, AlertCircle } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import hckLogo from '../images/HCK.png';
import SEO from '../components/SEO';

const Playoff = () => {
    const { leagueId } = useParams();
    const [loading, setLoading] = useState(true);
    const [league, setLeague] = useState(null);
    const [matches, setMatches] = useState({
        winnerBracket: {
            quarterfinals: [],
            semifinals: [],
            finals: []
        },
        loserBracket: {
            quarterfinals: [],
            semifinals: [],
            finals: []
        }
    });

    useEffect(() => {
        const fetchLeague = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/`);
                if (!response.ok) {
                    throw new Error('Failed to fetch league data');
                }
                const data = await response.json();
                setLeague(data);
            } catch (error) {
                console.error("Error fetching league data:", error);
            }
        };

        const fetchPlayoffMatches = async () => {
            try {
                setLoading(true);
                const response = await axios.get(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/playoffmatches/`);

                const winnerMatches = {
                    round16Matches: response.data.round16Matches?.filter(m => m.bracket_type === 'winner') || [],
                    quarterfinals: response.data.quarterfinals?.matches?.filter(m => m.bracket_type === 'winner') || [],
                    semifinals: response.data.semifinals?.matches?.filter(m => m.bracket_type === 'winner') || [],
                    finals: response.data.finals?.matches?.filter(m => m.bracket_type === 'winner') || [],
                    thirdPlaceMatches: response.data.thirdPlaceMatches?.filter(m => m.bracket_type === 'winner') || [],
                    fifthPlaceMatches: response.data.fifthPlaceMatches?.filter(m => m.bracket_type === 'winner') || []
                };

                const loserMatches = {
                    round16Matches: response.data.round16Matches?.filter(m => m.bracket_type === 'loser') || [],
                    quarterfinals: response.data.quarterfinals?.matches?.filter(m => m.bracket_type === 'loser') || [],
                    semifinals: response.data.semifinals?.matches?.filter(m => m.bracket_type === 'loser') || [],
                    finals: response.data.finals?.matches?.filter(m => m.bracket_type === 'loser') || [],
                    thirdPlaceMatches: response.data.thirdPlaceMatches?.filter(m => m.bracket_type === 'loser') || [],
                    fifthPlaceMatches: response.data.fifthPlaceMatches?.filter(m => m.bracket_type === 'loser') || []
                };

                setMatches({
                    winnerBracket: winnerMatches,
                    loserBracket: loserMatches
                });
            } catch (error) {
                console.error("Error fetching playoff data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPlayoffMatches();
        fetchLeague();

    }, [leagueId]);

    const formatDate = (dateString) => {
        if (!dateString) return 'TBD';
        try {
            const date = new Date(dateString);
            return new Intl.DateTimeFormat('sv-SE', {
                day: '2-digit',
                month: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            }).format(date);
        } catch (error) {
            console.error('Date formatting error:', error);
            return 'TBD';
        }
    };

    const MatchCard = ({ match }) => (
        <div className="relative bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-all duration-200">
            <div className="p-4">
                <div className="flex items-center justify-between">
                    <div className="flex-1 pr-4">
                        <div className="flex items-center space-x-2">
                            <div className="w-10 h-10 bg-yellow-300 rounded-full flex items-center justify-center">
                                <span className="text-sm font-medium text-gray-700">
                                    <img src={hckLogo} alt="HCK logo" className="w-7 h-7 object-contain" />
                                </span>
                            </div>
                            <div>
                                <p className="font-semibold text-gray-900">{match.team1_details?.team?.name || 'TBD'}</p>
                                <p className="text-sm text-gray-500">
                                    {match.team1_details?.seed ? `Seed #${match.team1_details.seed}` : ''}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-800 text-white rounded-md px-3 py-1 font-bold text-lg min-w-[2rem] text-center">
                        {match.team1_score ?? '-'}
                    </div>
                </div>

                <div className="my-3 border-t border-gray-100"></div>

                <div className="flex items-center justify-between">
                    <div className="flex-1 pr-4">
                        <div className="flex items-center space-x-2">
                            <div className="w-10 h-10 bg-red-700 rounded-full flex items-center justify-center">
                                <span className="text-sm font-medium text-gray-700">
                                    <img src={hckLogo} alt="HCK logo" className="w-7 h-7 object-contain" />
                                </span>
                            </div>
                            <div>
                                <p className="font-semibold text-gray-900">{match.team2_details?.team?.name || 'TBD'}</p>
                                <p className="text-sm text-gray-500">
                                    {match.team2_details?.seed ? `Seed #${match.team2_details.seed}` : ''}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-800 text-white rounded-md px-3 py-1 font-bold text-lg min-w-[2rem] text-center">
                        {match.team2_score ?? '-'}
                    </div>
                </div>

                <div className="flex items-center justify-between mt-4 text-sm">
                    <div className="flex items-center text-gray-600">
                        <Calendar className="w-4 h-4 mr-1" />
                        <span>{formatDate(match.match_date)}</span>
                    </div>
                    <div className="bg-gray-100 px-2 py-1 rounded text-gray-700">
                        Bana {match.track || 'TBD'}
                    </div>
                </div>

                {match.winner_details && (
                    <div className="mt-3 flex items-center text-green-700 bg-green-50 px-2 py-1 rounded-full text-sm font-medium">
                        <Crown className="w-4 h-4 mr-1" />
                        Vinnare: {match.winner_details.team.name}
                    </div>
                )}
            </div>
        </div>
    );

    const BracketRound = ({ matches, title, date }) => (
        <div className="mb-6">
            <h3 className="text-lg font-medium text-gray-800 mb-3 flex items-center">
                <Activity className="w-5 h-5 mr-2 text-blue-600" />
                {title} <span className="ml-2 text-sm text-gray-500">({formatDate(date)})</span>
            </h3>
            <div className="space-y-4">
                {matches.length > 0 ? (
                    matches.map((match, idx) => (
                        <MatchCard key={idx} match={match} />
                    ))
                ) : (
                    <div className="bg-gray-50 rounded-lg p-4 text-center text-gray-500">
                        Inga matcher tillgängliga
                    </div>
                )}
            </div>
        </div>
    );

    // BracketSection component with fixed layout to display all rounds including quarterfinals

    const BracketSection = ({ matches, title, leagueName, isLoserBracket }) => {
        const dates = isLoserBracket ? {
            round16: league?.loser_round16_datetime,
            quarter: league?.loser_round1_datetime,
            semi: league?.loser_round2_datetime,
            final: league?.loser_final_datetime,
            thirdPlace: league?.loser_third_place_datetime,
            fifthPlace: league?.loser_fifth_place_datetime
        } : {
            round16: league?.round16_datetime,
            quarter: league?.quarterfinal_datetime,
            semi: league?.semifinal_datetime,
            final: league?.final_datetime,
            thirdPlace: league?.third_place_datetime,
            fifthPlace: league?.fifth_place_datetime
        };

        // Group matches by type
        const round16Matches = matches.round16Matches || [];
        const quarterMatches = matches.quarterfinals || [];
        const semiMatches = matches.semifinals || [];
        const finalMatches = matches.finals || [];
        const thirdPlaceMatches = matches.thirdPlaceMatches || [];
        const fifthPlaceMatches = matches.fifthPlaceMatches || [];

        return (
            <div className="mb-12">
                <div className="bg-gradient-to-r from-gray-800 to-gray-700 rounded-xl shadow-lg p-5 text-white overflow-hidden mb-6 relative">
                    <div className="absolute top-0 right-0 w-64 h-64 bg-white opacity-5 rounded-full -mr-32 -mt-32"></div>
                    <div className="absolute bottom-0 left-0 w-40 h-40 bg-white opacity-5 rounded-full -ml-20 -mb-20"></div>
                    <div className="relative z-10">
                        <div className="flex justify-between items-center">
                            <h2 className="text-2xl font-bold flex items-center">
                                <Trophy className="mr-2 h-6 w-6" />
                                {title}
                            </h2>

                            {leagueName && (
                                <span className="text-sm text-blue-300">{leagueName}</span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow-lg p-6">
                    {/* Mobile View */}
                    <div className="md:hidden">
                        {round16Matches.length > 0 && (
                            <BracketRound
                                matches={round16Matches}
                                title="Åttondelsfinaler"
                                date={dates.round16}
                            />
                        )}

                        {quarterMatches.length > 0 && (
                            <BracketRound
                                matches={quarterMatches}
                                title="Kvartsfinaler"
                                date={dates.quarter}
                            />
                        )}

                        {semiMatches.length > 0 && (
                            <BracketRound
                                matches={semiMatches}
                                title="Semifinaler"
                                date={dates.semi}
                            />
                        )}

                        {finalMatches.length > 0 && (
                            <BracketRound
                                matches={finalMatches}
                                title="Final"
                                date={dates.final}
                            />
                        )}

                        {thirdPlaceMatches.length > 0 && (
                            <BracketRound
                                matches={thirdPlaceMatches}
                                title="Match om 3:e plats"
                                date={dates.thirdPlace}
                            />
                        )}

                        {fifthPlaceMatches.length > 0 && (
                            <BracketRound
                                matches={fifthPlaceMatches}
                                title="Match om 5:e plats"
                                date={dates.fifthPlace}
                            />
                        )}
                    </div>

                    {/* Desktop View - Updated with 4 columns */}
                    <div className="hidden md:block">
                        <div className="mb-6 grid grid-cols-4 border-b-2 border-gray-200 pb-4 text-center">
                            {/* Round of 16 Column Header */}
                            <div className="col-span-1">
                                <div className="text-lg font-medium text-gray-800">
                                    Åttondelsfinaler
                                    <span className="text-sm ml-1">
                                        ({formatDate(dates.round16)})
                                    </span>
                                </div>
                            </div>

                            {/* Quarterfinals Column Header */}
                            <div className="col-span-1">
                                <div className="text-lg font-medium text-gray-800">
                                    Kvartsfinaler
                                    <span className="text-sm ml-1">
                                        ({formatDate(dates.quarter)})
                                    </span>
                                </div>
                            </div>

                            {/* Semifinals Column Header */}
                            <div className="col-span-1">
                                <div className="text-lg font-medium text-gray-800">
                                    Semifinaler
                                    <span className="text-sm ml-1">
                                        ({formatDate(dates.semi)})
                                    </span>
                                </div>
                            </div>

                            {/* Finals Column Header */}
                            <div className="col-span-1">
                                <div className="text-lg font-medium text-gray-800">
                                    Final
                                    <span className="text-sm ml-1">
                                        ({formatDate(dates.final)})
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-4 gap-6">
                            {/* Round of 16 Matches */}
                            <div className="space-y-4">
                                {round16Matches.length > 0 ? (
                                    round16Matches.map((match, idx) => (
                                        <MatchCard key={`round16-${idx}`} match={match} />
                                    ))
                                ) : (
                                    <div className="bg-gray-50 rounded-lg p-4 text-center text-gray-500">
                                        Inga matcher
                                    </div>
                                )}
                            </div>

                            {/* Quarterfinal Matches */}
                            <div className="space-y-4">
                                {quarterMatches.length > 0 ? (
                                    quarterMatches.map((match, idx) => (
                                        <MatchCard key={`quarter-${idx}`} match={match} />
                                    ))
                                ) : (
                                    <div className="bg-gray-50 rounded-lg p-4 text-center text-gray-500">
                                        Inga matcher
                                    </div>
                                )}
                            </div>

                            {/* Semifinal Matches */}
                            <div className="space-y-4">
                                {semiMatches.length > 0 ? (
                                    semiMatches.map((match, idx) => (
                                        <MatchCard key={`semi-${idx}`} match={match} />
                                    ))
                                ) : (
                                    <div className="bg-gray-50 rounded-lg p-4 text-center text-gray-500">
                                        Inga matcher
                                    </div>
                                )}
                            </div>

                            {/* Final Match */}
                            <div className="space-y-4">
                                {finalMatches.length > 0 ? (
                                    finalMatches.map((match, idx) => (
                                        <MatchCard key={`final-${idx}`} match={match} />
                                    ))
                                ) : (
                                    <div className="bg-gray-50 rounded-lg p-4 text-center text-gray-500">
                                        Inga matcher
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Placement Matches Section */}
                        {(thirdPlaceMatches.length > 0 || fifthPlaceMatches.length > 0) && (
                            <div className="mt-10 pt-6 border-t-2 border-gray-200">
                                <h3 className="text-xl font-medium text-gray-800 mb-4">Placeringsmatcher</h3>

                                <div className="grid grid-cols-2 gap-6">
                                    {thirdPlaceMatches.length > 0 && (
                                        <div>
                                            <h4 className="text-md font-medium text-gray-700 mb-2">
                                                Match om 3:e plats <span className="text-sm">({formatDate(dates.thirdPlace)})</span>
                                            </h4>
                                            {thirdPlaceMatches.map((match, idx) => (
                                                <MatchCard key={`third-${idx}`} match={match} />
                                            ))}
                                        </div>
                                    )}

                                    {fifthPlaceMatches.length > 0 && (
                                        <div>
                                            <h4 className="text-md font-medium text-gray-700 mb-2">
                                                Match om 5:e plats <span className="text-sm">({formatDate(dates.fifthPlace)})</span>
                                            </h4>
                                            {fifthPlaceMatches.map((match, idx) => (
                                                <MatchCard key={`fifth-${idx}`} match={match} />
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <img
                    src={require('../images/curling.gif')}
                    alt="Loading..."
                    className="w-24 h-24"
                />
            </div>
        );
    }

    const hasNoPlayoffs = (
        (!matches.winnerBracket.quarterfinals.length &&
            !matches.winnerBracket.semifinals.length &&
            !matches.winnerBracket.finals.length) &&
        (!matches.loserBracket.quarterfinals.length &&
            !matches.loserBracket.semifinals.length &&
            !matches.loserBracket.finals.length)
    );

    return (
        <>
            <SEO
                title={`Slutspel ${league.name} | Curlingtävling`}
                description={`Följ slutspelet i ${league.name}. Se matchresultat, spelschema och uppdateringar från slutspelsmatcherna i Curlingtävlinen.`}
                keywords={`curlingslutspel, ${league.name} slutspel, slutspelsmatcher curling, curling playoff, slutspelsschema curling`}
                canonicalUrl={`https://sweepscore.se/league/${leagueId}/playoff`}
            />

            <div className="min-h-screen bg-gray-100 flex flex-col">
                <Helmet>
                    <title>{league?.name ? `Slutspel - ${league.name}` : 'Slutspel'} | SweepScore</title>
                    <meta name="description" content={`Se slutspelsställningar och resultat för ${league?.name || 'curlingtävlingen'}. Följ kvartsfinaler, semifinaler och finaler.`} />
                </Helmet>

                <main className="flex-grow container mx-auto px-4 py-16 pt-24 lg:pt-32">
                    <div className="mx-auto max-w-7xl">
                        {hasNoPlayoffs ? (
                            <div className="bg-white rounded-xl shadow-lg p-8 text-center">
                                <AlertCircle className="w-16 h-16 text-gray-400 mx-auto mb-4" />
                                <h2 className="text-2xl font-semibold text-gray-800 mb-2">Inget slutspel tillgängligt</h2>
                                <p className="text-gray-600 max-w-lg mx-auto">
                                    Det finns inga slutspelsmatcher tillgängliga för denna tävling ännu.
                                    Slutspelet kan vara planerat till ett senare datum eller så används
                                    inte slutspelsformat för denna tävling.
                                </p>
                            </div>
                        ) : (
                            <>
                                {(matches.winnerBracket.quarterfinals.length > 0 ||
                                    matches.winnerBracket.semifinals.length > 0 ||
                                    matches.winnerBracket.finals.length > 0) && (
                                        <BracketSection
                                            matches={matches.winnerBracket}
                                            title="A Slutspel"
                                            leagueName={league?.name}
                                            isLoserBracket={false}
                                        />
                                    )}

                                {(matches.loserBracket.quarterfinals.length > 0 ||
                                    matches.loserBracket.semifinals.length > 0 ||
                                    matches.loserBracket.finals.length > 0) && (
                                        <BracketSection
                                            matches={matches.loserBracket}
                                            title="B Slutspel"
                                            leagueName={league?.name}
                                            isLoserBracket={true}
                                        />
                                    )}
                            </>
                        )}
                    </div>
                </main>
                <Footer />
            </div>
        </>
    );
};

export default Playoff;