import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import logo from '../images/Sweepscore_white.png';
import { Menu, Trophy, Archive, MessageCircle, Home } from 'lucide-react';
import LeagueNavMenu from './LeagueNavMenu';

const NavBar = ({ withLeagueLinks }) => {
    const { isLoggedIn, setIsLoggedIn, user } = useAuth();
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showAvatarMenu, setShowAvatarMenu] = useState(false);
    const { id, leagueId } = useParams();
    const currentLeagueId = id || leagueId;
    const navigate = useNavigate();
    const location = useLocation();
    const [leagueName, setLeagueName] = useState('');

    // Refs for menus
    const mobileMenuRef = useRef(null);
    const avatarMenuRef = useRef(null);
    const hamburgerButtonRef = useRef(null);
    const avatarButtonRef = useRef(null);

    // Kontrollera om vi är på startsidan
    const isHomePage = location.pathname === '/';

    // Fetch league name when on a league page
    useEffect(() => {
        const fetchLeagueName = async () => {
            if (currentLeagueId) {
                try {
                    const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${currentLeagueId}/`);
                    if (response.ok) {
                        const data = await response.json();
                        setLeagueName(data.name);
                    }
                } catch (error) {
                    console.error('Error fetching league name:', error);
                }
            }
        };

        fetchLeagueName();
    }, [currentLeagueId]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check for clicks outside mobile menu
            if (showMobileMenu &&
                mobileMenuRef.current &&
                !mobileMenuRef.current.contains(event.target) &&
                hamburgerButtonRef.current &&
                !hamburgerButtonRef.current.contains(event.target)) {
                setShowMobileMenu(false);
            }

            // Check for clicks outside avatar menu
            if (showAvatarMenu &&
                avatarMenuRef.current &&
                !avatarMenuRef.current.contains(event.target) &&
                avatarButtonRef.current &&
                !avatarButtonRef.current.contains(event.target)) {
                setShowAvatarMenu(false);
            }
        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMobileMenu, showAvatarMenu]);

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        setIsLoggedIn(false);
        navigate('/');
    };

    const handleLinkClick = () => {
        setShowMobileMenu(false);
        setShowAvatarMenu(false);
    };

    // Dynamiskt sätta position baserat på om vi är på startsidan eller inte
    const navbarPositionClass = isHomePage 
    ? "top-36 md:top-0" // Ökad topp-margin för mobil
    : "top-0"; 

    // Check if this is a league page
    const isLeaguePage = withLeagueLinks && currentLeagueId;

    return (
        <>
            {/* Main navigation - positionering anpassad för varje sida */}
            <nav className={`bg-gray-800 text-white p-4 flex justify-between items-center shadow-lg w-full fixed ${navbarPositionClass} left-0 right-0 z-50 h-16`}>
                {/* Hamburger Menu - left side */}
                <div className="flex items-center relative">
                    <button
                        ref={hamburgerButtonRef}
                        className="cursor-pointer"
                        onClick={() => setShowMobileMenu(!showMobileMenu)}
                    >
                        <Menu size={24} />
                    </button>
                    
                    {/* Mobile menu for league links or homepage - opens downward */}
                    {showMobileMenu && (
                        <div ref={mobileMenuRef} className="absolute top-full left-0 mt-2 bg-gray-800 rounded-lg shadow-lg p-4 w-60 z-40 text-white">
                            <ul className="space-y-3">
                                {/* Common links for all pages */}
                                <li>
                                    <Link 
                                        to="/" 
                                        className="flex items-center text-white hover:text-gray-300 transition-colors" 
                                        onClick={handleLinkClick}
                                    >
                                        <Home className="w-5 h-5 mr-2" />
                                        Hem
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                        to="/aktiva-tavlingar" 
                                        className="flex items-center text-white hover:text-gray-300 transition-colors" 
                                        onClick={handleLinkClick}
                                    >
                                        <Trophy className="w-5 h-5 mr-2" />
                                        Aktiva tävlingar
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                        to="/arkiverade-tavlingar" 
                                        className="flex items-center text-white hover:text-gray-300 transition-colors" 
                                        onClick={handleLinkClick}
                                    >
                                        <Archive className="w-5 h-5 mr-2" />
                                        Arkiverade tävlingar
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                        to="/kontakta-oss" 
                                        className="flex items-center text-white hover:text-gray-300 transition-colors" 
                                        onClick={handleLinkClick}
                                    >
                                        <MessageCircle className="w-5 h-5 mr-2" />
                                        Kontakta oss
                                    </Link>
                                </li>

                                <li className="border-t border-gray-600 my-2 pt-2"></li>

                                {withLeagueLinks && currentLeagueId ? (
                                    <>
                                        <li><Link to={`/league/${currentLeagueId}`} className="block hover:text-gray-400" onClick={handleLinkClick}>Översikt</Link></li>
                                        <li><Link to={`/league/${currentLeagueId}/schedule`} className="block hover:text-gray-400" onClick={handleLinkClick}>Spelschema</Link></li>
                                        <li><Link to={`/league/${currentLeagueId}/matches`} className="block hover:text-gray-400" onClick={handleLinkClick}>Matcher</Link></li>
                                        <li><Link to={`/league/${currentLeagueId}/table`} className="block hover:text-gray-400" onClick={handleLinkClick}>Tabell</Link></li>
                                        <li><Link to={`/league/${currentLeagueId}/playoff`} className="block hover:text-gray-400" onClick={handleLinkClick}>Slutspel</Link></li>
                                        <li><Link to={`/league/${currentLeagueId}/teams`} className="block hover:text-gray-400" onClick={handleLinkClick}>Lag</Link></li>
                                    </>
                                ) : isLoggedIn && (
                                    <>
                                        <li><Link to="/profile" className="block hover:text-gray-400" onClick={handleLinkClick}>Min profil</Link></li>
                                        <li><Link to="/my-team" className="block hover:text-gray-400" onClick={handleLinkClick}>Mitt lag</Link></li>
                                        <li><Link to="/my-competitions" className="block hover:text-gray-400" onClick={handleLinkClick}>Mina tävlingar</Link></li>
                                        <li><Link to="/my-matches" className="block hover:text-gray-400" onClick={handleLinkClick}>Mina matcher</Link></li>
                                        {(user?.is_staff || user?.permissions?.is_league_admin) && (
                                            <li><Link to="/admin" className="block hover:text-gray-400" onClick={handleLinkClick}>Admin</Link></li>
                                        )}
                                    </>
                                )}
                            </ul>
                        </div>
                    )}
                </div>

                {/* Logo and name - centered */}
                <div className="flex items-center justify-center">
                    <Link to="/" className="flex items-center">
                        <img src={logo} alt="SweepScore Logo" className="w-12 h-12 mr-2 mb-1" />
                        <h1 className="text-xl font-semibold">SweepScore</h1>
                    </Link>
                </div>

                {/* Avatar/Login - right aligned */}
                <div className="flex items-center">
                    {isLoggedIn ? (
                        <div className="relative">
                            <button
                                ref={avatarButtonRef}
                                className="flex items-center cursor-pointer focus:outline-none"
                                onClick={() => setShowAvatarMenu(!showAvatarMenu)}
                            >
                                <img
                                    src={user?.avatar || logo}
                                    alt="User"
                                    className="w-10 h-10 rounded-full object-cover border-2 border-white"
                                />
                            </button>
                            {/* Avatar dropdown menu */}
                            {showAvatarMenu && (
                                <div ref={avatarMenuRef} className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-lg shadow-lg p-4 z-50">
                                    <ul className="space-y-3">
                                        <>
                                            <li><Link to="/profile" className="block hover:text-gray-400" onClick={handleLinkClick}>Min profil</Link></li>
                                            <li><Link to="/my-team" className="block hover:text-gray-400" onClick={handleLinkClick}>Mitt lag</Link></li>
                                            <li><Link to="/my-competitions" className="block hover:text-gray-400" onClick={handleLinkClick}>Mina tävlingar</Link></li>
                                            <li><Link to="/my-matches" className="block hover:text-gray-400" onClick={handleLinkClick}>Mina matcher</Link></li>
                                            {(user?.is_staff || user?.permissions?.is_league_admin) && (
                                                <li><Link to="/admin" className="block hover:text-gray-400" onClick={handleLinkClick}>Admin</Link></li>
                                            )}
                                            <li className="border-t border-gray-600 my-2"></li>
                                            <li>
                                                <button
                                                    className="w-full text-left text-white hover:text-gray-400"
                                                    onClick={() => {
                                                        handleLogout();
                                                        handleLinkClick();
                                                    }}
                                                >
                                                    Logga ut
                                                </button>
                                            </li>
                                        </>
                                    </ul>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Link to="/login" className="hover:text-gray-400">Logga in</Link>
                    )}
                </div>
            </nav>

            {/* League Navigation Menu - only visible on desktop and league pages */}
            {isLeaguePage && <LeagueNavMenu leagueId={currentLeagueId} leagueName={leagueName} />}
        </>
    );
};

export default NavBar;