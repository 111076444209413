import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../AuthContext';
import { useNavigate } from 'react-router-dom';
import MatchResults from './MatchResults';
import CreateMatches from './CreateMatches';
import CreatePlayoffMatches from './CreatePlayoffMatches'; // New import
import PlayoffMatches from './PlayoffMatches';
import SubTabButton from '../Common/SubTabButton';
import { Trophy, Calendar, PlusCircle, Target } from 'lucide-react';

const MatchesTab = ({ activeSubTab }) => {
  const [playoffDataFetched, setPlayoffDataFetched] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Kontrollera behörigheter
    if (!user || (!user.is_staff && !user.permissions?.is_league_admin)) {
      navigate('/');
      return;
    }

    // Initialize tab display on component mount
    const tabcontent = document.getElementsByClassName("tabcontent");
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    
    // Show the active tab
    const initialTab = activeSubTab || 'MatchResults';
    const element = document.getElementById(initialTab);
    if (element) {
      element.style.display = "block";
    }

    if (initialTab === 'PlayoffMatches') {
      setPlayoffDataFetched(true);
    }
  }, [activeSubTab, user, navigate]);

  const openSubTab = (event, tabName) => {
    const tabcontent = document.getElementsByClassName("tabcontent");
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    const tablinks = document.getElementsByClassName("tablinks");
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    document.getElementById(tabName).style.display = "block";
    event.currentTarget.className += " active";

    if (tabName === 'PlayoffMatches') {
      setPlayoffDataFetched(true);
    }
  };

  // Bestäm vilka flikar som ska visas baserat på användarens behörigheter
  const getAvailableTabs = () => {
    const tabs = [
      {
        id: 'MatchResults',
        label: 'Matchresultat',
        icon: <Calendar size={16} />
      },
      {
        id: 'PlayoffMatches',
        label: 'Slutspelsresultat',
        icon: <Trophy size={16} />
      }
    ];

    // Endast staff users kan skapa matcher
    if (user?.is_staff) {
      tabs.push({
        id: 'CreateMatches',
        label: 'Skapa seriematcher',
        icon: <PlusCircle size={16} />
      });
      tabs.push({
        id: 'CreatePlayoffMatches',
        label: 'Skapa slutspelsmatcher',
        icon: <Target size={16} />
      });
    }

    return tabs;
  };

  const availableTabs = getAvailableTabs();

  return (
    <div>
      {/* Horizontal SubTabs */}
      <div className="subtab rounded-t-lg mb-4">
        {availableTabs.map(tab => (
          <SubTabButton 
            key={tab.id}
            onClick={(e) => openSubTab(e, tab.id)} 
            label={tab.label} 
            icon={tab.icon}
            isActive={activeSubTab === tab.id}
          />
        ))}
      </div>

      {/* Tab Content */}
      <div className="bg-white rounded-b-lg shadow">
        <div id="MatchResults" className="tabcontent">
          <MatchResults />
        </div>

        <div id="PlayoffMatches" className="tabcontent">
          {playoffDataFetched ? <PlayoffMatches /> : null}
        </div>

        {user?.is_staff && (
          <>
            <div id="CreateMatches" className="tabcontent">
              <CreateMatches />
            </div>
            <div id="CreatePlayoffMatches" className="tabcontent">
              <CreatePlayoffMatches />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MatchesTab;