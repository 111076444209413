import React from 'react';

export function StatisticsCard({ icon, title, value }) {
    return (
        <div className="bg-white rounded-lg shadow-md p-4 border border-gray-100 hover:shadow-lg transition-all">
            <div className="flex items-center space-x-3">
                <div className="p-2.5 bg-blue-50 rounded-lg text-blue-600 flex-shrink-0">
                    {icon}
                </div>
                <div>
                    <p className="text-sm text-gray-500 font-medium">{title}</p>
                    <p className="text-lg font-bold text-gray-900">{value || '0'}</p>
                </div>
            </div>
        </div>
    );
}