import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Calendar, 
  ListOrdered, 
  Trophy, 
  UsersRound, 
  CalendarClock,
  Info
} from 'lucide-react';

const LeagueNavMenu = ({ leagueId, leagueName }) => {
  const location = useLocation();
  
  // Define navigation links for the league
  const navigationLinks = [
    { to: `/league/${leagueId}`, text: 'Översikt', icon: Info, exact: true },
    { to: `/league/${leagueId}/matches`, text: 'Matcher', icon: Calendar, exact: false },
    { to: `/league/${leagueId}/table`, text: 'Tabell', icon: ListOrdered, exact: false },
    { to: `/league/${leagueId}/playoff`, text: 'Slutspel', icon: Trophy, exact: false },
    { to: `/league/${leagueId}/teams`, text: 'Lag', icon: UsersRound, exact: false },
    { to: `/league/${leagueId}/schedule`, text: 'Spelschema', icon: CalendarClock, exact: false }
  ];

  // Check if the current path matches or starts with the given path
  const isActive = (path, exact) => {
    if (exact) {
      return location.pathname === path;
    }
    return location.pathname.startsWith(path);
  };

  return (
    <div className="hidden lg:block bg-gray-800 w-full shadow-md fixed top-16 left-0 right-0 z-40">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-center h-10">
          {/* League name - positioned to the left */}
          <div className="text-blue-300 font-medium truncate mr-6 absolute left-4">
            {leagueName}
          </div>
          
          {/* Navigation links - centered */}
          <nav className="flex space-x-4 overflow-x-auto">
            {navigationLinks.map((link) => (
              <Link
                key={link.to}
                to={link.to}
                className={`flex items-center px-3 py-2 text-sm font-medium transition-colors duration-200 border-b-2 ${
                  isActive(link.to, link.exact)
                    ? 'border-blue-400 text-white'
                    : 'border-transparent text-gray-300 hover:text-white hover:border-gray-400'
                }`}
              >
                {link.icon && <link.icon className="w-4 h-4 mr-2" />}
                {link.text}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default LeagueNavMenu;