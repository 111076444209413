import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import { Users, UserPlus, UserMinus, AlertCircle, User } from 'lucide-react';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';

const MyTeam = () => {
    const { user } = useAuth();
    const [teams, setTeams] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchTeams = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(process.env.REACT_APP_API_URL + '/api/myteams/', {
                headers: {
                    Authorization: `Token ${localStorage.getItem('authToken')}`,
                },
            });
            setTeams(response.data);
            setIsLoading(false);
        } catch (error) {
            setError('Kunde inte ladda lagdata');
            setIsLoading(false);
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/api/users/', {
                headers: {
                    Authorization: `Token ${localStorage.getItem('authToken')}`,
                },
            });
            // Sort users alphabetically by first name and last name
            const sortedUsers = response.data.sort((a, b) =>
                `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`, 'sv')
            );
            setUsers(sortedUsers);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        if (user) {
            fetchTeams();
            fetchUsers();
        }
    }, [user]);

    const handleRemoveMember = async (teamId, memberUsername) => {
        try {
            await axios.delete(process.env.REACT_APP_API_URL + `/api/myteams/${teamId}/remove_member/`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem('authToken')}`,
                },
                data: { username: memberUsername },
            });
            setSuccess(`Medlemmen har tagits bort från laget`);
            setTimeout(() => setSuccess(null), 3000);
            await fetchTeams(); // Uppdatera team-datan efter borttagning
        } catch (error) {
            setError('Kunde inte ta bort medlemmen: ' + (error.response?.data?.detail || 'Ett fel uppstod'));
            setTimeout(() => setError(null), 3000);
        }
    };

    const handleAddMember = async (teamId) => {
        const selectedUserId = selectedUsers[teamId];
        if (selectedUserId) {
            try {
                await axios.post(process.env.REACT_APP_API_URL + `/api/myteams/${teamId}/add_member/`,
                    { userId: selectedUserId }, {
                    headers: {
                        Authorization: `Token ${localStorage.getItem('authToken')}`,
                    },
                });
                setSuccess('Medlemmen har lagts till i laget');
                setTimeout(() => setSuccess(null), 3000);
                await fetchTeams(); // Uppdatera team-datan efter tillägg
                setSelectedUsers({ ...selectedUsers, [teamId]: '' }); // Återställ selection
            } catch (error) {
                setError('Kunde inte lägga till medlemmen: ' + (error.response?.data?.detail || 'Ett fel uppstod'));
                setTimeout(() => setError(null), 3000);
            }
        }
    };

    const handleUserChange = (teamId, userId) => {
        setSelectedUsers({ ...selectedUsers, [teamId]: userId });
    };

    if (isLoading) {
        return (
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex items-center justify-center">
                <img src={require('../images/curling.gif')} alt="Loading" className="w-16 h-16" />
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex flex-col">
            <Helmet>
                <title>Mitt Lag | SweepScore</title>
                <meta name="description" content="Hantera ditt curlinglag på SweepScore. Lägg till och ta bort medlemmar från ditt lag." />
            </Helmet>

            <main className="flex-grow container mx-auto px-4 py-16 pt-24">
                {/* Header */}
                <div className="bg-gradient-to-r from-gray-800 to-gray-700 rounded-xl shadow-lg p-5 text-white overflow-hidden mb-6 relative">
                    <div className="absolute top-0 right-0 w-64 h-64 bg-white opacity-5 rounded-full -mr-32 -mt-32"></div>
                    <div className="absolute bottom-0 left-0 w-40 h-40 bg-white opacity-5 rounded-full -ml-20 -mb-20"></div>
                    <div className="relative z-10">
                        <h1 className="text-2xl md:text-3xl font-bold flex items-center">
                            <Users className="mr-2 h-6 w-6" /> Mitt Lag
                        </h1>
                    </div>
                </div>

                {/* Alerts */}
                {error && (
                    <div className="bg-red-50 border-l-4 border-red-500 text-red-700 p-4 mb-6 rounded-lg">
                        <div className="flex">
                            <AlertCircle className="h-5 w-5 mr-2" />
                            <p>{error}</p>
                        </div>
                    </div>
                )}

                {success && (
                    <div className="bg-green-50 border-l-4 border-green-500 text-green-700 p-4 mb-6 rounded-lg">
                        <div className="flex">
                            <AlertCircle className="h-5 w-5 mr-2" />
                            <p>{success}</p>
                        </div>
                    </div>
                )}

                {teams.length === 0 ? (
                    <div className="bg-white rounded-xl shadow-lg p-8 text-center">
                        <AlertCircle className="w-16 h-16 text-gray-400 mx-auto mb-4" />
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Inga lag hittades</h2>
                        <p className="text-gray-600 max-w-lg mx-auto">
                            Du är inte medlem i något lag ännu. Kontakta en administratör för att bli tillagd i ett lag.
                        </p>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {teams.map(team => (
                            <div key={team.id} className="bg-white rounded-xl shadow-lg overflow-hidden">
                                <div className="bg-blue-600 px-6 py-4 text-white">
                                    <h2 className="text-xl font-bold">{team.name}</h2>
                                </div>
                                
                                <div className="p-6">
                                    <h3 className="text-lg font-semibold mb-4 flex items-center">
                                        <Users className="w-5 h-5 mr-2 text-blue-600" />
                                        Lagmedlemmar
                                    </h3>
                                    
                                    {team.members.length === 0 ? (
                                        <p className="text-gray-500 italic mb-4">Inga medlemmar i laget.</p>
                                    ) : (
                                        <ul className="space-y-2 mb-6">
                                            {team.members.map(member => (
                                                <li key={member.id} className="flex justify-between items-center p-3 rounded-md bg-gray-50 hover:bg-gray-100">
                                                    <div className="flex items-center">
                                                        <div className="bg-gray-200 rounded-full p-2 mr-3">
                                                            <User className="h-4 w-4 text-gray-600" />
                                                        </div>
                                                        <div>
                                                            <span className="font-medium text-gray-900">{member.first_name} {member.last_name}</span>
                                                            <span className="text-sm text-gray-500 ml-2">({member.username})</span>
                                                        </div>
                                                    </div>
                                                    <button
                                                        onClick={() => handleRemoveMember(team.id, member.username)}
                                                        className="p-2 text-red-500 hover:text-red-700 hover:bg-red-50 rounded-full"
                                                        aria-label="Ta bort medlem"
                                                    >
                                                        <UserMinus className="h-5 w-5" />
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    )}

                                    <div className="border-t pt-4 mt-4">
                                        <h3 className="text-lg font-semibold mb-4 flex items-center">
                                            <UserPlus className="w-5 h-5 mr-2 text-blue-600" />
                                            Lägg till spelare
                                        </h3>
                                        
                                        <div className="space-y-3">
                                            <select
                                                value={selectedUsers[team.id] || ''}
                                                onChange={(e) => handleUserChange(team.id, e.target.value)}
                                                className="block w-full p-3 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500"
                                            >
                                                <option value="">Välj en användare</option>
                                                {users.map(user => (
                                                    <option key={user.id} value={user.id}>
                                                        {user.first_name} {user.last_name} ({user.username})
                                                    </option>
                                                ))}
                                            </select>
                                            
                                            <button
                                                onClick={() => handleAddMember(team.id)}
                                                disabled={!selectedUsers[team.id]}
                                                className="w-full bg-blue-600 text-white p-3 rounded-lg hover:bg-blue-700 transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed flex items-center justify-center"
                                            >
                                                <UserPlus className="w-5 h-5 mr-2" />
                                                Lägg till medlem
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default MyTeam;