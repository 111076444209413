import React, { useEffect } from 'react';
import CreateTeam from './CreateTeam';
import AllTeams from './AllTeams';
import SubTabButton from '../Common/SubTabButton';
import { PlusCircle, Users } from 'lucide-react';

const TeamsTab = ({ activeSubTab }) => {
  const openSubTab = (event, tabName) => {
    const tabcontent = document.getElementsByClassName("tabcontent");
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    const tablinks = document.getElementsByClassName("tablinks");
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    document.getElementById(tabName).style.display = "block";
    event.currentTarget.className += " active";
  };

  // Initialize tab display on component mount
  useEffect(() => {
    // Hide all tabs first
    const tabcontent = document.getElementsByClassName("tabcontent");
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    
    // Show the active tab
    const initialTab = activeSubTab || 'CreateNewTeams';
    const element = document.getElementById(initialTab);
    if (element) {
      element.style.display = "block";
    }
  }, [activeSubTab]);

  return (
    <div>
      <div className="subtab rounded-t-lg">
        <SubTabButton 
          onClick={(e) => openSubTab(e, 'CreateNewTeams')} 
          label="Skapa nytt lag" 
          icon={<PlusCircle size={16} />} 
          isActive={activeSubTab === 'CreateNewTeams'}
        />
        <SubTabButton 
          onClick={(e) => openSubTab(e, 'AllTeams')} 
          label="Hantera lag" 
          icon={<Users size={16} />}
          isActive={activeSubTab === 'AllTeams'}
        />
      </div>

      <div id="CreateNewTeams" className="tabcontent">
        <CreateTeam />
      </div>

      <div id="AllTeams" className="tabcontent">
        <AllTeams />
      </div>
    </div>
  );
};

export default TeamsTab;