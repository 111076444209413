import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import defaultAvatar from '../images/Player1.png';
import { Camera, Key, User, Mail, Save, Edit, Check, AlertCircle } from 'lucide-react';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';

const ProfilePage = () => {
    const { user, setUser } = useAuth();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [isEditingPassword, setIsEditingPassword] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [passwordForm, setPasswordForm] = useState({
        newPassword: '',
        confirmPassword: ''
    });

    useEffect(() => {
        if (user) {
            setUsername(user.username || '');
            setEmail(user.email || '');
        }
    }, [user]);

    const handleProfileUpdate = async (e) => {
        e.preventDefault();
        setError('');
        setSuccessMessage('');

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/users/update/', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({ username, email }),
            });

            if (response.ok) {
                const updatedUser = await response.json();
                setUser(updatedUser);
                setSuccessMessage('Profilen har uppdaterats!');
            } else {
                const errorData = await response.json();
                if (errorData.username) {
                    setError('Användarnamnet finns redan');
                } else if (errorData.email) {
                    setError('E-postadressen finns redan');
                } else {
                    setError('Ett fel uppstod vid uppdatering av profilen');
                }
            }
        } catch (error) {
            setError('Ett nätverksfel uppstod');
        }
    };

    const handlePasswordUpdate = async (e) => {
        e.preventDefault();
        setError('');
        setSuccessMessage('');

        if (passwordForm.newPassword !== passwordForm.confirmPassword) {
            setError('Lösenorden matchar inte');
            return;
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/users/update/', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({ password: passwordForm.newPassword }),
            });

            if (response.ok) {
                setSuccessMessage('Lösenordet har uppdaterats!');
                setPasswordForm({
                    newPassword: '',
                    confirmPassword: ''
                });
                setIsEditingPassword(false);
            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Ett fel uppstod vid uppdatering av lösenordet');
            }
        } catch (error) {
            setError('Ett nätverksfel uppstod');
        }
    };

    if (!user) {
        return (
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex items-center justify-center p-4">
                <div className="bg-white rounded-xl shadow-lg p-8 max-w-md w-full">
                    <AlertCircle className="w-16 h-16 text-gray-400 mx-auto mb-4" />
                    <h2 className="text-2xl font-semibold text-gray-800 mb-2 text-center">Ingen inloggad användare</h2>
                    <p className="text-gray-600 text-center mb-6">Du behöver logga in för att visa din profil.</p>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex flex-col">
            <Helmet>
                <title>Min Profil | SweepScore</title>
                <meta name="description" content="Hantera din användarprofil på SweepScore. Uppdatera dina uppgifter och kontoinställningar." />
            </Helmet>

            <main className="flex-grow container mx-auto px-4 py-16 pt-24">
                {/* Hero header */}
                <div className="bg-gradient-to-r from-gray-800 to-gray-700 rounded-xl shadow-lg p-5 text-white overflow-hidden mb-6 relative">
                    <div className="absolute top-0 right-0 w-64 h-64 bg-white opacity-5 rounded-full -mr-32 -mt-32"></div>
                    <div className="absolute bottom-0 left-0 w-40 h-40 bg-white opacity-5 rounded-full -ml-20 -mb-20"></div>
                    <div className="relative z-10">
                        <h1 className="text-2xl md:text-3xl font-bold flex items-center">
                            <User className="mr-2 h-6 w-6" /> Min Profil
                        </h1>
                    </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                    {/* Left sidebar with avatar */}
                    <div className="lg:col-span-1">
                        <div className="bg-white rounded-xl shadow-lg p-6 mb-6">
                            <div className="flex flex-col items-center">
                                <div className="relative mb-4">
                                    <img
                                        src={user?.avatar || defaultAvatar}
                                        alt="Profile"
                                        className="w-32 h-32 rounded-full object-cover border-4 border-white shadow-lg"
                                    />
                                    <button className="absolute bottom-0 right-0 bg-gray-800 p-2 rounded-full text-white hover:bg-gray-700 transition-colors">
                                        <Camera size={20} />
                                    </button>
                                </div>
                                <h2 className="text-xl font-bold text-gray-800">{user.first_name} {user.last_name}</h2>
                                <p className="text-gray-500 mb-4">@{user.username}</p>
                                
                                {user.is_staff && (
                                    <div className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm font-medium mb-2">
                                        Admin
                                    </div>
                                )}
                                
                                {user.permissions?.is_league_admin && (
                                    <div className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm font-medium">
                                        Ligaadministratör
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Main content area */}
                    <div className="lg:col-span-2">
                        {error && (
                            <div className="bg-red-50 border-l-4 border-red-500 text-red-700 p-4 mb-6 rounded-lg">
                                <div className="flex">
                                    <AlertCircle className="h-5 w-5 mr-2" />
                                    <p>{error}</p>
                                </div>
                            </div>
                        )}

                        {successMessage && (
                            <div className="bg-green-50 border-l-4 border-green-500 text-green-700 p-4 mb-6 rounded-lg">
                                <div className="flex">
                                    <Check className="h-5 w-5 mr-2" />
                                    <p>{successMessage}</p>
                                </div>
                            </div>
                        )}

                        {/* Profile Information Section */}
                        <div className="bg-white rounded-xl shadow-lg overflow-hidden mb-6">
                            <div className="p-6">
                                <h3 className="text-xl font-bold text-gray-800 mb-4 flex items-center">
                                    <User className="w-5 h-5 mr-2 text-blue-600" />
                                    Profilinformation
                                </h3>

                                <form onSubmit={handleProfileUpdate} className="space-y-4">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Förnamn</label>
                                            <input
                                                type="text"
                                                value={user.first_name || ''}
                                                className="w-full px-4 py-2 bg-gray-100 border border-gray-300 rounded-lg text-gray-700 focus:outline-none"
                                                disabled
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Efternamn</label>
                                            <input
                                                type="text"
                                                value={user.last_name || ''}
                                                className="w-full px-4 py-2 bg-gray-100 border border-gray-300 rounded-lg text-gray-700 focus:outline-none"
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Användarnamn
                                        </label>
                                        <div className="relative">
                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <User className="h-5 w-5 text-gray-400" />
                                            </div>
                                            <input
                                                type="text"
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                className="w-full pl-10 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            E-post
                                        </label>
                                        <div className="relative">
                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <Mail className="h-5 w-5 text-gray-400" />
                                            </div>
                                            <input
                                                type="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                className="w-full pl-10 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex justify-end">
                                        <button
                                            type="submit"
                                            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
                                        >
                                            <Save className="w-5 h-5 mr-2" />
                                            Spara ändringar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {/* Password Section */}
                        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                            <div className="p-6">
                                <div className="flex items-center justify-between mb-4">
                                    <h3 className="text-xl font-bold text-gray-800 flex items-center">
                                        <Key className="w-5 h-5 mr-2 text-blue-600" />
                                        Lösenord
                                    </h3>
                                    <button
                                        onClick={() => setIsEditingPassword(!isEditingPassword)}
                                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                                    >
                                        {isEditingPassword ? (
                                            <>
                                                <Check className="w-4 h-4 mr-2" />
                                                Avbryt
                                            </>
                                        ) : (
                                            <>
                                                <Edit className="w-4 h-4 mr-2" />
                                                Byt lösenord
                                            </>
                                        )}
                                    </button>
                                </div>

                                {isEditingPassword && (
                                    <form onSubmit={handlePasswordUpdate} className="space-y-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Nytt lösenord
                                            </label>
                                            <input
                                                type="password"
                                                value={passwordForm.newPassword}
                                                onChange={(e) => setPasswordForm({ ...passwordForm, newPassword: e.target.value })}
                                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                Bekräfta lösenordet
                                            </label>
                                            <input
                                                type="password"
                                                value={passwordForm.confirmPassword}
                                                onChange={(e) => setPasswordForm({ ...passwordForm, confirmPassword: e.target.value })}
                                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                                required
                                            />
                                        </div>
                                        <div className="flex justify-end">
                                            <button
                                                type="submit"
                                                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
                                            >
                                                <Key className="w-5 h-5 mr-2" />
                                                Uppdatera lösenordet
                                            </button>
                                        </div>
                                    </form>
                                )}

                                {!isEditingPassword && (
                                    <p className="text-gray-600">
                                        Av säkerhetsskäl visas inte ditt lösenord. Klicka på knappen ovan för att byta ditt lösenord.
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default ProfilePage;