import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import { Trophy, Calendar, AlertCircle, Activity, Clock, Archive } from 'lucide-react';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';

const MyCompetitions = () => {
    const { user } = useAuth();
    const [competitions, setCompetitions] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCompetitions = async () => {
            try {
                setLoading(true);
                const response = await axios.get(process.env.REACT_APP_API_URL + '/api/mycompetitions/', {
                    headers: {
                        Authorization: `Token ${localStorage.getItem('authToken')}`,
                    },
                });
                setCompetitions(response.data);
                setLoading(false);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    setError('Du är inte medlem i något lag.');
                } else {
                    setError('Kunde inte ladda tävlingsdata');
                }
                setLoading(false);
            }
        };

        if (user) {
            fetchCompetitions();
        }
    }, [user]);

    if (loading) {
        return (
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex items-center justify-center">
                <img src={require('../images/curling.gif')} alt="Loading" className="w-16 h-16" />
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex flex-col">
            <Helmet>
                <title>Mina Tävlingar | SweepScore</title>
                <meta name="description" content="Se alla dina curlingtävlingar på SweepScore. Följ dina aktiva och avslutade tävlingar." />
            </Helmet>

            <main className="flex-grow container mx-auto px-4 py-16 pt-24">
                {/* Header */}
                <div className="bg-gradient-to-r from-gray-800 to-gray-700 rounded-xl shadow-lg p-5 text-white overflow-hidden mb-6 relative">
                    <div className="absolute top-0 right-0 w-64 h-64 bg-white opacity-5 rounded-full -mr-32 -mt-32"></div>
                    <div className="absolute bottom-0 left-0 w-40 h-40 bg-white opacity-5 rounded-full -ml-20 -mb-20"></div>
                    <div className="relative z-10">
                        <h1 className="text-2xl md:text-3xl font-bold flex items-center">
                            <Trophy className="mr-2 h-6 w-6" /> Mina Tävlingar
                        </h1>
                    </div>
                </div>

                {error ? (
                    <div className="bg-white rounded-xl shadow-lg p-8 text-center">
                        <AlertCircle className="w-16 h-16 text-gray-400 mx-auto mb-4" />
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Inga tävlingar hittades</h2>
                        <p className="text-gray-600 max-w-lg mx-auto">
                            {error}
                        </p>
                    </div>
                ) : (
                    <div className="space-y-8">
                        {/* Active competitions */}
                        <section>
                            <h2 className="text-xl font-bold mb-4 flex items-center">
                                <Activity className="w-5 h-5 mr-2 text-blue-600" />
                                Aktiva tävlingar
                            </h2>

                            {competitions.filter(comp => comp.active).length === 0 ? (
                                <div className="bg-white rounded-xl shadow-md p-6 text-center">
                                    <p className="text-gray-500">Du har inga aktiva tävlingar för tillfället.</p>
                                </div>
                            ) : (
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                    {competitions.filter(comp => comp.active).map(competition => (
                                        <Link to={`/league/${competition.id}`} key={competition.id} className="block h-full">
                                            <div className="bg-white rounded-xl shadow-lg overflow-hidden h-full transition-all hover:shadow-xl hover:translate-y-[-5px]">
                                                <div className="bg-gradient-to-r from-blue-600 to-blue-500 px-6 py-4 text-white relative">
                                                    <h3 className="text-xl font-bold mb-1">{competition.name}</h3>
                                                    <span className="inline-block px-3 py-1 text-xs bg-green-500 text-white rounded-full">
                                                        Aktiv
                                                    </span>
                                                    <div className="absolute top-0 right-0 w-20 h-20 bg-white opacity-5 rounded-full -mr-10 -mt-10"></div>
                                                </div>
                                                
                                                <div className="p-6">
                                                    <div className="flex items-center mb-4">
                                                        <Calendar className="w-5 h-5 text-gray-600 mr-2" />
                                                        <span className="text-gray-700">
                                                            {new Date(competition.start_date).toLocaleDateString('sv-SE')} - {new Date(competition.end_date).toLocaleDateString('sv-SE')}
                                                        </span>
                                                    </div>
                                                    
                                                    <div className="mt-4 text-center">
                                                        <span className="inline-block px-4 py-2 bg-blue-50 text-blue-700 rounded-lg font-medium">
                                                            Visa tävling
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            )}
                        </section>

                        {/* Archived competitions */}
                        <section>
                            <h2 className="text-xl font-bold mb-4 flex items-center">
                                <Archive className="w-5 h-5 mr-2 text-blue-600" />
                                Avslutade tävlingar
                            </h2>

                            {competitions.filter(comp => !comp.active).length === 0 ? (
                                <div className="bg-white rounded-xl shadow-md p-6 text-center">
                                    <p className="text-gray-500">Du har inga avslutade tävlingar.</p>
                                </div>
                            ) : (
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                    {competitions.filter(comp => !comp.active).map(competition => (
                                        <Link to={`/league/${competition.id}`} key={competition.id} className="block h-full">
                                            <div className="bg-white rounded-xl shadow-lg overflow-hidden h-full transition-all hover:shadow-xl hover:translate-y-[-5px]">
                                                <div className="bg-gradient-to-r from-gray-700 to-gray-600 px-6 py-4 text-white relative">
                                                    <h3 className="text-xl font-bold mb-1">{competition.name}</h3>
                                                    <span className="inline-block px-3 py-1 text-xs bg-red-500 text-white rounded-full">
                                                        Avslutad
                                                    </span>
                                                    <div className="absolute top-0 right-0 w-20 h-20 bg-white opacity-5 rounded-full -mr-10 -mt-10"></div>
                                                </div>
                                                
                                                <div className="p-6">
                                                    <div className="flex items-center mb-4">
                                                        <Clock className="w-5 h-5 text-gray-600 mr-2" />
                                                        <span className="text-gray-700">
                                                            {new Date(competition.start_date).toLocaleDateString('sv-SE')} - {new Date(competition.end_date).toLocaleDateString('sv-SE')}
                                                        </span>
                                                    </div>
                                                    
                                                    <div className="mt-4 text-center">
                                                        <span className="inline-block px-4 py-2 bg-gray-50 text-gray-700 rounded-lg font-medium">
                                                            Visa tävling
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            )}
                        </section>
                    </div>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default MyCompetitions;