import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from '../../../AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Clock, User, Check } from 'lucide-react';

const MatchResults = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [leagueMatches, setLeagueMatches] = useState({});
  const [leagues, setLeagues] = useState([]);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [successMessages, setSuccessMessages] = useState({});

  const findNextUpcomingDate = (dates) => {
    const today = new Date();

    const exactTodayMatch = dates.find(dateStr => {
      const matchDate = new Date(dateStr);
      return matchDate.getFullYear() === today.getFullYear() &&
        matchDate.getMonth() === today.getMonth() &&
        matchDate.getDate() === today.getDate();
    });

    if (exactTodayMatch) {
      return exactTodayMatch;
    }

    const futureDates = dates.filter(dateStr => new Date(dateStr) >= today);
    return futureDates.length > 0 ? futureDates[0] : dates[0];
  };

  const fetchMatchesForLeague = useCallback(async (leagueId) => {
    try {
      const matchesResponse = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/matches/`);
      if (!matchesResponse.ok) throw new Error(`Failed to fetch matches for league ${leagueId}`);
      const matchesData = await matchesResponse.json();

      const allDates = matchesData
        .map(match => match.date.split('T')[0])
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => new Date(a) - new Date(b));

      const leagueMatchesByDate = matchesData.reduce((acc, match) => {
        const date = match.date.split('T')[0];
        if (!acc[date]) acc[date] = [];
        acc[date].push(match);
        return acc;
      }, {});

      setAvailableDates(allDates);
      setLeagueMatches(prev => ({ ...prev, [leagueId]: leagueMatchesByDate }));

      const initialDate = findNextUpcomingDate(allDates);
      setSelectedDate(initialDate);
    } catch (error) {
      console.error('Error fetching league matches:', error);
      setError('Failed to fetch matches');
    }
  }, []);

  useEffect(() => {
    axios.defaults.headers.post['X-CSRFToken'] = getCsrfToken();

    const fetchLeagues = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/api/leagues/', {
          headers: {
            'Authorization': `Token ${localStorage.getItem('authToken')}`
          },
        });
        const activeLeagues = response.data.filter(league => league.active);
        setLeagues(activeLeagues);

        const initialLeague = activeLeagues[0].id;
        setSelectedLeague(initialLeague);

        await fetchMatchesForLeague(initialLeague);

        const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setIsMobile(isMobileDevice);
      } catch (error) {
        console.error('Error fetching leagues:', error);
        setError('Failed to fetch leagues');
      }
    };

    fetchLeagues();
  }, [user, navigate, fetchMatchesForLeague]);

  useEffect(() => {
    if (selectedLeague) {
      fetchMatchesForLeague(selectedLeague);
    }
  }, [selectedLeague, fetchMatchesForLeague]);

  const getCsrfToken = () => {
    const match = document.cookie.match(/csrftoken=([^;]+)/);
    return match ? match[1] : '';
  };

  const handleScoreChange = (leagueId, matchId, team, value) => {
    setLeagueMatches((prevMatches) => {
      const updatedMatches = { ...prevMatches };
      for (const date in updatedMatches[leagueId]) {
        const match = updatedMatches[leagueId][date].find(m => m.id === matchId);
        if (match) {
          if (team === 'team1') {
            match.team1_score = value;
          } else {
            match.team2_score = value;
          }
          break;
        }
      }
      return updatedMatches;
    });
  };

  const handleSaveResult = async (leagueId, matchId) => {
    let matchToUpdate;
    for (const date in leagueMatches[leagueId]) {
      const match = leagueMatches[leagueId][date].find(m => m.id === matchId);
      if (match) {
        matchToUpdate = match;
        break;
      }
    }

    if (!matchToUpdate) return;

    const updatedData = {
      team1_score: matchToUpdate.team1_score,
      team2_score: matchToUpdate.team2_score,
    };

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/matches/${matchId}/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify(updatedData),
      });
      if (!response.ok) throw new Error('Failed to update match result');
      
      // Update the match with the response data
      const updatedMatch = await response.json();
      
      // Update the match in the state with reported_by information
      setLeagueMatches((prevMatches) => {
        const updatedMatches = { ...prevMatches };
        for (const date in updatedMatches[leagueId]) {
          const matchIndex = updatedMatches[leagueId][date].findIndex(m => m.id === matchId);
          if (matchIndex !== -1) {
            updatedMatches[leagueId][date][matchIndex] = updatedMatch;
            break;
          }
        }
        return updatedMatches;
      });
      
      // Set success message for specific match
      setSuccessMessages(prev => ({
        ...prev,
        [matchId]: "Resultatet har sparats!"
      }));

      // Clear the success message after 3 seconds
      setTimeout(() => {
        setSuccessMessages(prev => {
          const newMessages = { ...prev };
          delete newMessages[matchId];
          return newMessages;
        });
      }, 3000);
    } catch (error) {
      console.error('Error updating match result:', error);
      alert('Kunde inte spara resultatet. Försök igen.');
    }
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Administrera matcher</h1>

      <div className="mb-6 flex items-center space-x-4">
        <div className="relative">
          <select
            id="league-select"
            value={selectedLeague || ''}
            onChange={(e) => setSelectedLeague(Number(e.target.value))}
            className="block w-full text-white bg-gray-800 border border-gray-300 rounded ring-blue-500 border-blue-500 text-sm p-2.5 mt-2"
          >
            {leagues.map(league => (
              <option key={league.id} value={league.id}>
                {league.name}
              </option>
            ))}
          </select>
        </div>

        <div className="relative">
          <select
            id="date-select"
            value={selectedDate || ''}
            onChange={(e) => setSelectedDate(e.target.value)}
            className="block w-full text-white bg-gray-800 border border-gray-300 rounded ring-blue-500 border-blue-500 text-sm p-2.5 mt-2"
          >
            {availableDates.map(date => (
              <option key={date} value={date}>
                {new Date(date).toLocaleDateString('sv-SE', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </option>
            ))}
          </select>
        </div>
      </div>

      {error && <p className="text-red-500">{error}</p>}
      {leagues.length === 0 ? (
        <p>Inga ligor att visa</p>
      ) : (
        selectedLeague && (
          <div key={selectedLeague} className="mb-8">
            {selectedDate && leagueMatches[selectedLeague] && leagueMatches[selectedLeague][selectedDate] ? (
              <div>
                <h3 className="text-lg font-semibold mb-4">
                  {new Date(selectedDate).toLocaleDateString('sv-SE', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </h3>
                {isMobile ? (
                  leagueMatches[selectedLeague][selectedDate].map((match) => (
                    <AdminMatchCard
                      key={match.id}
                      league={leagues.find(league => league.id === selectedLeague)}
                      match={match}
                      onScoreChange={(team, value) => handleScoreChange(selectedLeague, match.id, team, value)}
                      onSaveResult={() => handleSaveResult(selectedLeague, match.id)}
                      successMessage={successMessages[match.id]}
                    />
                  ))
                ) : (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {leagueMatches[selectedLeague][selectedDate].map((match) => (
                      <AdminMatchCard
                        key={match.id}
                        league={leagues.find(league => league.id === selectedLeague)}
                        match={match}
                        onScoreChange={(team, value) => handleScoreChange(selectedLeague, match.id, team, value)}
                        onSaveResult={() => handleSaveResult(selectedLeague, match.id)}
                        successMessage={successMessages[match.id]}
                      />
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <p>Inga matcher denna dag för denna liga</p>
            )}
          </div>
        )
      )}
    </div>
  );
};

const AdminMatchCard = ({ league, match, onScoreChange, onSaveResult, successMessage }) => {
  const handleScoreChange = (team, value) => {
    onScoreChange(team, value);
  };

  const handleSaveResult = () => {
    onSaveResult();
  };

  return (
    <div className="bg-white shadow-md p-4 mb-4 rounded-lg">
      <div className="flex flex-col">
        <div className="text-gray-700 text-sm text-center mb-2">
          {new Date(match.date).toLocaleDateString()}
        </div>

        <div className="flex flex-col items-center mb-4">
          <div className="text-gray-700 font-medium mb-2">
            {match.team1_name} - ({match.team1_groupid})
          </div>
          <div className="flex items-center justify-center w-full mb-2">
            <input
              type="number"
              className="w-16 h-10 text-center border border-gray-300 rounded-md text-lg"
              placeholder="0"
              value={match.team1_score || ''}
              onChange={(e) => handleScoreChange('team1', e.target.value)}
            />
            <span className="mx-3 text-lg font-bold">-</span>
            <input
              type="number"
              className="w-16 h-10 text-center border border-gray-300 rounded-md text-lg"
              placeholder="0"
              value={match.team2_score || ''}
              onChange={(e) => handleScoreChange('team2', e.target.value)}
            />
          </div>
          <div className="text-gray-700 font-medium">
            {match.team2_name} - ({match.team2_groupid})
          </div>
        </div>

        {/* Reported By Information */}
        {match.reported_by_username && (
          <div className="mb-3 p-2 bg-gray-50 rounded-lg">
            <div className="flex items-center text-xs text-gray-600">
              <User className="w-3.5 h-3.5 mr-1 text-gray-500" />
              <span>Rapporterad av: {match.reported_by_username}</span>
            </div>
            {match.reported_at_formatted && (
              <div className="flex items-center text-xs text-gray-600 mt-1">
                <Clock className="w-3.5 h-3.5 mr-1 text-gray-500" />
                <span>Tidpunkt: {match.reported_at_formatted}</span>
              </div>
            )}
          </div>
        )}

        {/* Success message */}
        {successMessage && (
          <div className="mb-4 p-2 bg-green-50 rounded-lg flex items-center">
            <Check className="w-4 h-4 text-green-500 mr-2" />
            <p className="text-green-600 text-sm">{successMessage}</p>
          </div>
        )}

        <button
          className="w-full bg-gray-600 text-white py-2 px-4 rounded-lg hover:bg-gray-800"
          onClick={handleSaveResult}
        >
          Spara resultat
        </button>
      </div>
    </div>
  );
};

export default MatchResults;