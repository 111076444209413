const TabButton = ({ isActive, onClick, label, icon }) => (
  <button
    className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
      isActive 
        ? 'bg-gray-800 text-white font-medium'
        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
    }`}
    onClick={onClick}
  >
    {icon}
    {label}
  </button>
);

export default TabButton;