import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { User, Users, Calendar, ListOrdered, Trophy, UsersRound, CalendarClock, Info, FileText } from 'lucide-react';
import ArchivedLeagueDetails from '../components/archivedleagues/ArchivedLeagueDetails';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import SEO from '../components/SEO';

const LeagueDetail = () => {
  const { id } = useParams();
  const [league, setLeague] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('overview');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + `/api/leagues/${id}/`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch league data');
        }
        return response.json();
      })
      .then(data => {
        setLeague(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching league:', error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <img
          src={require('../images/curling.gif')}
          alt="Loading..."
          className="w-24 h-24"
        />
      </div>
    );
  }

  if (!league) {
    return <div className="flex justify-center items-center min-h-screen">League not found</div>;
  }

  if (!league.active) {
    return <ArchivedLeagueDetails leagueId={id} leagueData={league} />;
  }

  const navigationLinks = [
    { to: `/league/${id}/matches`, text: 'Matcher', icon: Calendar, priority: true },
    { to: `/league/${id}/table`, text: 'Tabell', icon: ListOrdered, priority: true },
    { to: `/league/${id}/playoff`, text: 'Slutspel', icon: Trophy, priority: false },
    { to: `/league/${id}/teams`, text: 'Lag', icon: UsersRound, priority: false },
    { to: `/league/${id}/schedule`, text: 'Spelschema', icon: CalendarClock, priority: false }
  ];

  return (
    <>
      <SEO
        title={`${league.name} | Curlingtävling`}
        description={`Följ ${league.name} på SweepScore. Se matcher, resultat, tabeller och spelschema för ${league.name} curlingtävling.`}
        keywords={`${league.name}, curlingliga, curlingresultat, curlingtabell, curlingmatcher, ${league.name} resultat`}
        canonicalUrl={`https://sweepscore.se/league/${id}`}
      />

      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex flex-col">
        <Helmet>
          <title>{league.name} | SweepScore</title>
          <meta name="description" content={`Följ ${league.name} - Curlingtävling arrangerad av ${league.organizer}. Se matcher, resultat, tabeller och lag.`} />
          <meta name="keywords" content={`curling, ${league.name}, curlingtävling, ${league.organizer}, curlingmatcher, curlingtabell, curlinglag`} />
        </Helmet>

        <main className="w-full max-w-7xl mx-auto px-4 py-8 pt-20 lg:pt-32 space-y-8 flex-grow">
          {/* Hero Section with League Title - Redesigned and more compact */}
          <section className="relative bg-gradient-to-r from-gray-800 to-gray-700 rounded-xl shadow-lg p-5 text-white overflow-hidden">
            <div className="absolute top-0 right-0 w-64 h-64 bg-white opacity-5 rounded-full -mr-32 -mt-32"></div>
            <div className="absolute bottom-0 left-0 w-40 h-40 bg-white opacity-5 rounded-full -ml-20 -mb-20"></div>

            <div className="relative z-10">
              <div className="flex justify-between items-center mb-3">
                <h1 className="text-2xl md:text-3xl font-bold">{league.name}</h1>

                <span className="inline-block px-3 py-1 text-xs bg-green-500 text-white rounded-full">
                  Aktiv
                </span>
              </div>

              <div className="flex flex-wrap gap-3 mt-3">
                <div className="flex items-center gap-2">
                  <User className="w-4 h-4 text-blue-300" />
                  <span className="text-sm">{league.organizer || 'Ej specificerad'}</span>
                </div>

                <div className="flex items-center gap-2">
                  <Calendar className="w-4 h-4 text-blue-300" />
                  <span className="text-sm">
                    {new Date(league.start_date).toLocaleDateString('sv-SE')} - {new Date(league.end_date).toLocaleDateString('sv-SE')}
                  </span>
                </div>
              </div>
            </div>
          </section>

          {/* Mobile Quick Actions - Only show on mobile */}
          {isMobile && (
            <div className="grid grid-cols-2 gap-3 mb-4">
              {navigationLinks.filter(link => link.priority).map((link) => (
                <Link
                  key={link.to}
                  to={link.to}
                  className="flex flex-col items-center justify-center bg-white rounded-lg shadow-md p-3 hover:bg-gray-50 transition-colors"
                >
                  <link.icon className="w-6 h-6 text-blue-600 mb-1" />
                  <span className="font-medium text-gray-900">{link.text}</span>
                </Link>
              ))}
            </div>
          )}

          {/* Tabs Navigation */}
          <div className="bg-white rounded-lg shadow-md p-2">
            <div className="flex flex-wrap gap-2">
              <button
                onClick={() => setActiveTab('overview')}
                className={`px-4 py-2 rounded-lg flex items-center gap-2 ${activeTab === 'overview' ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:bg-gray-100'
                  }`}
              >
                <Info className="w-5 h-5" />
                <span>Översikt</span>
              </button>

              <button
                onClick={() => setActiveTab('schedule')}
                className={`px-4 py-2 rounded-lg flex items-center gap-2 ${activeTab === 'schedule' ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:bg-gray-100'
                  }`}
              >
                <CalendarClock className="w-5 h-5" />
                <span>Spelschema</span>
              </button>

              <button
                onClick={() => setActiveTab('reserves')}
                className={`px-4 py-2 rounded-lg flex items-center gap-2 ${activeTab === 'reserves' ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:bg-gray-100'
                  }`}
              >
                <Users className="w-5 h-5" />
                <span>Reservspelare</span>
              </button>

              <button
                onClick={() => setActiveTab('playoffs')}
                className={`px-4 py-2 rounded-lg flex items-center gap-2 ${activeTab === 'playoffs' ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:bg-gray-100'
                  }`}
              >
                <Trophy className="w-5 h-5" />
                <span>Slutspel</span>
              </button>
            </div>
          </div>

          {/* Tab Content */}
          <div className="space-y-8">
            {/* Overview Tab */}
            {activeTab === 'overview' && (
              <section className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                {/* League Information Card */}
                <div className="lg:col-span-2 bg-white rounded-xl shadow-md p-6">
                  <h2 className="text-2xl font-bold mb-4 flex items-center">
                    <FileText className="w-6 h-6 mr-2 text-blue-600" />
                    Information om tävlingen
                  </h2>

                  {league.info ? (
                    <div className="prose max-w-none text-gray-700">
                      <p>{league.info}</p>
                    </div>
                  ) : (
                    <p className="text-gray-500 italic">Ingen information tillgänglig för denna tävling.</p>
                  )}

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
                    <div className="bg-gray-50 rounded-lg p-4">
                      <h3 className="font-medium text-gray-900 mb-2 flex items-center">
                        <Calendar className="w-5 h-5 mr-2 text-blue-600" />
                        Tävlingsperiod
                      </h3>
                      <p className="text-gray-700">
                        {new Date(league.start_date).toLocaleDateString('sv-SE')} - {new Date(league.end_date).toLocaleDateString('sv-SE')}
                      </p>
                    </div>

                    <div className="bg-gray-50 rounded-lg p-4">
                      <h3 className="font-medium text-gray-900 mb-2 flex items-center">
                        <User className="w-5 h-5 mr-2 text-blue-600" />
                        Arrangör
                      </h3>
                      <p className="text-gray-700">{league.organizer || 'Ej specificerad'}</p>
                    </div>
                  </div>
                </div>

                {/* Quick Links & Stats Card */}
                <div className="bg-white rounded-xl shadow-md p-6">
                  <h2 className="text-xl font-bold mb-4 flex items-center">
                    <ListOrdered className="w-6 h-6 mr-2 text-blue-600" />
                    Snabblänkar
                  </h2>

                  <div className="space-y-3">
                    {navigationLinks.map((link) => (
                      <Link
                        key={link.to}
                        to={link.to}
                        className="flex items-center justify-between p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                      >
                        <div className="flex items-center gap-3">
                          <link.icon className="w-5 h-5 text-blue-600" />
                          <span className="font-medium text-gray-900">{link.text}</span>
                        </div>
                        <svg className="w-5 h-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                      </Link>
                    ))}
                  </div>

                  <div className="mt-8 pt-6 border-t border-gray-200">
                    <h3 className="font-medium text-gray-900 mb-4">Tävlingsdetaljer</h3>

                    <div className="space-y-3">
                      {league.teamscount > 0 && (
                        <div className="flex justify-between items-center">
                          <span className="text-gray-600">Antal lag:</span>
                          <span className="font-medium">{league.teamscount}</span>
                        </div>
                      )}

                      {league.participants > 0 && (
                        <div className="flex justify-between items-center">
                          <span className="text-gray-600">Antal deltagare:</span>
                          <span className="font-medium">{league.participants}</span>
                        </div>
                      )}

                      {league.gamesplayed > 0 && (
                        <div className="flex justify-between items-center">
                          <span className="text-gray-600">Matcher spelade:</span>
                          <span className="font-medium">{league.gamesplayed}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            )}

            {/* Schedule Tab */}
            {activeTab === 'schedule' && (
              <section className="bg-white rounded-xl shadow-md p-6">
                <h2 className="text-2xl font-bold mb-6 flex items-center">
                  <CalendarClock className="w-6 h-6 mr-2 text-blue-600" />
                  Spelschema
                </h2>

                <div className="space-y-6">
                  <p className="text-gray-700">
                    Se det kompletta spelschemat med alla matcher och tider för {league.name}.
                  </p>

                  <Link
                    to={`/league/${id}/schedule`}
                    className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                  >
                    <Calendar className="mr-2 w-5 h-5" />
                    Visa spelschema
                  </Link>

                  <div className="mt-6 pt-6 border-t border-gray-200">
                    <h3 className="font-semibold text-xl mb-4">Slutspelsdatum</h3>

                    {league.Playoff ? (
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {/* A-Slutspel (Winner Bracket) */}
                        <div className="col-span-full mb-2">
                          <h4 className="font-medium text-blue-700 mb-2">A-Slutspel</h4>
                        </div>

                        {league.quarterfinal_datetime && (
                          <div className="bg-gray-50 rounded-lg p-4">
                            <h5 className="font-medium text-gray-900 mb-1">Kvartsfinal</h5>
                            <p className="text-gray-700">
                              {new Date(league.quarterfinal_datetime).toLocaleString('sv-SE')}
                            </p>
                          </div>
                        )}

                        {league.semifinal_datetime && (
                          <div className="bg-gray-50 rounded-lg p-4">
                            <h5 className="font-medium text-gray-900 mb-1">Semifinal</h5>
                            <p className="text-gray-700">
                              {new Date(league.semifinal_datetime).toLocaleString('sv-SE')}
                            </p>
                          </div>
                        )}

                        {league.final_datetime && (
                          <div className="bg-gray-50 rounded-lg p-4">
                            <h5 className="font-medium text-gray-900 mb-1">Final</h5>
                            <p className="text-gray-700">
                              {new Date(league.final_datetime).toLocaleString('sv-SE')}
                            </p>
                          </div>
                        )}

                        {/* B-Slutspel (Loser Bracket) */}
                        {(league.loser_round1_datetime || league.loser_round2_datetime || league.loser_final_datetime) && (
                          <>
                            <div className="col-span-full mt-4 mb-2">
                              <h4 className="font-medium text-blue-700 mb-2">B-Slutspel</h4>
                            </div>

                            {league.loser_round1_datetime && (
                              <div className="bg-gray-50 rounded-lg p-4">
                                <h5 className="font-medium text-gray-900 mb-1">Kvartsfinal</h5>
                                <p className="text-gray-700">
                                  {new Date(league.loser_round1_datetime).toLocaleString('sv-SE')}
                                </p>
                              </div>
                            )}

                            {league.loser_round2_datetime && (
                              <div className="bg-gray-50 rounded-lg p-4">
                                <h5 className="font-medium text-gray-900 mb-1">Semifinal</h5>
                                <p className="text-gray-700">
                                  {new Date(league.loser_round2_datetime).toLocaleString('sv-SE')}
                                </p>
                              </div>
                            )}

                            {league.loser_final_datetime && (
                              <div className="bg-gray-50 rounded-lg p-4">
                                <h5 className="font-medium text-gray-900 mb-1">Final</h5>
                                <p className="text-gray-700">
                                  {new Date(league.loser_final_datetime).toLocaleString('sv-SE')}
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <p className="text-gray-500 italic">Denna tävling har inget slutspel.</p>
                    )}
                  </div>
                </div>
              </section>
            )}

            {/* Reserves Tab */}
            {activeTab === 'reserves' && (
              <section className="bg-white rounded-xl shadow-md p-6">
                <h2 className="text-2xl font-bold mb-6 flex items-center">
                  <Users className="w-6 h-6 mr-2 text-blue-600" />
                  Reservspelare
                </h2>

                {league.reserve_players && league.reserve_players.length > 0 ? (
                  <div className="space-y-6">
                    <p className="text-gray-700 mb-4">
                      Följande reservspelare finns tillgängliga för denna tävling:
                    </p>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                      {league.reserve_players.map((player) => (
                        <div
                          key={player.id}
                          className="flex items-center p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                        >
                          <div className="bg-blue-100 p-2 rounded-full mr-3">
                            <User className="w-5 h-5 text-blue-600" />
                          </div>
                          <div>
                            <p className="font-medium text-gray-900">
                              {player.first_name} {player.last_name}
                            </p>
                            {player.phone_number && (
                              <p className="text-sm text-gray-600">
                                {player.phone_number}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <p className="text-gray-500 italic">Inga reservspelare registrerade för denna tävling.</p>
                )}
              </section>
            )}

            {activeTab === 'playoffs' && (
              <section className="bg-white rounded-xl shadow-md p-6">
                <h2 className="text-2xl font-bold mb-6 flex items-center">
                  <Trophy className="w-6 h-6 mr-2 text-blue-600" />
                  Slutspel
                </h2>

                <div className="space-y-6">
                  {league.Playoff ? (
                    <>
                      <p className="text-gray-700">
                        {league.name} har ett slutspel där de bäst placerade lagen från gruppspelet går vidare.
                        Se alla slutspelsmatcher och resultat genom att klicka på knappen nedan.
                      </p>

                      <div className="bg-blue-50 rounded-lg p-4 border border-blue-100 mb-6">
                        <h3 className="text-lg font-semibold text-blue-800 mb-4">Slutspelsdatum</h3>

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                          {/* A-Slutspel (Winner Bracket) */}
                          <div className="col-span-full mb-2">
                            <h4 className="font-medium text-blue-700 mb-2">A-Slutspel</h4>
                          </div>

                          {league.round16_datetime && (
                            <div className="bg-gray-50 rounded-lg p-4">
                              <h5 className="font-medium text-gray-900 mb-1">Åttondelsfinal</h5>
                              <p className="text-gray-700">
                                {new Date(league.round16_datetime).toLocaleString('sv-SE')}
                              </p>
                            </div>
                          )}

                          {league.quarterfinal_datetime && (
                            <div className="bg-gray-50 rounded-lg p-4">
                              <h5 className="font-medium text-gray-900 mb-1">Kvartsfinal</h5>
                              <p className="text-gray-700">
                                {new Date(league.quarterfinal_datetime).toLocaleString('sv-SE')}
                              </p>
                            </div>
                          )}

                          {league.semifinal_datetime && (
                            <div className="bg-gray-50 rounded-lg p-4">
                              <h5 className="font-medium text-gray-900 mb-1">Semifinal</h5>
                              <p className="text-gray-700">
                                {new Date(league.semifinal_datetime).toLocaleString('sv-SE')}
                              </p>
                            </div>
                          )}

                          {league.final_datetime && (
                            <div className="bg-gray-50 rounded-lg p-4">
                              <h5 className="font-medium text-gray-900 mb-1">Final</h5>
                              <p className="text-gray-700">
                                {new Date(league.final_datetime).toLocaleString('sv-SE')}
                              </p>
                            </div>
                          )}

                          {league.third_place_datetime && (
                            <div className="bg-gray-50 rounded-lg p-4">
                              <h5 className="font-medium text-gray-900 mb-1">Match om 3:e plats</h5>
                              <p className="text-gray-700">
                                {new Date(league.third_place_datetime).toLocaleString('sv-SE')}
                              </p>
                            </div>
                          )}

                          {league.fifth_place_datetime && (
                            <div className="bg-gray-50 rounded-lg p-4">
                              <h5 className="font-medium text-gray-900 mb-1">Match om 5:e plats</h5>
                              <p className="text-gray-700">
                                {new Date(league.fifth_place_datetime).toLocaleString('sv-SE')}
                              </p>
                            </div>
                          )}

                          {/* B-Slutspel (Loser Bracket) */}
                          {(league.loser_round16_datetime || league.loser_round1_datetime ||
                            league.loser_round2_datetime || league.loser_final_datetime ||
                            league.loser_third_place_datetime || league.loser_fifth_place_datetime) && (
                              <>
                                <div className="col-span-full mt-6 mb-2">
                                  <h4 className="font-medium text-blue-700 mb-2">B-Slutspel</h4>
                                </div>

                                {league.loser_round16_datetime && (
                                  <div className="bg-gray-50 rounded-lg p-4">
                                    <h5 className="font-medium text-gray-900 mb-1">Åttondelsfinal</h5>
                                    <p className="text-gray-700">
                                      {new Date(league.loser_round16_datetime).toLocaleString('sv-SE')}
                                    </p>
                                  </div>
                                )}

                                {league.loser_round1_datetime && (
                                  <div className="bg-gray-50 rounded-lg p-4">
                                    <h5 className="font-medium text-gray-900 mb-1">Kvartsfinal</h5>
                                    <p className="text-gray-700">
                                      {new Date(league.loser_round1_datetime).toLocaleString('sv-SE')}
                                    </p>
                                  </div>
                                )}

                                {league.loser_round2_datetime && (
                                  <div className="bg-gray-50 rounded-lg p-4">
                                    <h5 className="font-medium text-gray-900 mb-1">Semifinal</h5>
                                    <p className="text-gray-700">
                                      {new Date(league.loser_round2_datetime).toLocaleString('sv-SE')}
                                    </p>
                                  </div>
                                )}

                                {league.loser_final_datetime && (
                                  <div className="bg-gray-50 rounded-lg p-4">
                                    <h5 className="font-medium text-gray-900 mb-1">Final</h5>
                                    <p className="text-gray-700">
                                      {new Date(league.loser_final_datetime).toLocaleString('sv-SE')}
                                    </p>
                                  </div>
                                )}

                                {league.loser_third_place_datetime && (
                                  <div className="bg-gray-50 rounded-lg p-4">
                                    <h5 className="font-medium text-gray-900 mb-1">Match om 3:e plats</h5>
                                    <p className="text-gray-700">
                                      {new Date(league.loser_third_place_datetime).toLocaleString('sv-SE')}
                                    </p>
                                  </div>
                                )}

                                {league.loser_fifth_place_datetime && (
                                  <div className="bg-gray-50 rounded-lg p-4">
                                    <h5 className="font-medium text-gray-900 mb-1">Match om 5:e plats</h5>
                                    <p className="text-gray-700">
                                      {new Date(league.loser_fifth_place_datetime).toLocaleString('sv-SE')}
                                    </p>
                                  </div>
                                )}
                              </>
                            )}
                        </div>
                      </div>

                      <div className="mt-8">
                        <Link
                          to={`/league/${id}/playoff`}
                          className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                        >
                          <Trophy className="mr-2 w-5 h-5" />
                          Visa slutspel
                        </Link>
                      </div>
                    </>
                  ) : (
                    <p className="text-gray-500 italic">Denna tävling har inget slutspel.</p>
                  )}
                </div>
              </section>
            )}
          </div>
        </main>

        <Footer />
      </div>
    </>
  );
};

export default LeagueDetail;