import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Calendar, ChevronLeft, ChevronRight, Trophy, Clock, MapPin, AlertCircle, Info } from 'lucide-react';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import hckLogo from '../images/HCK.png';
import SEO from '../components/SEO';


const MatchesPage = () => {
  const { leagueId } = useParams();
  const [leagueName, setLeagueName] = useState('');
  const [matchesByDate, setMatchesByDate] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [currentDate, setCurrentDate] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchLeagueName = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/`);
        if (!response.ok) {
          throw new Error('Failed to fetch league name');
        }
        const data = await response.json();
        setLeagueName(data.name);
      } catch (error) {
        console.error('Error fetching league name:', error);
        setError(error.message);
      }
    };

    const fetchMatches = async () => {
      try {
        setLoading(true);
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/matches/`);
        if (!response.ok) throw new Error('Network response was not ok');

        const data = await response.json();

        // Group matches by date
        const matchesByDateObj = {};
        const uniqueDates = new Set();

        data.forEach(match => {
          const matchDate = match.date.split('T')[0];
          uniqueDates.add(matchDate);

          if (!matchesByDateObj[matchDate]) {
            matchesByDateObj[matchDate] = [];
          }

          matchesByDateObj[matchDate].push(match);
        });

        setMatchesByDate(matchesByDateObj);

        const sortedDates = Array.from(uniqueDates).sort((a, b) => new Date(a) - new Date(b));
        setAvailableDates(sortedDates);

        const nextDate = findNextUpcomingDate(sortedDates);
        setCurrentDate(nextDate);

      } catch (error) {
        console.error('Error fetching matches:', error);
        setError('Failed to fetch matches');
      } finally {
        setLoading(false);
      }
    };

    fetchLeagueName();
    fetchMatches();
  }, [leagueId]);

  const findNextUpcomingDate = (dates) => {
    const today = new Date();

    const exactTodayMatch = dates.find(dateStr => {
      const matchDate = new Date(dateStr);
      return matchDate.getFullYear() === today.getFullYear() &&
        matchDate.getMonth() === today.getMonth() &&
        matchDate.getDate() === today.getDate();
    });

    if (exactTodayMatch) {
      return exactTodayMatch;
    }

    const futureDates = dates.filter(dateStr => new Date(dateStr) >= today);
    return futureDates.length > 0 ? futureDates[0] : dates[0];
  };

  const extractTimeFromTimestamp = (timestamp) => {
    if (!timestamp) return 'Okänd tid';

    try {
      const date = new Date(timestamp);
      return date.toLocaleTimeString('sv-SE', {
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      console.error('Error parsing timestamp:', timestamp);
      return 'Okänd tid';
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('sv-SE', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  const handlePreviousDate = () => {
    const dateIndex = availableDates.indexOf(currentDate);
    if (dateIndex > 0) {
      setCurrentDate(availableDates[dateIndex - 1]);
      //setCurrentMatchIndex(0);
    }
  };

  const handleNextDate = () => {
    const dateIndex = availableDates.indexOf(currentDate);
    if (dateIndex < availableDates.length - 1) {
      setCurrentDate(availableDates[dateIndex + 1]);
      //setCurrentMatchIndex(0);
    }
  };

  const getScoreColor = (team1Score, team2Score, isTeam1) => {
    // Don't apply any special color if scores aren't available or it's 0-0
    if (team1Score === null || team2Score === null || (team1Score === 0 && team2Score === 0)) return '';

    // Determine which team won
    const team1Won = team1Score > team2Score;
    const team2Won = team2Score > team1Score;

    // Apply the winning/losing/draw classes
    if (isTeam1) {
      return team1Won ? 'text-green-600 font-bold' : team2Won ? 'text-red-600' : 'text-yellow-600';
    } else {
      return team2Won ? 'text-green-600 font-bold' : team1Won ? 'text-red-600' : 'text-yellow-600';
    }
  };

  const MatchCard = ({ match }) => (
    <div className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-all duration-300 mb-4">
      {/* Date and round info */}
      <div className="bg-gray-50 p-3 border-b border-gray-100 flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <Clock className="w-4 h-4 text-gray-500" />
          <span className="text-sm text-gray-700">{extractTimeFromTimestamp(match.date)}</span>
        </div>
        <div className="flex items-center space-x-2">
          <MapPin className="w-4 h-4 text-gray-500" />
          <span className="text-sm text-gray-700">Bana {match.track}</span>
        </div>
        {match.round_number && (
          <div className="px-2 py-1 bg-blue-50 rounded-full">
            <span className="text-xs text-blue-700">Omgång {match.round_number}</span>
          </div>
        )}
      </div>

      {/* Teams and scores */}
      <div className="p-4">
        {/* Team 1 - Red background */}
        <div className="flex items-center justify-between bg-red-800 text-white p-3 rounded-t-lg">
          <div className="flex items-center">
            <div className="w-8 h-8 flex items-center justify-center mr-3">
              <img src={hckLogo} alt="HCK logo" className="w-6 h-6 object-contain" />
            </div>
            <div>
              <p className="font-semibold">{match.team1_name}</p>
              <p className="text-xs text-red-200">{match.team1_groupid}</p>
            </div>
          </div>
          <div className={`text-2xl font-bold ${getScoreColor(match.team1_score, match.team2_score, true)}`}>
            {match.team1_score !== null ? match.team1_score : '-'}
          </div>
        </div>

        {/* Vs divider */}
        <div className="flex items-center justify-center py-1 bg-gray-200 text-gray-600 text-sm">
          VS
        </div>

        {/* Team 2 - Yellow background */}
        <div className="flex items-center justify-between bg-yellow-300 text-gray-900 p-3 rounded-b-lg">
          <div className="flex items-center">
            <div className="w-8 h-8 flex items-center justify-center mr-3">
              <img src={hckLogo} alt="HCK logo" className="w-6 h-6 object-contain" />
            </div>
            <div>
              <p className="font-semibold">{match.team2_name}</p>
              <p className="text-xs text-yellow-800">{match.team2_groupid}</p>
            </div>
          </div>
          <div className={`text-2xl font-bold ${getScoreColor(match.team1_score, match.team2_score, false)}`}>
            {match.team2_score !== null ? match.team2_score : '-'}
          </div>
        </div>

        {/* Match status */}
        <div className="mt-3 flex justify-end">
          {match.team1_score !== null && (match.team1_score > 0 || match.team2_score > 0) ? (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
              <Trophy className="w-3 h-3 mr-1" />
              Spelad
            </span>
          ) : (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
              <Calendar className="w-3 h-3 mr-1" />
              Kommande
            </span>
          )}
        </div>
      </div>
    </div>
  );

  const MobileView = () => {
    if (!currentDate || !matchesByDate[currentDate]) return null;

    return (
      <div>
        <div className="flex items-center justify-between mb-4">
          <button
            onClick={handlePreviousDate}
            className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-colors"
            disabled={availableDates.indexOf(currentDate) === 0}
          >
            <ChevronLeft size={20} />
          </button>

          <div className="text-center">
            <h3 className="font-medium">{formatDate(currentDate)}</h3>
            <p className="text-sm text-gray-500">{matchesByDate[currentDate]?.length || 0} matcher</p>
          </div>

          <button
            onClick={handleNextDate}
            className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-colors"
            disabled={availableDates.indexOf(currentDate) === availableDates.length - 1}
          >
            <ChevronRight size={20} />
          </button>
        </div>

        <div className="mb-4 p-4 bg-blue-50 rounded-lg flex items-start">
          <Info className="w-5 h-5 text-blue-500 mr-2 mt-0.5 flex-shrink-0" />
          <p className="text-sm text-blue-800">
            Alla matcher för valt datum visas nedan. Scrolla för att se fler matcher.
          </p>
        </div>

        <div className="space-y-4">
          {matchesByDate[currentDate].length === 0 ? (
            <div className="text-center p-8 bg-gray-50 rounded-lg">
              <p className="text-gray-500">Inga matcher denna dag</p>
            </div>
          ) : (
            matchesByDate[currentDate].map((match) => (
              <MatchCard key={match.id} match={match} />
            ))
          )}
        </div>
      </div>
    );
  };

  const DesktopView = () => {
    if (!currentDate || !matchesByDate[currentDate]) return null;

    return (
      <div>
        <div className="mb-6 flex items-center space-x-4">
          <div className="relative w-full max-w-md">
            <select
              id="date-select"
              value={currentDate || ''}
              onChange={(e) => setCurrentDate(e.target.value)}
              className="block w-full text-white bg-gray-800 border border-gray-300 rounded ring-blue-500 border-blue-500 text-sm p-2.5 mt-2"
            >
              {availableDates.map((date) => (
                <option key={date} value={date}>
                  {formatDate(date)}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {matchesByDate[currentDate].length === 0 ? (
            <div className="text-center p-8 bg-gray-50 rounded-lg md:col-span-2">
              <p className="text-gray-500">Inga matcher denna dag</p>
            </div>
          ) : (
            matchesByDate[currentDate].map((match) => (
              <MatchCard key={match.id} match={match} />
            ))
          )}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <img
          src={require('../images/curling.gif')}
          alt="Loading..."
          className="w-24 h-24"
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="bg-white rounded-xl shadow-lg p-8 text-center max-w-xl">
          <AlertCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />
          <h2 className="text-2xl font-semibold text-gray-800 mb-2">Ett fel har uppstått</h2>
          <p className="text-gray-600 mb-4">{error}</p>
        </div>
      </div>
    );
  }

  if (availableDates.length === 0) {
    return (
      <div className="min-h-screen bg-gray-100 flex flex-col items-center pt-16">
        <main className="flex-grow container mx-auto px-4 py-8">
          <div className="bg-gradient-to-r from-gray-800 to-gray-700 rounded-xl shadow-lg p-5 text-white overflow-hidden mb-6 relative">
            <div className="absolute top-0 right-0 w-64 h-64 bg-white opacity-5 rounded-full -mr-32 -mt-32"></div>
            <div className="absolute bottom-0 left-0 w-40 h-40 bg-white opacity-5 rounded-full -ml-20 -mb-20"></div>
            <div className="relative z-10">
              <div className="flex justify-between items-center">
                <h1 className="text-2xl md:text-3xl font-bold flex items-center">
                  <Calendar className="mr-2 h-6 w-6" /> Matcher
                </h1>

                <span className="text-sm text-blue-300">{leagueName}</span>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-lg p-8 text-center">
            <AlertCircle className="w-16 h-16 text-gray-400 mx-auto mb-4" />
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Inga matcher tillgängliga</h2>
            <p className="text-gray-600 max-w-lg mx-auto">
              Det finns inga matcher schemalagda för denna tävling.
              Matcher kommer att visas när de har lagts till i spelschemat.
            </p>
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <>
      <SEO
        title={`Matcher i ${leagueName} | Curlingtävling`}
        description={`Alla matcher i ${leagueName}. Se kommande, pågående och avslutade curlingmatcher med fullständiga resultat och statistik.`}
        keywords={`curlingmatcher, ${leagueName} matcher, curlingresultat, matchschema curling, curlingmöten`}
        canonicalUrl={`https://sweepscore.se/league/${leagueId}/matches`}
      />

      <div className="min-h-screen bg-gray-100 flex flex-col">
        <Helmet>
          <title>{`Matcher - ${leagueName}`} | SweepScore</title>
          <meta name="description" content={`Se alla matcher för ${leagueName}. Följ resultat och kommande matcher i turneringen.`} />
        </Helmet>

        <main className="flex-grow container mx-auto px-4 py-16 pt-24 lg:pt-32">
          {/* Header */}
          <div className="bg-gradient-to-r from-gray-800 to-gray-700 rounded-xl shadow-lg p-5 text-white overflow-hidden mb-6 relative">
            <div className="absolute top-0 right-0 w-64 h-64 bg-white opacity-5 rounded-full -mr-32 -mt-32"></div>
            <div className="absolute bottom-0 left-0 w-40 h-40 bg-white opacity-5 rounded-full -ml-20 -mb-20"></div>
            <div className="relative z-10">
              <div className="flex justify-between items-center">
                <h1 className="text-2xl md:text-3xl font-bold flex items-center">
                  <Calendar className="mr-2 h-6 w-6" /> Matcher
                </h1>

                <span className="text-sm text-blue-300">{leagueName}</span>
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="bg-white rounded-xl shadow-lg p-6">
            {isMobile ? <MobileView /> : <DesktopView />}
          </div>

          {/* Legend */}
          <div className="mt-6 bg-white rounded-xl shadow-lg p-6">
            <h3 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
              <Info className="w-5 h-5 mr-2 text-blue-600" />
              Teckenförklaring
            </h3>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="flex items-center space-x-3">
                <div className="w-8 h-8 bg-red-800 rounded-md"></div>
                <span className="text-gray-700">Hemmalag</span>
              </div>

              <div className="flex items-center space-x-3">
                <div className="w-8 h-8 bg-yellow-300 rounded-md"></div>
                <span className="text-gray-700">Bortalag</span>
              </div>

              <div className="flex items-center space-x-3">
                <div className="flex">
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    <Trophy className="w-3 h-3 mr-1" />
                    Spelad
                  </span>
                </div>
                <span className="text-gray-700">Match avslutad</span>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default MatchesPage;