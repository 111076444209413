import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import { Calendar, Clock, AlertCircle, CheckCircle, Info, Trophy, User, Calendar as CalendarIcon } from 'lucide-react';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';

const MyMatches = () => {
    const { user } = useAuth();
    const [leagueMatches, setLeagueMatches] = useState([]);
    const [playoffMatches, setPlayoffMatches] = useState([]);
    const [error, setError] = useState(null);
    const [statusMessages, setStatusMessages] = useState({});
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchMatches = async () => {
            try {
                setLoading(true);
                const response = await axios.get(process.env.REACT_APP_API_URL + '/api/mymatches/', {
                    headers: {
                        Authorization: `Token ${localStorage.getItem('authToken')}`,
                    },
                });
                setLeagueMatches(response.data.league_matches);
                setPlayoffMatches(response.data.playoff_matches);
                setLoading(false);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    setError('Du är inte medlem i något lag.');
                } else {
                    setError('Kunde inte ladda matchdata');
                }
                setLoading(false);
            }
        };

        if (user) {
            fetchMatches();
        }
    }, [user]);

    const handleScoreChange = (matchId, team, score, isPlayoff) => {
        setStatusMessages(prev => ({
            ...prev,
            [matchId]: null
        }));

        if (isPlayoff) {
            setPlayoffMatches(prevMatches =>
                prevMatches.map(match =>
                    match.id === matchId ? { ...match, [`team${team}_score`]: score } : match
                )
            );
        } else {
            setLeagueMatches(prevMatches =>
                prevMatches.map(match =>
                    match.id === matchId ? { ...match, [`team${team}_score`]: score } : match
                )
            );
        }
    };

    const handleSaveResult = async (matchId, isPlayoff) => {
        const match = isPlayoff
            ? playoffMatches.find(m => m.id === matchId)
            : leagueMatches.find(m => m.id === matchId);
    
        const updatedData = {
            team1_score: match.team1_score,
            team2_score: match.team2_score,
        };
    
        const endpoint = isPlayoff
            ? `${process.env.REACT_APP_API_URL}/api/playoff-matches/${matchId}/update_result/`
            : `${process.env.REACT_APP_API_URL}/api/matches/${matchId}/update_user_result/`;
    
        try {
            const { data } = await axios.patch(endpoint, updatedData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('authToken')}`
                }
            });
    
            setStatusMessages(prev => ({
                ...prev,
                [matchId]: { type: 'success', message: data.message || 'Resultatet har sparats!' }
            }));
    
            // Refresh the match data to get updated reporting information
            const matchesResponse = await axios.get(process.env.REACT_APP_API_URL + '/api/mymatches/', {
                headers: {
                    Authorization: `Token ${localStorage.getItem('authToken')}`,
                },
            });
            
            if (isPlayoff) {
                setPlayoffMatches(matchesResponse.data.playoff_matches);
            } else {
                setLeagueMatches(matchesResponse.data.league_matches);
            }
    
            setTimeout(() => {
                setStatusMessages(prev => ({
                    ...prev,
                    [matchId]: null
                }));
            }, 3000);
    
        } catch (error) {
            console.error('Error updating match result:', error.response?.data || error.message);
            setStatusMessages(prev => ({
                ...prev,
                [matchId]: { 
                    type: 'error', 
                    message: error.response?.data?.error || 'Kunde inte spara resultatet. Försök igen.'
                }
            }));
        }
    };

    const getTeamName = (match, teamNumber, isPlayoff) => {
        if (isPlayoff) {
            const teamDetails = match[`team${teamNumber}_details`];
            if (teamDetails?.team?.name) {
                return teamDetails.team.name;
            } else if (teamDetails?.name) {
                return teamDetails.name;
            }
            return `Lag ${teamNumber}`;
        }
        return match[`team${teamNumber}_name`] || `Lag ${teamNumber}`;
    };

    const MatchCard = ({ match, isPlayoff }) => (
        <div className="bg-white rounded-xl shadow-lg overflow-hidden mb-4">
            <div className="bg-gradient-to-r from-gray-800 to-gray-700 px-4 py-2 text-white">
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        {isPlayoff ? (
                            <Trophy className="w-4 h-4 mr-2" />
                        ) : (
                            <Calendar className="w-4 h-4 mr-2" />
                        )}
                        <span>
                            {isPlayoff ? match.match_type || 'Slutspel' : `Omgång ${match.round_number || '-'}`}
                        </span>
                    </div>
                    <span className="text-sm">
                        {new Date(isPlayoff ? match.match_date : match.date).toLocaleDateString()}
                    </span>
                </div>
            </div>
            
            <div className="p-4">
                {isMobile ? (
                    /* Mobile Layout */
                    <div className="flex flex-col">
                        <div className="flex flex-col items-center mb-6">
                            <div className="w-full flex justify-between items-center p-3 mb-3 bg-gray-50 rounded-lg">
                                <p className="font-medium text-gray-900">{getTeamName(match, 1, isPlayoff)}</p>
                                <input
                                    type="number"
                                    className="w-16 h-10 text-center border border-gray-300 rounded-md text-lg ml-2"
                                    placeholder="0"
                                    value={match.team1_score || ''}
                                    onChange={(e) => handleScoreChange(match.id, '1', e.target.value, isPlayoff)}
                                />
                            </div>
                            
                            <div className="w-full flex justify-between items-center p-3 bg-gray-50 rounded-lg">
                                <p className="font-medium text-gray-900">{getTeamName(match, 2, isPlayoff)}</p>
                                <input
                                    type="number"
                                    className="w-16 h-10 text-center border border-gray-300 rounded-md text-lg ml-2"
                                    placeholder="0"
                                    value={match.team2_score || ''}
                                    onChange={(e) => handleScoreChange(match.id, '2', e.target.value, isPlayoff)}
                                />
                            </div>
                        </div>
                        
                        <button
                            className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors"
                            onClick={() => handleSaveResult(match.id, isPlayoff)}
                        >
                            Spara resultat
                        </button>
                        
                        {/* Reporting information */}
                        {match.reported_by_username && (
                            <div className="mt-3 p-2 bg-gray-50 rounded-lg text-sm text-gray-600 flex items-center">
                                <User className="w-4 h-4 mr-2 text-gray-500" />
                                <div>
                                    <p>Rapporterat av: <span className="font-medium">{match.reported_by_username}</span></p>
                                    {match.reported_at_formatted && <p>Tid: {match.reported_at_formatted}</p>}
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    /* Desktop Layout */
                    <div>
                        <div className="grid grid-cols-5 items-center mb-4 gap-4">
                            <div className="col-span-2 font-medium text-gray-900">{getTeamName(match, 1, isPlayoff)}</div>
                            <div className="flex justify-center items-center">
                                <input
                                    type="number"
                                    className="w-16 text-center border border-gray-300 rounded-md p-2"
                                    placeholder="0"
                                    value={match.team1_score || ''}
                                    onChange={(e) => handleScoreChange(match.id, '1', e.target.value, isPlayoff)}
                                />
                                <span className="mx-2 text-lg font-bold">-</span>
                                <input
                                    type="number"
                                    className="w-16 text-center border border-gray-300 rounded-md p-2"
                                    placeholder="0"
                                    value={match.team2_score || ''}
                                    onChange={(e) => handleScoreChange(match.id, '2', e.target.value, isPlayoff)}
                                />
                            </div>
                            <div className="col-span-2 text-right font-medium text-gray-900">{getTeamName(match, 2, isPlayoff)}</div>
                        </div>
                        
                        <div className="flex justify-between items-center">
                            {/* Location and track info */}
                            <div className="text-sm text-gray-600 flex items-center">
                                <CalendarIcon className="w-4 h-4 mr-1" />
                                <span>Bana {match.track || '?'}</span>
                            </div>
                            
                            <button
                                className="bg-blue-600 text-white py-2 px-6 rounded-lg hover:bg-blue-700 transition-colors"
                                onClick={() => handleSaveResult(match.id, isPlayoff)}
                            >
                                Spara resultat
                            </button>
                        </div>
                        
                        {/* Reporting information */}
                        {match.reported_by_username && (
                            <div className="mt-3 p-2 bg-gray-50 rounded-lg text-sm text-gray-600 flex items-center">
                                <User className="w-4 h-4 mr-2 text-gray-500" />
                                <div>
                                    <p>Rapporterat av: <span className="font-medium">{match.reported_by_username}</span></p>
                                    {match.reported_at_formatted && <p>Tid: {match.reported_at_formatted}</p>}
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {/* Status messages (for both mobile and desktop) */}
                {statusMessages[match.id] && (
                    <div className={`mt-3 p-3 rounded-lg flex items-center ${
                        statusMessages[match.id].type === 'success' 
                            ? 'bg-green-50 text-green-700'
                            : 'bg-red-50 text-red-700'
                    }`}>
                        {statusMessages[match.id].type === 'success' ? (
                            <CheckCircle className="w-5 h-5 mr-2" />
                        ) : (
                            <AlertCircle className="w-5 h-5 mr-2" />
                        )}
                        <p>{statusMessages[match.id].message}</p>
                    </div>
                )}
            </div>
        </div>
    );

    if (loading) {
        return (
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex items-center justify-center">
                <img src={require('../images/curling.gif')} alt="Loading" className="w-16 h-16" />
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex flex-col">
            <Helmet>
                <title>Mina Matcher | SweepScore</title>
                <meta name="description" content="Hantera dina matcher på SweepScore. Rapportera resultat för dina curlingmatcher." />
            </Helmet>

            <main className="flex-grow container mx-auto px-4 py-16 pt-24">
                {/* Header */}
                <div className="bg-gradient-to-r from-gray-800 to-gray-700 rounded-xl shadow-lg p-5 text-white overflow-hidden mb-6 relative">
                    <div className="absolute top-0 right-0 w-64 h-64 bg-white opacity-5 rounded-full -mr-32 -mt-32"></div>
                    <div className="absolute bottom-0 left-0 w-40 h-40 bg-white opacity-5 rounded-full -ml-20 -mb-20"></div>
                    <div className="relative z-10">
                        <h1 className="text-2xl md:text-3xl font-bold flex items-center">
                            <Calendar className="mr-2 h-6 w-6" /> Mina Matcher
                        </h1>
                    </div>
                </div>

                {error ? (
                    <div className="bg-white rounded-xl shadow-lg p-8 text-center">
                        <AlertCircle className="w-16 h-16 text-gray-400 mx-auto mb-4" />
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Inga matcher hittades</h2>
                        <p className="text-gray-600 max-w-lg mx-auto">
                            {error}
                        </p>
                    </div>
                ) : (
                    <div className="space-y-8">
                        {/* Instructions */}
                        <div className="bg-blue-50 border-l-4 border-blue-500 text-blue-700 p-4 rounded-lg">
                            <div className="flex">
                                <Info className="h-5 w-5 mr-2" />
                                <div>
                                    <p className="font-medium">Rapportera matchresultat</p>
                                    <p className="text-sm mt-1">Här kan du rapportera och se resultat för dina matcher. Fyll i poängen och klicka på "Spara resultat" för att uppdatera matchresultatet.</p>
                                </div>
                            </div>
                        </div>

                        {/* League Matches */}
                        {leagueMatches.length > 0 && (
                            <section>
                                <h2 className="text-xl font-bold mb-4 flex items-center">
                                    <Calendar className="w-5 h-5 mr-2 text-blue-600" />
                                    Seriematcher
                                </h2>
                                
                                {leagueMatches.map((match) => (
                                    <MatchCard key={match.id} match={match} isPlayoff={false} />
                                ))}
                            </section>
                        )}

                        {/* Playoff Matches */}
                        {playoffMatches.length > 0 && (
                            <section>
                                <h2 className="text-xl font-bold mb-4 flex items-center">
                                    <Trophy className="w-5 h-5 mr-2 text-blue-600" />
                                    Slutspelsmatcher
                                </h2>
                                
                                {playoffMatches.map((match) => (
                                    <MatchCard key={match.id} match={match} isPlayoff={true} />
                                ))}
                            </section>
                        )}

                        {leagueMatches.length === 0 && playoffMatches.length === 0 && (
                            <div className="bg-white rounded-xl shadow-lg p-8 text-center">
                                <Clock className="w-16 h-16 text-gray-400 mx-auto mb-4" />
                                <h2 className="text-2xl font-semibold text-gray-800 mb-2">Inga matcher att visa</h2>
                                <p className="text-gray-600 max-w-lg mx-auto">
                                    Du har inga matcher att rapportera just nu.
                                </p>
                            </div>
                        )}
                    </div>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default MyMatches;