import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../AuthContext';
import { useNavigate } from 'react-router-dom';
import { Plus, X, Trophy, Calendar, Clock } from 'lucide-react';
import axios from 'axios';

const CreatePlayoffMatches = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [leagues, setLeagues] = useState([]);
    const [teams, setTeams] = useState({});
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    // State for match form
    const [matchForm, setMatchForm] = useState({
        league: '',
        match_type: 'R16', // Default to Round of 16
        date: '',
        time: '',
        track: '',
        matches: [{ team1: '', team2: '', track: '' }]
    });

    // Match type options
    const matchTypes = [
        { value: 'R16', label: 'Åttondelsfinal (A-slutspel)' },
        { value: '3P', label: 'Match om 3:e plats (A-slutspel)' },
        { value: '5P', label: 'Match om 5:e plats (A-slutspel)' },
        { value: 'LR16', label: 'Åttondelsfinal (B-slutspel)' },
        { value: 'L3P', label: 'Match om 3:e plats (B-slutspel)' },
        { value: 'L5P', label: 'Match om 5:e plats (B-slutspel)' }
    ];

    const getCsrfToken = () => {
        const match = document.cookie.match(/csrftoken=([^;]+)/);
        return match ? match[1] : '';
    };

    useEffect(() => {
        if (!user || !user.is_staff) {
            navigate('/');
        }

        axios.defaults.headers.post['X-CSRFToken'] = getCsrfToken();

        const fetchLeagues = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/api/leagues/', {
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('authToken')}`
                    },
                });
                const activeLeagues = response.data.filter(league => league.active);
                setLeagues(activeLeagues);
            } catch (error) {
                console.error('Error fetching leagues:', error);
                setError('Failed to fetch leagues');
            }
        };

        fetchLeagues();
    }, [user, navigate]);

    const loadTeams = async (leagueId) => {
        try {
            // We need both Group A and Group B teams for playoff matches
            const groupAResponse = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/teams/?group=A`);
            const groupBResponse = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/teams/?group=B`);

            if (!groupAResponse.ok || !groupBResponse.ok)
                throw new Error(`Failed to fetch teams for league ID ${leagueId}`);

            const groupAData = await groupAResponse.json();
            const groupBData = await groupBResponse.json();

            // Combine teams from both groups
            const allTeams = [...groupAData, ...groupBData];

            setTeams(prevTeams => ({ ...prevTeams, [leagueId]: allTeams }));
        } catch (error) {
            console.error('Error fetching teams:', error);
            setError('Failed to fetch teams');
        }
    };

    const handleLeagueSelect = (leagueId) => {
        setMatchForm(prev => ({
            ...prev,
            league: leagueId
        }));
        loadTeams(leagueId);
    };

    const handleFormChange = (field, value) => {
        setMatchForm(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleMatchChange = (index, field, value) => {
        setMatchForm(prev => {
            const newMatches = [...prev.matches];
            newMatches[index] = {
                ...newMatches[index],
                [field]: value
            };
            return {
                ...prev,
                matches: newMatches
            };
        });
    };

    const addMatch = () => {
        setMatchForm(prev => ({
            ...prev,
            matches: [...prev.matches, { team1: '', team2: '', track: '' }]
        }));
    };

    const removeMatch = (index) => {
        setMatchForm(prev => ({
            ...prev,
            matches: prev.matches.filter((_, i) => i !== index)
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);
        setSuccessMessage('');

        // Validation
        if (!matchForm.league || !matchForm.match_type || !matchForm.date || !matchForm.time) {
            setError('Vänligen fyll i alla gemensamma fält');
            setIsSubmitting(false);
            return;
        }

        // Validate all matches
        const invalidMatches = matchForm.matches.some(match =>
            !match.track || (match.team1 !== 'tbd' && match.team2 !== 'tbd' && match.team1 === match.team2)
        );

        if (invalidMatches) {
            setError('Kontrollera alla matcher - bana måste anges, och om lag väljs kan de inte vara samma lag');
            setIsSubmitting(false);
            return;
        }

        try {
            // Create all matches in sequence
            let createdCount = 0;

            for (const match of matchForm.matches) {
                const matchData = {
                    team1: match.team1 === 'tbd' ? null : match.team1,
                    team2: match.team2 === 'tbd' ? null : match.team2,
                    match_type: matchForm.match_type,
                    track: match.track,
                    match_date: `${matchForm.date}T${matchForm.time}:00`, // Combine date and time with seconds
                    league: matchForm.league
                };

                // Use the correct URL path matching the backend registration
                const response = await axios.post(
                    process.env.REACT_APP_API_URL + '/api/create-playoff-match/',
                    matchData,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRFToken': getCsrfToken(),
                            'Authorization': `Token ${localStorage.getItem('authToken')}`
                        },
                        withCredentials: true
                    }
                );

                console.log("Match created:", response.data);
                createdCount++;
            }

            // Reset form but keep league and match type selections
            setMatchForm({
                league: matchForm.league,
                match_type: matchForm.match_type,
                date: '',
                time: '',
                track: '',
                matches: [{ team1: '', team2: '', track: '' }]
            });

            setSuccessMessage(`${createdCount} slutspelsmatcher har skapats`);
            setError(null);
        } catch (error) {
            console.error('Error creating playoff matches:', error.response?.data || error);
            setError(error.response?.data?.message || error.message || 'Kunde inte skapa slutspelsmatcher');
        } finally {
            setIsSubmitting(false);
        }
    };

    const renderTeamOptions = (teams, leagueId) => {
        if (!teams || !teams[leagueId]) return null;

        return (
            <>
                {/* Add TBD option */}
                <option value="tbd">TBD (Bestäms senare)</option>
                {teams[leagueId].map((team) => (
                    <option key={team.id} value={team.id}>
                        {team.group} - {team.groupid || '-'} - {team.team.name}
                    </option>
                ))}
            </>
        );
    };

    return (
        <div className="min-h-screen flex flex-col">
            <h1 className="text-2xl font-bold mb-6 flex items-center">
                <Trophy className="w-6 h-6 mr-2 text-blue-600" />
                Skapa slutspelsmatcher
            </h1>

            <div className="mb-6 bg-white p-6 rounded-xl shadow-md">
                <h2 className="text-lg font-medium mb-4">Välj liga och slutspelstyp</h2>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Liga</label>
                        <select
                            value={matchForm.league}
                            onChange={(e) => handleLeagueSelect(e.target.value)}
                            className="w-full p-2 border rounded-md"
                            required
                        >
                            <option value="">Välj liga</option>
                            {leagues.map(league => (
                                <option key={league.id} value={league.id}>
                                    {league.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Matchtyp</label>
                        <select
                            value={matchForm.match_type}
                            onChange={(e) => handleFormChange('match_type', e.target.value)}
                            className="w-full p-2 border rounded-md"
                            required
                        >
                            {matchTypes.map(type => (
                                <option key={type.value} value={type.value}>
                                    {type.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            {matchForm.league && (
                <div className="bg-white p-6 rounded-xl shadow-md mb-6">
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div className="flex items-center">
                                <Calendar className="w-5 h-5 mr-2 text-gray-500" />
                                <div className="w-full">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Datum</label>
                                    <input
                                        type="date"
                                        value={matchForm.date}
                                        onChange={(e) => handleFormChange('date', e.target.value)}
                                        className="w-full p-2 border rounded-md"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="flex items-center">
                                <Clock className="w-5 h-5 mr-2 text-gray-500" />
                                <div className="w-full">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Tid</label>
                                    <input
                                        type="time"
                                        value={matchForm.time}
                                        onChange={(e) => handleFormChange('time', e.target.value)}
                                        className="w-full p-2 border rounded-md"
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="space-y-4 mt-4">
                            <h3 className="text-lg font-medium mb-2">Matcher</h3>

                            {matchForm.matches.map((match, index) => (
                                <div key={index} className="grid grid-cols-1 md:grid-cols-7 gap-2 items-center border p-3 rounded-md">
                                    <div className="md:col-span-3">
                                        <label className="block text-xs text-gray-500 mb-1">Lag 1</label>
                                        <select
                                            value={match.team1}
                                            onChange={(e) => handleMatchChange(index, 'team1', e.target.value)}
                                            className="w-full p-2 border rounded-md"
                                            required
                                        >
                                            <option value="">Välj lag 1</option>
                                            {renderTeamOptions(teams, matchForm.league)}
                                        </select>
                                    </div>

                                    <div className="md:col-span-3">
                                        <label className="block text-xs text-gray-500 mb-1">Lag 2</label>
                                        <select
                                            value={match.team2}
                                            onChange={(e) => handleMatchChange(index, 'team2', e.target.value)}
                                            className="w-full p-2 border rounded-md"
                                            required
                                        >
                                            <option value="">Välj lag 2</option>
                                            {renderTeamOptions(teams, matchForm.league)}
                                        </select>
                                    </div>

                                    <div className="md:col-span-1">
                                        <label className="block text-xs text-gray-500 mb-1">Bana</label>
                                        <div className="flex items-center">
                                            <input
                                                type="number"
                                                value={match.track}
                                                onChange={(e) => handleMatchChange(index, 'track', e.target.value)}
                                                className="w-full p-2 border rounded-md"
                                                min="1"
                                                required
                                            />
                                            {index > 0 && (
                                                <button
                                                    type="button"
                                                    onClick={() => removeMatch(index)}
                                                    className="ml-2 p-2 text-red-600 hover:text-red-800"
                                                >
                                                    <X size={20} />
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div className="flex justify-between">
                                <button
                                    type="button"
                                    onClick={addMatch}
                                    className="flex items-center text-blue-600 hover:text-blue-800"
                                >
                                    <Plus size={20} className="mr-1" />
                                    <span>Lägg till match</span>
                                </button>

                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="bg-gray-600 text-white px-6 py-2 rounded-md hover:bg-gray-800 disabled:bg-gray-400"
                                >
                                    {isSubmitting ? 'Skapar...' : 'Skapa slutspelsmatcher'}
                                </button>
                            </div>
                        </div>
                    </form>

                    {successMessage && (
                        <div className="mt-4 p-3 bg-green-100 text-green-700 rounded-lg">
                            {successMessage}
                        </div>
                    )}
                    {error && (
                        <div className="mt-4 p-3 bg-red-100 text-red-700 rounded-lg">
                            {error}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default CreatePlayoffMatches;