import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Calendar, User, Trophy, Users, Clock, MapPin, Archive, MessageCircle } from 'lucide-react';
import Footer from '../components/Footer';
import LatestMatchesSlider from '../components/LatestMatchesSlider';
import '../css/match-slider.css';
import { Helmet } from 'react-helmet-async';
import SEO from '../components/SEO';


const Home = () => {

  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1023);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1023);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        setLoading(true);
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/leagues/');
        if (!response.ok) {
          throw new Error('Failed to fetch leagues');
        }
        const data = await response.json();

        // Loop through each league to fetch teams for both groups and count them
        const updatedLeagues = await Promise.all(
          data.map(async (league) => {
            const groupATeams = await fetchTeamsInGroup(league.id, 'A');
            const groupBTeams = await fetchTeamsInGroup(league.id, 'B');
            const totalTeams = (groupATeams?.length || 0) + (groupBTeams?.length || 0);
            return { ...league, totalTeams };
          })
        );

        setLeagues(updatedLeagues);

        // Filter archived leagues
        const archivedLeagues = updatedLeagues.filter(league => !league.active);

        // Fetch results for archived leagues
        await fetchLeagueResults(archivedLeagues);

      } catch (err) {
        setError(err.message);
        console.error('Error fetching leagues:', err);
      } finally {
        setLoading(false);
      }
    };

    const fetchTeamsInGroup = async (leagueId, group) => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/teams/?group=${group}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch teams for group ${group} in league ${leagueId}`);
        }
        const data = await response.json();
        return data;
      } catch (error) {
        //console.error(`Error fetching teams in group ${group} for league ${leagueId}:`, error);
        return [];
      }
    };

    const fetchLeagueResults = async (leagues) => {
      try {
        // Om leagues är tomma eller undefined, avbryt funktionen
        if (!leagues || leagues.length === 0) {
          console.log('No archived leagues to fetch results for');
          return;
        }

        // Skapa en URL med alla arkiverade liga-ID:n
        const leagueIds = leagues.map(league => league.id).join(',');

        // Hämta resultat för Playoff A, specifikt för arkiverade ligor
        const responseA = await fetch(
          `${process.env.REACT_APP_API_URL}/api/league-results/?playoff_group=A&league_ids=${leagueIds}`
        );

        if (!responseA.ok) {
          throw new Error('Failed to fetch league results');
        }

        const playoffAResults = await responseA.json();

        // Skapa ett objekt för enkel åtkomst
        const resultsMap = playoffAResults.reduce((acc, leagueResult) => {
          // Hitta vinnaren (första plats)
          const winner = leagueResult.results.find(result => result.finalplacement === 1);

          acc[leagueResult.league_id] = {
            playoffA: leagueResult.results,
            winner: winner ? winner.team.name : null
          };

          return acc;
        }, {});

        // Uppdatera leagues med vinnare
        setLeagues(prevLeagues =>
          prevLeagues.map(league => {
            const leagueResult = resultsMap[league.id];
            return leagueResult
              ? { ...league, winner: leagueResult.winner }
              : league;
          })
        );

      } catch (error) {
        console.error('Fel vid hämtning av resultat:', error);
        // Här kan du lägga till ytterligare felhantering, t.ex. att sätta ett error state
        setError('Could not fetch league results');
      }
    };

    fetchLeagues();
  }, []);

  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    const mainContent = document.querySelector('main');
    if (mainContent) {
      const isHomePage = pathname === '/';
      mainContent.style.marginTop = isHomePage ? '144px' : '64px'; // 80px + 64px = 144px
    }
  }, [pathname]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <img
          src={require('../images/curling.gif')}
          alt="Loading..."
          className="w-24 h-24"
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-red-600">Error: {error}</div>
      </div>
    );
  }
  const archivedLeagues = leagues.filter(league => !league.active);
  const activeLeagues = leagues.filter(league => league.active);

  return (
    <>
      <SEO
        title="Digital resultatportal för curlingklubbar"
        description="SweepScore erbjuder en komplett digital lösning för curlingklubbar i Sverige. Följ matcher, resultat och tabeller för curlingtävlingar i realtid."
        keywords="curling, resultatportal, curlingtävlingar, curlingmatcher, svenska curlingförbundet, resultat curling"
        canonicalUrl="https://sweepscore.se"
      />
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <Helmet>
          <title>SweepScore | Digital resultatportal för curlingklubbar</title>
          <meta name="description" content="SweepScore är den digitala resultatportalen för curlingklubbar i Sverige. Följ matcher, resultat och tabeller för curlingtävlingar i realtid." />
          <meta name="keywords" content="curling, resultatportal, curlingtävlingar, svenska curlingligor, curlingmatcher, curlingtabeller" />
          <link rel="canonical" href="https://sweepscore.se" />
        </Helmet>

        {/* Add the match results slider at the top - only for desktop */}
        {!isMobile && <LatestMatchesSlider />}

        <main className={`max-w-7xl mx-auto px-4 py-12 ${isMobile ? 'pt-56' : 'pt-8'} space-y-12 flex-grow`}>
          {/* Hero section */}
          <section className="text-center mb-16">
            <h1 className="text-3xl md:text-5xl font-bold mb-6">
              <span className="text-gray-900">Digital resultatportal för</span>
              <span className="block text-blue-700">curlingklubbar</span>
            </h1>
            <p className="text-xl text-gray-700 max-w-3xl mx-auto mb-8">
              Välkommen till SweepScore - den kompletta digitala lösningen för att följa,
              hantera och uppleva curlingtävlingar i Sverige.
            </p>

            <div className="hidden md:flex md:flex-row gap-4 justify-center">
              <Link
                to="/aktiva-tavlingar"
                className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                <Trophy className="mr-2 w-5 h-5" />
                Se aktiva tävlingar
              </Link>
              <Link
                to="/arkiverade-tavlingar"
                className="inline-flex items-center px-6 py-3 bg-gray-700 text-white rounded-lg hover:bg-gray-800 transition-colors"
              >
                <Archive className="mr-2 w-5 h-5" />
                Utforska arkivet
              </Link>
              <Link
                to="/kontakta-oss"
                className="inline-flex items-center px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
              >
                <MessageCircle className="mr-2 w-5 h-5" />
                Kontakta oss
              </Link>
            </div>
          </section>

          {/* Aktiva tävlingar sektion */}
          <section id="active-leagues">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold">Aktuella tävlingar</h2>
              <Link to="/aktiva-tavlingar" className="text-blue-600 hover:underline flex items-center">
                Se alla
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </Link>
            </div>

            {activeLeagues.length === 0 ? (
              <div className="text-center text-gray-600 bg-white rounded-xl shadow-md p-8">
                <Trophy className="w-16 h-16 text-gray-400 mx-auto mb-4" />
                <h3 className="text-lg font-medium mb-2">Inga aktiva tävlingar just nu</h3>
                <p>Det finns inga pågående tävlingar. Kom tillbaka senare eller utforska arkivet.</p>
              </div>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {activeLeagues.slice(0, 3).map((league) => (
                  <Link to={`/league/${league.id}`} key={league.id} className="h-full">
                    <div className="w-full h-full bg-white rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:shadow-xl hover:translate-y-[-5px] flex flex-col">
                      <div className="bg-gradient-to-r from-gray-800 to-gray-700 px-6 py-4 relative overflow-hidden">
                        <div className="relative z-10">
                          <h2 className="text-xl font-bold text-white">{league.name}</h2>
                          <span className="inline-block px-3 py-1 text-xs bg-green-500 text-white rounded-full mt-2">
                            Aktiv
                          </span>
                        </div>
                        <div className="absolute top-0 right-0 w-32 h-32 bg-white opacity-5 rounded-full -mr-16 -mt-16"></div>
                        <div className="absolute bottom-0 left-0 w-24 h-24 bg-white opacity-5 rounded-full -ml-12 -mb-12"></div>
                      </div>

                      <div className="p-6 space-y-4 flex-grow flex flex-col">
                        <div className="flex items-start space-x-3">
                          <User className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-500">Arrangör</p>
                            <p className="text-gray-900 font-medium">{league.organizer || 'Ej specificerad'}</p>
                          </div>
                        </div>

                        <div className="flex items-start space-x-3">
                          <Calendar className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-500">Datum</p>
                            <p className="text-gray-900 font-medium">
                              {new Date(league.start_date).toLocaleDateString('sv-SE')} - {new Date(league.end_date).toLocaleDateString('sv-SE')}
                            </p>
                          </div>
                        </div>

                        <div className="flex items-start space-x-3">
                          <Users className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-500">Antal lag</p>
                            <p className="text-gray-900 font-medium">{league.totalTeams || '0'}</p>
                          </div>
                        </div>

                        <div className="mt-auto pt-4">
                          <div className="bg-blue-50 border border-blue-100 rounded-lg p-3 text-center">
                            <span className="text-blue-800 font-medium">Se detaljer</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            )}

            {activeLeagues.length > 3 && (
              <div className="text-center mt-8">
                <Link
                  to="/aktiva-tavlingar"
                  className="inline-flex items-center px-6 py-3 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition-colors"
                >
                  Se alla {activeLeagues.length} aktiva tävlingar
                </Link>
              </div>
            )}
          </section>

          {/* Avslutade tävlingar sektion */}
          {archivedLeagues.length > 0 && (
            <section id="archived-leagues">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold">Tidigare tävlingar</h2>
                <Link to="/arkiverade-tavlingar" className="text-blue-600 hover:underline flex items-center">
                  Se alla
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </Link>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {archivedLeagues.slice(0, 3).map((league) => (
                  <Link to={`/league/${league.id}`} key={league.id} className="h-full">
                    <div className="w-full h-full bg-white rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:shadow-xl hover:translate-y-[-5px] flex flex-col">
                      <div className="bg-gradient-to-r from-gray-700 to-gray-600 px-6 py-4 relative overflow-hidden">
                        <div className="relative z-10">
                          <div className="flex justify-between items-center">
                            <h2 className="text-xl font-bold text-white">{league.name}</h2>
                            <span className="text-xs text-white bg-gray-500 px-2 py-1 rounded-full">
                              {new Date(league.end_date).getFullYear()}
                            </span>
                          </div>
                          <span className="inline-block px-3 py-1 text-xs bg-red-500 text-white rounded-full mt-2">
                            Arkiverad
                          </span>
                        </div>
                        <div className="absolute top-0 right-0 w-32 h-32 bg-white opacity-5 rounded-full -mr-16 -mt-16"></div>
                        <div className="absolute bottom-0 left-0 w-24 h-24 bg-white opacity-5 rounded-full -ml-12 -mb-12"></div>
                      </div>

                      <div className="p-6 space-y-4 flex-grow flex flex-col">
                        <div className="flex items-start space-x-3">
                          <User className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-500">Arrangör</p>
                            <p className="text-gray-900 font-medium">{league.organizer || 'Ej specificerad'}</p>
                          </div>
                        </div>

                        <div className="flex items-start space-x-3">
                          <Trophy className="w-5 h-5 text-yellow-500 mt-0.5 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-500">Vinnare</p>
                            <p className="text-gray-900 font-medium">{league.winner || 'Ej registrerad'}</p>
                          </div>
                        </div>

                        <div className="mt-auto pt-4">
                          <div className="bg-blue-50 border border-blue-100 rounded-lg p-3 text-center">
                            <span className="text-blue-800 font-medium">Se detaljer</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>

              {archivedLeagues.length > 3 && (
                <div className="text-center mt-8">
                  <Link
                    to="/arkiverade-tavlingar"
                    className="inline-flex items-center px-6 py-3 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition-colors"
                  >
                    Se alla {archivedLeagues.length} arkiverade tävlingar
                  </Link>
                </div>
              )}
            </section>
          )}

          {/* Features section */}
          <section className="my-16">
            <h2 className="text-2xl font-bold mb-8 text-center">Varför välja SweepScore?</h2>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow">
                <Clock className="w-8 h-8 text-blue-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">Realtidsuppdateringar</h3>
                <p className="text-gray-700">
                  Alla resultat uppdateras i realtid för att ge dig den senaste informationen direkt från isbanorna.
                </p>
              </div>

              <div className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow">
                <MapPin className="w-8 h-8 text-green-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">Nationell täckning</h3>
                <p className="text-gray-700">
                  Vi samlar tävlingar från curlingklubbar över hela Sverige på en och samma plattform.
                </p>
              </div>

              <div className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow">
                <Trophy className="w-8 h-8 text-yellow-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">Komplett turneringshantering</h3>
                <p className="text-gray-700">
                  Från gruppspel till slutspel - SweepScore hanterar hela vägen och håller dig uppdaterad.
                </p>
              </div>
            </div>
          </section>

          {/* CTA Section */}
          <section className="bg-gradient-to-r from-blue-600 to-blue-800 rounded-xl shadow-xl p-8 text-white text-center">
            <h2 className="text-3xl font-bold mb-4">Har du frågor eller behöver hjälp?</h2>
            <p className="text-xl mb-8 max-w-2xl mx-auto">
              Vårt team finns här för att hjälpa dig med alla frågor om curling och SweepScore-plattformen.
            </p>
            <Link
              to="/kontakta-oss"
              className="inline-flex items-center px-8 py-4 bg-white text-blue-700 rounded-lg hover:bg-gray-100 transition-colors font-bold text-lg"
            >
              <MessageCircle className="mr-2 w-6 h-6" />
              Kontakta oss
            </Link>
          </section>
        </main>

        <Footer />
      </div>
    </>
  );
};

export default Home;