import React, { useState } from 'react';
import { Trophy, Calendar, User, Clock, Info, Users, Award } from 'lucide-react';

const CreateLeague = () => {
    const [newLeague, setNewLeague] = useState({
        name: '',
        start_date: '',
        end_date: '',
        active: true,
        organizer: '',
        leagueinfo: '',
        teamscount: 0,
        participants: 0,
        gamesplayed: 0,
        mvp: ''
    });
    const [createPlayoffs, setCreatePlayoffs] = useState(false);
    const [hasLoserBracket, setHasLoserBracket] = useState(false);
    const [playoffDates, setPlayoffDates] = useState({
        // A-Playoff (Winner bracket) dates
        round16: { date: '', time: '' },            // Round of 16
        quarterfinal: { date: '', time: '' },       // Quarterfinals
        semifinal: { date: '', time: '' },          // Semifinals
        final: { date: '', time: '' },              // Finals
        third_place: { date: '', time: '' },        // 3rd place match
        fifth_place: { date: '', time: '' },        // 5th place match
        
        // B-Playoff (Loser bracket) dates
        loser_round16: { date: '', time: '' },      // Loser bracket Round of 16
        loser_round1: { date: '', time: '' },       // Loser bracket Round 1
        loser_round2: { date: '', time: '' },       // Loser bracket Round 2
        loser_final: { date: '', time: '' },        // Loser bracket Final
        loser_third_place: { date: '', time: '' },  // Loser bracket 3rd place match
        loser_fifth_place: { date: '', time: '' }   // Loser bracket 5th place match
    });
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');

    const getCsrfToken = () => {
        const match = document.cookie.match(/csrftoken=([^;]+)/);
        return match ? match[1] : '';
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewLeague((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : 
                    type === 'number' ? (value === '' ? 0 : Number(value)) : 
                    value
        }));
    };

    const handlePlayoffDateChange = (stage, type, value) => {
        setPlayoffDates(prev => ({
            ...prev,
            [stage]: {
                ...prev[stage],
                [type]: value
            }
        }));
    };

    const formatPlayoffDates = () => {
        if (!createPlayoffs) {
            // If playoffs are disabled, explicitly return null for all datetime fields
            return {
                round16_datetime: null,
                quarterfinal_datetime: null,
                semifinal_datetime: null,
                final_datetime: null,
                third_place_datetime: null,
                fifth_place_datetime: null,
                loser_round16_datetime: null,
                loser_round1_datetime: null,
                loser_round2_datetime: null,
                loser_final_datetime: null,
                loser_third_place_datetime: null,
                loser_fifth_place_datetime: null
            };
        }
    
        const formatDateTime = (stage) => {
            if (!playoffDates[stage].date || !playoffDates[stage].time) return null;
            let fullTime = playoffDates[stage].time;
            if (fullTime.split(':').length === 2) {
                fullTime = `${fullTime}:00`;
            }
            return `${playoffDates[stage].date}T${fullTime}`;
        };
    
        const dates = {
            // A-Playoff datetime fields
            round16_datetime: formatDateTime('round16'),
            quarterfinal_datetime: formatDateTime('quarterfinal'),
            semifinal_datetime: formatDateTime('semifinal'),
            final_datetime: formatDateTime('final'),
            third_place_datetime: formatDateTime('third_place'),
            fifth_place_datetime: formatDateTime('fifth_place')
        };
    
        if (hasLoserBracket) {
            // B-Playoff datetime fields
            dates.loser_round16_datetime = formatDateTime('loser_round16');
            dates.loser_round1_datetime = formatDateTime('loser_round1');
            dates.loser_round2_datetime = formatDateTime('loser_round2');
            dates.loser_final_datetime = formatDateTime('loser_final');
            dates.loser_third_place_datetime = formatDateTime('loser_third_place');
            dates.loser_fifth_place_datetime = formatDateTime('loser_fifth_place');
        } else {
            // If loser bracket is disabled, explicitly set these to null
            dates.loser_round16_datetime = null;
            dates.loser_round1_datetime = null;
            dates.loser_round2_datetime = null;
            dates.loser_final_datetime = null;
            dates.loser_third_place_datetime = null;
            dates.loser_fifth_place_datetime = null;
        }
    
        return dates;
    };
    
    const handleCreateLeague = async (e) => {
        e.preventDefault();

        const payload = {
            ...newLeague,
            create_playoffs: createPlayoffs,
            has_loser_bracket: hasLoserBracket,
            teamscount: parseInt(newLeague.teamscount) || 0,
            participants: parseInt(newLeague.participants) || 0,
            gamesplayed: parseInt(newLeague.gamesplayed) || 0,
            mvp: newLeague.mvp || '',
            ...formatPlayoffDates()
        };

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/leagues/create/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCsrfToken(),
                    'Authorization': `Token ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to create league');
            }

            setSuccessMessage(`Ny serie har skapats: ${newLeague.name}`);

            // Reset form
            setNewLeague({ 
                name: '', 
                start_date: '', 
                end_date: '', 
                organizer: '', 
                leagueinfo: '', 
                active: true,
                teamscount: 0,
                participants: 0,
                gamesplayed: 0,
                mvp: ''
            });
            setCreatePlayoffs(false);
            setHasLoserBracket(false);
            setPlayoffDates({
                round16: { date: '', time: '' },
                quarterfinal: { date: '', time: '' },
                semifinal: { date: '', time: '' },
                final: { date: '', time: '' },
                third_place: { date: '', time: '' },
                fifth_place: { date: '', time: '' },
                loser_round16: { date: '', time: '' },
                loser_round1: { date: '', time: '' },
                loser_round2: { date: '', time: '' },
                loser_final: { date: '', time: '' },
                loser_third_place: { date: '', time: '' },
                loser_fifth_place: { date: '', time: '' }
            });
            setError(null);
        } catch (error) {
            console.error('Misslyckades att skapa liga:', error);
            setError(error.message || 'Kunde inte skapa liga');
        }
    };

    // Helper component for date-time input fields
    const DateTimeInput = ({ stage, label, isRequired = false }) => (
        <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
                {label} {isRequired && <span className="text-red-500">*</span>}
            </label>
            <div className="grid grid-cols-2 gap-2">
                <div className="flex items-center">
                    <Calendar className="w-4 h-4 text-gray-500 mr-2" />
                    <input
                        type="date"
                        value={playoffDates[stage].date}
                        onChange={(e) => handlePlayoffDateChange(stage, 'date', e.target.value)}
                        required={isRequired}
                        className="w-full p-2 border rounded-md"
                    />
                </div>
                <div className="flex items-center">
                    <Clock className="w-4 h-4 text-gray-500 mr-2" />
                    <input
                        type="time"
                        value={playoffDates[stage].time}
                        onChange={(e) => handlePlayoffDateChange(stage, 'time', e.target.value)}
                        required={isRequired}
                        className="w-full p-2 border rounded-md"
                    />
                </div>
            </div>
        </div>
    );

    return (
        <div className="max-w-2xl mx-auto p-4">
            <form onSubmit={handleCreateLeague} className="bg-white p-6 rounded-xl shadow-lg">
                <h3 className="text-xl font-semibold mb-6 flex items-center">
                    <Trophy className="w-5 h-5 mr-2 text-blue-600" />
                    Skapa ny tävling
                </h3>

                {/* Basic League Information */}
                <div className="space-y-4 mb-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                            <Trophy className="w-4 h-4 mr-1 text-gray-600" />
                            Tävlingens namn <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            name="name"
                            placeholder="Tävlingens namn"
                            value={newLeague.name}
                            onChange={handleInputChange}
                            required
                            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                            <User className="w-4 h-4 mr-1 text-gray-600" />
                            Organisatör <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            name="organizer"
                            placeholder="Organisatör"
                            value={newLeague.organizer}
                            onChange={handleInputChange}
                            required
                            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                            <Info className="w-4 h-4 mr-1 text-gray-600" />
                            Ligainformation
                        </label>
                        <textarea
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-24"
                            name="leagueinfo"
                            value={newLeague.leagueinfo}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm mb-1 flex items-center">
                                <Calendar className="w-4 h-4 mr-1 text-gray-600" />
                                Startdatum <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="date"
                                name="start_date"
                                value={newLeague.start_date}
                                onChange={handleInputChange}
                                required
                                className="w-full p-2 border rounded-md"
                            />
                        </div>
                        <div>
                            <label className="block text-sm mb-1 flex items-center">
                                <Calendar className="w-4 h-4 mr-1 text-gray-600" />
                                Slutdatum <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="date"
                                name="end_date"
                                value={newLeague.end_date}
                                onChange={handleInputChange}
                                required
                                className="w-full p-2 border rounded-md"
                            />
                        </div>
                    </div>

                    <div className="flex items-center space-x-2">
                        <input
                            type="checkbox"
                            id="active"
                            name="active"
                            checked={newLeague.active}
                            onChange={handleInputChange}
                            className="h-4 w-4 text-blue-600 rounded border-gray-300"
                        />
                        <label htmlFor="active" className="text-sm font-medium text-gray-700">
                            Aktiv tävling
                        </label>
                    </div>
                </div>

                {/* Statistics fields */}
                <div className="mb-6 p-4 bg-gray-50 rounded-lg">
                    <h4 className="font-medium text-gray-800 mb-3 flex items-center">
                        <Award className="w-4 h-4 mr-2 text-blue-600" />
                        Statistik (frivilligt)
                    </h4>
                    
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                                <Users className="w-4 h-4 mr-1 text-gray-600" />
                                Antal lag
                            </label>
                            <input
                                type="number"
                                name="teamscount"
                                value={newLeague.teamscount}
                                onChange={handleInputChange}
                                min="0"
                                className="w-full p-2 border rounded-md"
                            />
                        </div>
                        
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                                <Users className="w-4 h-4 mr-1 text-gray-600" />
                                Antal deltagare
                            </label>
                            <input
                                type="number"
                                name="participants"
                                value={newLeague.participants}
                                onChange={handleInputChange}
                                min="0"
                                className="w-full p-2 border rounded-md"
                            />
                        </div>
                        
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                                <Calendar className="w-4 h-4 mr-1 text-gray-600" />
                                Spelade matcher
                            </label>
                            <input
                                type="number"
                                name="gamesplayed"
                                value={newLeague.gamesplayed}
                                onChange={handleInputChange}
                                min="0"
                                className="w-full p-2 border rounded-md"
                            />
                        </div>
                        
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                                <Trophy className="w-4 h-4 mr-1 text-gray-600" />
                                MVP
                            </label>
                            <input
                                type="text"
                                name="mvp"
                                value={newLeague.mvp}
                                onChange={handleInputChange}
                                className="w-full p-2 border rounded-md"
                            />
                        </div>
                    </div>
                </div>

                {/* Playoffs Section */}
                <div className="mb-6">
                    <label className="flex items-center space-x-2 w-full cursor-pointer">
                        <span className="font-medium flex items-center">
                            <Trophy className="w-4 h-4 mr-1 text-blue-600" />
                            Skapa A Slutspel
                        </span>
                        <div className="relative inline-block w-14 h-5">
                            <input
                                type="checkbox"
                                name="playoff"
                                checked={createPlayoffs}
                                onChange={(e) => setCreatePlayoffs(e.target.checked)}
                                id="playoff-switch"
                                className="peer appearance-none w-11 h-5 bg-slate-100 rounded-full checked:bg-slate-800 cursor-pointer transition-colors duration-300"
                            />
                            <label
                                htmlFor="playoff-switch"
                                className="absolute top-0 left-0 w-5 h-5 bg-white rounded-full border border-slate-300 shadow-sm transition-transform duration-300 peer-checked:translate-x-6 peer-checked:border-slate-800 cursor-pointer"
                            ></label>
                        </div>
                    </label>

                    {createPlayoffs && (
                        <>
                            <label className="flex items-center space-x-2 w-full cursor-pointer mt-3">
                                <span className="font-medium flex items-center">
                                    <Trophy className="w-4 h-4 mr-1 text-blue-600" />
                                    Skapa B Slutspel
                                </span>
                                <div className="relative inline-block w-16 h-5">
                                    <input
                                        type="checkbox"
                                        name="loserbracket"
                                        checked={hasLoserBracket}
                                        onChange={(e) => setHasLoserBracket(e.target.checked)}
                                        id="loser-bracket-switch"
                                        className="peer appearance-none w-11 h-5 bg-slate-100 rounded-full checked:bg-slate-800 cursor-pointer transition-colors duration-300"
                                    />
                                    <label
                                        htmlFor="loser-bracket-switch"
                                        className="absolute top-0 left-0 w-5 h-5 bg-white rounded-full border border-slate-300 shadow-sm transition-transform duration-300 peer-checked:translate-x-6 peer-checked:border-slate-800 cursor-pointer"
                                    ></label>
                                </div>
                            </label>

                            <div className="space-y-3 py-4">
                                <h4 className="font-medium mb-4">A Slutspel</h4>
                                
                                {/* Round of 16 */}
                                <DateTimeInput stage="round16" label="Åttondelsfinal" />
                                
                                {/* Quarterfinals */}
                                <DateTimeInput stage="quarterfinal" label="Kvartsfinal" isRequired={createPlayoffs} />

                                {/* Semifinals */}
                                <DateTimeInput stage="semifinal" label="Semifinal" isRequired={createPlayoffs} />

                                {/* Final */}
                                <DateTimeInput stage="final" label="Final" isRequired={createPlayoffs} />
                                
                                {/* 3rd Place Match */}
                                <DateTimeInput stage="third_place" label="Match om 3:e plats" />
                                
                                {/* 5th Place Match */}
                                <DateTimeInput stage="fifth_place" label="Match om 5:e plats" />

                                {/* Loser Bracket Section */}
                                {hasLoserBracket && (
                                    <div className="mt-6 pt-6 border-t border-gray-300">
                                        <h4 className="font-medium mb-4">B Slutspel</h4>
                                        
                                        {/* Loser Round of 16 */}
                                        <DateTimeInput stage="loser_round16" label="B-slutspel Åttondelsfinal" />

                                        {/* Loser Round 1 */}
                                        <DateTimeInput stage="loser_round1" label="B-slutspel Kvartsfinal" isRequired={hasLoserBracket} />

                                        {/* Loser Round 2 */}
                                        <DateTimeInput stage="loser_round2" label="B-slutspel Semifinal" isRequired={hasLoserBracket} />

                                        {/* Loser Final */}
                                        <DateTimeInput stage="loser_final" label="B-slutspel Final" isRequired={hasLoserBracket} />
                                        
                                        {/* Loser 3rd Place Match */}
                                        <DateTimeInput stage="loser_third_place" label="B-slutspel Match om 3:e plats" />
                                        
                                        {/* Loser 5th Place Match */}
                                        <DateTimeInput stage="loser_fifth_place" label="B-slutspel Match om 5:e plats" />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>

                {error && (
                    <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-lg text-red-600">
                        {error}
                    </div>
                )}
                
                {successMessage && (
                    <div className="mb-4 p-3 bg-green-50 border border-green-200 rounded-lg text-green-600">
                        {successMessage}
                    </div>
                )}

                <button
                    type="submit"
                    className="w-full bg-gray-600 text-white py-2 px-4 rounded-lg hover:bg-gray-800 transition-colors"
                >
                    Skapa tävling
                </button>
            </form>
        </div>
    );
};

export default CreateLeague;