import React, { useState } from 'react';
import { Mail, Send, AlertCircle, CheckCircle } from 'lucide-react';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import SEO from '../components/SEO';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Namn krävs';
    if (!formData.email.trim()) {
      newErrors.email = 'E-post krävs';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'E-postadressen är ogiltig';
    }
    if (!formData.subject.trim()) newErrors.subject = 'Ämne krävs';
    if (!formData.message.trim()) newErrors.message = 'Meddelande krävs';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Clear error for this field if user is typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: null
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setIsSubmitting(true);
    setSubmitError(null);

    try {
      // Call the backend API instead of using the mock
      await axios.post(process.env.REACT_APP_API_URL + '/api/contact/', formData);

      // Success
      setSubmitSuccess(true);
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });

      // Reset success message after 5 seconds
      setTimeout(() => {
        setSubmitSuccess(false);
      }, 5000);

    } catch (error) {
      setSubmitError(error.response?.data?.error || 'Det gick inte att skicka meddelandet. Försök igen senare.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <SEO
        title="Kontakta SweepScore"
        description="Har du frågor eller behöver hjälp med SweepScore? Kontakta oss för support gällande vår digitala resultatportal för curlingklubbar i Sverige."
        keywords="kontakta curlingportal, SweepScore support, hjälp curlingresultat, curlingklubb digital lösning"
        canonicalUrl="https://sweepscore.se/kontakta-oss"
      />
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex flex-col">
        <Helmet>
          <title>Kontakta oss | SweepScore</title>
          <meta name="description" content="Kontakta SweepScore för frågor om curlingtävlingar, systemet eller teknisk support. Vi hjälper dig med alla frågor relaterade till curling och digital resultatrapportering." />
          <meta name="keywords" content="curling kontakt, SweepScore support, curling hjälp, curlingsystem, kontaktformulär curling, curlingtävlingar kontakt" />
          <link rel="canonical" href="https://sweepscore.se/kontakta-oss" />
        </Helmet>

        <main className="max-w-7xl mx-auto px-4 py-16 pt-28 space-y-12 flex-grow">
          <section>
            <div className="max-w-3xl mx-auto mb-12 text-center">
              <h1 className="text-4xl font-bold mb-6 text-gray-900">Kontakta oss</h1>
              <p className="text-lg text-gray-700">
                Har du frågor eller funderingar?
              </p>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-5 gap-8 max-w-6xl mx-auto">
              <div className="lg:col-span-2 bg-white rounded-xl shadow-lg p-8">
                <h2 className="text-2xl font-semibold mb-6">Kontaktinformation</h2>

                <div className="space-y-6">
                  <div className="flex items-start space-x-4">
                    <div className="bg-blue-100 p-3 rounded-full">
                      <Mail className="w-6 h-6 text-blue-600" />
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900">E-post</h3>
                      <a href="mailto:support@sweepscore.se" className="text-blue-600 hover:underline">
                        support@sweepscore.se
                      </a>
                    </div>
                  </div>
                </div>

                <div className="mt-12">
                  <h3 className="font-semibold text-lg mb-4">Om SweepScore</h3>
                  <p className="text-gray-700 mb-4">
                    SweepScore är en digital plattform för curlingtävlingar i Sverige.
                    Vi erbjuder omfattande lösningar för att hantera, visa och följa curlingtävlingar på för curlingklubbar.
                  </p>
                  <p className="text-gray-700">
                    Vår vision är att göra curlingsporten mer tillgänglig och engagerande för alla,
                    från nybörjare till elitspelare och supportrar.
                  </p>
                </div>
              </div>

              <div className="lg:col-span-3 bg-white rounded-xl shadow-lg p-8">
                <h2 className="text-2xl font-semibold mb-6">Skicka ett meddelande</h2>

                {submitSuccess && (
                  <div className="mb-6 bg-green-50 border border-green-100 text-green-800 rounded-lg p-4 flex items-start">
                    <CheckCircle className="w-5 h-5 mr-3 mt-0.5 flex-shrink-0" />
                    <p>Tack för ditt meddelande! Vi återkommer så snart som möjligt.</p>
                  </div>
                )}

                {submitError && (
                  <div className="mb-6 bg-red-50 border border-red-100 text-red-800 rounded-lg p-4 flex items-start">
                    <AlertCircle className="w-5 h-5 mr-3 mt-0.5 flex-shrink-0" />
                    <p>{submitError}</p>
                  </div>
                )}

                <form onSubmit={handleSubmit} className="space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                        Namn <span className="text-red-500">*</span>
                      </label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        value={formData.name}
                        onChange={handleChange}
                        className={`w-full px-4 py-2 border ${errors.name ? 'border-red-300 ring-red-200' : 'border-gray-300'} rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500`}
                      />
                      {errors.name && (
                        <p className="mt-1 text-sm text-red-600">{errors.name}</p>
                      )}
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                        E-post <span className="text-red-500">*</span>
                      </label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`w-full px-4 py-2 border ${errors.email ? 'border-red-300 ring-red-200' : 'border-gray-300'} rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500`}
                      />
                      {errors.email && (
                        <p className="mt-1 text-sm text-red-600">{errors.email}</p>
                      )}
                    </div>
                  </div>

                  <div>
                    <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                      Ämne <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="subject"
                      name="subject"
                      type="text"
                      value={formData.subject}
                      onChange={handleChange}
                      className={`w-full px-4 py-2 border ${errors.subject ? 'border-red-300 ring-red-200' : 'border-gray-300'} rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500`}
                    />
                    {errors.subject && (
                      <p className="mt-1 text-sm text-red-600">{errors.subject}</p>
                    )}
                  </div>

                  <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                      Meddelande <span className="text-red-500">*</span>
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      rows="6"
                      value={formData.message}
                      onChange={handleChange}
                      className={`w-full px-4 py-2 border ${errors.message ? 'border-red-300 ring-red-200' : 'border-gray-300'} rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500`}
                    ></textarea>
                    {errors.message && (
                      <p className="mt-1 text-sm text-red-600">{errors.message}</p>
                    )}
                  </div>

                  <div>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`flex items-center justify-center w-full md:w-auto px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 ${isSubmitting ? 'opacity-70 cursor-not-allowed' : 'hover:bg-blue-700'
                        } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                    >
                      {isSubmitting ? (
                        <>
                          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          Skickar...
                        </>
                      ) : (
                        <>
                          <Send className="mr-2 h-5 w-5" />
                          Skicka meddelande
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>

          <section className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg p-8 mt-16">
            <h2 className="text-2xl font-bold mb-6 text-gray-900">Vanliga frågor</h2>
            <div className="space-y-6">
              <div className="border-b border-gray-200 pb-4">
                <h3 className="text-lg font-medium text-gray-900 mb-2">Hur skapar jag ett konto på SweepScore?</h3>
                <p className="text-gray-700">
                  För att skapa ett konto behöver du bara ange din e-postadress och följa instruktionerna för att verifiera din identitet.
                  Administratörer från din curlingklubb kan också skapa konton åt medlemmar.
                </p>
              </div>

              <div className="border-b border-gray-200 pb-4">
                <h3 className="text-lg font-medium text-gray-900 mb-2">Hur uppdaterar jag resultat för mitt lag?</h3>
                <p className="text-gray-700">
                  När du är inloggad kan du gå till "Mina matcher" där du kan rapportera resultat för matcher där ditt lag deltar.
                  Du behöver vara registrerad som lagmedlem för att kunna uppdatera matchresultat.
                </p>
              </div>

              <div className="border-b border-gray-200 pb-4">
                <h3 className="text-lg font-medium text-gray-900 mb-2">Hur kan min klubb använda SweepScore för sina tävlingar?</h3>
                <p className="text-gray-700">
                  Kontakta oss via formuläret ovan eller mejla direkt till support@sweepscore.se för att diskutera hur vi kan hjälpa din klubb
                  att digitalisera era tävlingar och resultatrapportering.
                </p>
              </div>

              <div className="pb-4">
                <h3 className="text-lg font-medium text-gray-900 mb-2">Är tjänsten gratis att använda?</h3>
                <p className="text-gray-700">
                  SweepScore erbjuder både kostnadsfria grundfunktioner och premiumtjänster för klubbar med mer avancerade behov.
                  Kontakta oss för mer information om prissättning för just din klubbs behov.
                </p>
              </div>
            </div>
          </section>

          <section className="max-w-4xl mx-auto mt-12">
            <div className="bg-blue-50 border border-blue-100 rounded-xl p-8">
              <h2 className="text-2xl font-semibold mb-4 text-blue-900">Teknisk support</h2>
              <p className="text-blue-800 mb-6">
                Behöver du hjälp med tekniska problem eller har frågor om hur systemet fungerar?
                Vårt supportteam finns tillgängligt för att hjälpa dig med alla aspekter av SweepScore.
              </p>

              <div className="flex flex-col sm:flex-row gap-4 items-center">
                <a
                  href="mailto:support@sweepscore.se"
                  className="flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  <Mail className="mr-2 h-5 w-5" />
                  Kontakta teknisk support
                </a>
              </div>
            </div>
          </section>
        </main>

        <Footer />
      </div>
    </>
  );
};

export default ContactUs;