import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Calendar, User, Info, Trophy, Users, Clock, MapPin } from 'lucide-react';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import SEO from '../components/SEO';

const ActiveCompetitions = () => {
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        setLoading(true);
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/leagues/');
        if (!response.ok) {
          throw new Error('Failed to fetch leagues');
        }
        const data = await response.json();

        // Loop through each league to fetch teams for both groups and count them
        const updatedLeagues = await Promise.all(
          data.map(async (league) => {
            const groupATeams = await fetchTeamsInGroup(league.id, 'A');
            const groupBTeams = await fetchTeamsInGroup(league.id, 'B');
            const totalTeams = (groupATeams?.length || 0) + (groupBTeams?.length || 0);
            return { ...league, totalTeams };
          })
        );

        // Filter for active leagues only
        const activeLeagues = updatedLeagues.filter(league => league.active);
        setLeagues(activeLeagues);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching leagues:', err);
      } finally {
        setLoading(false);
      }
    };

    const fetchTeamsInGroup = async (leagueId, group) => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/teams/?group=${group}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch teams for group ${group} in league ${leagueId}`);
        }
        const data = await response.json();
        return data;
      } catch (error) {
        return [];
      }
    };

    fetchLeagues();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <img
          src={require('../images/curling.gif')}
          alt="Loading..."
          className="w-24 h-24"
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-red-600">Error: {error}</div>
      </div>
    );
  }

  return (
    <>
      <SEO
        title="Aktiva curlingtävlingar"
        description="Se alla pågående curlingtävlingar i Sverige. Följ liveresultat, tabeller och matcher i aktiva curlingseriespel och turneringar."
        keywords="aktiva curlingtävlingar, pågående curlingmatcher, curlingserier, curlingturneringar, curlingleague, liveresultat curling, curling Härnösand"
        canonicalUrl="https://sweepscore.se/aktiva-tavlingar"
      />

      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex flex-col">
        <Helmet>
          <title>Aktiva Curling Tävlingar | SweepScore</title>
          <meta name="description" content="Utforska pågående curlingtävlingar i Sverige. Se spelscheman, resultat och tabeller för aktiva ligor och turneringar inom curling." />
          <meta name="keywords" content="curling, svenska curlingtävlingar, aktiva curlingsäsonger, curlingligor, curlingturneringar, curlingresultat" />
          <link rel="canonical" href="https://sweepscore.se/aktiva-tavlingar" />
        </Helmet>

        <main className="max-w-7xl mx-auto px-4 py-16 pt-28 space-y-12 flex-grow">
          <section>
            <div className="max-w-3xl mx-auto mb-12 text-center">
              <h1 className="text-4xl font-bold mb-6 text-gray-900">Aktiva Curlingtävlingar</h1>
              <p className="text-lg text-gray-700">
                Utforska de curlingtävlingar som just nu pågår runt om i Sverige. Följ matcher, resultat och ställningar i realtid.
              </p>
            </div>

            {leagues.length === 0 ? (
              <div className="bg-white rounded-xl shadow-lg p-8 text-center max-w-2xl mx-auto">
                <Trophy className="w-16 h-16 text-gray-400 mx-auto mb-4" />
                <h2 className="text-2xl font-semibold text-gray-700 mb-2">Inga aktiva tävlingar</h2>
                <p className="text-gray-600 mb-4">
                  Det finns inga aktiva tävlingar att visa just nu. Kom tillbaka senare eller utforska våra arkiverade tävlingar.
                </p>
                <Link to="/arkiverade-tavlingar" className="inline-block bg-gray-800 text-white py-2 px-6 rounded-lg hover:bg-gray-700 transition-colors">
                  Se arkiverade tävlingar
                </Link>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {leagues.map((league) => (
                  <Link to={`/league/${league.id}`} key={league.id} className="h-full">
                    <div className="w-full h-full bg-white rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:shadow-xl hover:translate-y-[-5px] flex flex-col">
                      <div className="bg-gradient-to-r from-gray-800 to-gray-700 px-6 py-4 relative overflow-hidden">
                        <div className="relative z-10">
                          <h2 className="text-xl font-bold text-white">{league.name}</h2>
                          <span className="inline-block px-3 py-1 text-xs bg-green-500 text-white rounded-full mt-2">
                            Aktiv
                          </span>
                        </div>
                        <div className="absolute top-0 right-0 w-32 h-32 bg-white opacity-5 rounded-full -mr-16 -mt-16"></div>
                        <div className="absolute bottom-0 left-0 w-24 h-24 bg-white opacity-5 rounded-full -ml-12 -mb-12"></div>
                      </div>

                      <div className="p-6 space-y-4 flex-grow flex flex-col">
                        <div className="flex items-start space-x-3">
                          <User className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-500">Arrangör</p>
                            <p className="text-gray-900 font-medium">{league.organizer || 'Ej specificerad'}</p>
                          </div>
                        </div>

                        <div className="flex items-start space-x-3">
                          <Calendar className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-500">Datum</p>
                            <p className="text-gray-900 font-medium">
                              {new Date(league.start_date).toLocaleDateString('sv-SE')} - {new Date(league.end_date).toLocaleDateString('sv-SE')}
                            </p>
                          </div>
                        </div>

                        <div className="flex items-start space-x-3">
                          <Users className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-500">Antal lag</p>
                            <p className="text-gray-900 font-medium">{league.totalTeams}</p>
                          </div>
                        </div>

                        {league.leagueinfo && (
                          <div className="flex items-start space-x-3">
                            <Info className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
                            <div>
                              <p className="text-sm text-gray-500">Information</p>
                              <p className="text-gray-900 line-clamp-2">{league.leagueinfo}</p>
                            </div>
                          </div>
                        )}

                        <div className="mt-auto pt-4">
                          <div className="bg-blue-50 border border-blue-100 rounded-lg p-3 text-center">
                            <span className="text-blue-800 font-medium">Se detaljer</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </section>

          <section className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg p-8 mt-16">
            <h2 className="text-2xl font-bold mb-6 text-gray-900">Om Curlingtävlingar i Sverige</h2>
            <div className="prose prose-lg max-w-none text-gray-700">
              <p>
                Curling är en sport med djupa rötter i Sverige och har vuxit i popularitet genom åren.
                De aktiva tävlingarna som visas här representerar spännande mästerskap och ligor som pågår runt om i landet.
              </p>
              <p>
                Varje tävling har sitt eget format med gruppspel, slutspel och ibland även B-slutspel för förlorarna i det första slutspelssteget.
                Detta ger alla lag chansen att spela fler matcher och utvecklas tillsammans, oavsett resultat i gruppspelet.
              </p>
              <p>
                Genom SweepScore kan du följa alla matcher, resultat och tabeller i realtid.
                Var med och upplev den fartfyllda curlingsporten oavsett var du befinner dig!
              </p>
            </div>
          </section>

          <section className="max-w-4xl mx-auto mt-12">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow">
                <Clock className="w-8 h-8 text-blue-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">Aktuella resultat</h3>
                <p className="text-gray-700">
                  Alla resultat uppdateras i realtid för att ge dig den senaste informationen från isbanorna.
                </p>
              </div>

              <div className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow">
                <MapPin className="w-8 h-8 text-green-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">Nationell täckning</h3>
                <p className="text-gray-700">
                  Vi samlar tävlingar från curlingklubbar över hela Sverige på en och samma plattform.
                </p>
              </div>

              <div className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow">
                <Trophy className="w-8 h-8 text-yellow-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">Turneringsformat</h3>
                <p className="text-gray-700">
                  Från kvalspel till finaler - följ hela resan och håll dig uppdaterad om alla turneringsmoment.
                </p>
              </div>
            </div>
          </section>
        </main>

        <Footer />
      </div>
    </>
  );
};

export default ActiveCompetitions;