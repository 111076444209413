import React, { useEffect, useState } from 'react';
import { Trophy, Users, Award, User, ListOrdered, Calendar, MapPin, Info, Flag, Archive } from 'lucide-react';
import { FinalPlacementsList } from './FinalPlacementList';
import { StatisticsCard } from './StatisticsCard';
import Footer from '../Footer';
import { Helmet } from 'react-helmet-async';

const ArchivedLeagueDetails = ({ leagueId, leagueData }) => {
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [groupAData, setGroupAData] = useState([]);
  const [groupBData, setGroupBData] = useState([]);
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    const fetchLeagueResults = async () => {
      try {
        setLoading(true);

        // Fetch results for Playoff A
        const responseA = await fetch(
          `${process.env.REACT_APP_API_URL}/api/league-results/?playoff_group=A&league_ids=${leagueId}`
        );

        // Fetch results for Playoff B
        const responseB = await fetch(
          `${process.env.REACT_APP_API_URL}/api/league-results/?playoff_group=B&league_ids=${leagueId}`
        );

        if (!responseA.ok || !responseB.ok) {
          throw new Error('Failed to fetch league results');
        }

        const playoffAResults = await responseA.json();
        const playoffBResults = await responseB.json();

        // Create results object
        const updatedStatistics = {
          ...leagueData.statistics,
          finalPlacements: playoffAResults[0]?.results || [],
          loserBracketPlacements: playoffBResults[0]?.results || [],
          mostWins: playoffAResults[0]?.results?.find(result => result.finalplacement === 1)?.team || null,
          highestScore: playoffAResults[0]?.results?.find(result => result.rank === 1)?.team || null
        };

        setStatistics(updatedStatistics);

      } catch (error) {
        console.error('Fel vid hämtning av resultat:', error);
        setError('Could not fetch league results');
      } finally {
        setLoading(false);
      }
    };

    const fetchPointsForGroup = async (group) => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/table/?group=${group}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return await response.json();
      } catch (error) {
        console.error(`Error fetching points for group ${group}:`, error);
        setError(error.message);
        return [];
      }
    };

    const fetchPoints = async () => {
      try {
        setLoading(true);
        const [groupA, groupB] = await Promise.all([
          fetchPointsForGroup('A'),
          fetchPointsForGroup('B')
        ]);

        setGroupAData(groupA);
        setGroupBData(groupB);

      } catch (error) {
        console.error('Error in fetchPoints:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPoints();
    fetchLeagueResults();
  }, [leagueId, leagueData]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-red-600">Error: {error}</div>
      </div>
    );
  }

  const winner = statistics?.finalPlacements.find((placement) => placement.finalplacement === 1);

  const findBestTeamOverallSeason = (groupAData, groupBData) => {
    // Lägg till en null/undefined-kontroll
    if (!groupAData || !groupBData) {
      return null;
    }

    const combinedTeams = [...groupAData, ...groupBData];

    // Sortera lagen baserat på poäng och stenskillnad
    const sortedTeams = combinedTeams.sort((a, b) => {
      // Se till att både a och b har de förväntade egenskaperna
      const aPoints = a?.points || 0;
      const bPoints = b?.points || 0;
      const aStone = a?.stone_difference || 0;
      const bStone = b?.stone_difference || 0;

      // Först sortera efter poäng (fallande ordning)
      if (bPoints !== aPoints) {
        return bPoints - aPoints;
      }

      // Om poängen är lika, sortera efter stenskillnad (fallande ordning)
      return bStone - aStone;
    });

    // Returnera det första laget (bästa laget)
    return sortedTeams[0] || null;
  };

  const bestTeamOverall = findBestTeamOverallSeason(groupAData, groupBData);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('sv-SE', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Helmet>
        <title>{leagueData.name} | Arkiverad Tävling | SweepScore</title>
        <meta name="description" content={`Se resultat och statistik för den arkiverade curlingtävlingen ${leagueData.name}. Arrangerad av ${leagueData.organizer}.`} />
      </Helmet>
      
      {loading ? (
        <div className="min-h-screen flex items-center justify-center">
          <img
            src={require('../../images/curling.gif')}
            alt="Loading..."
            className="w-24 h-24"
          />
        </div>
      ) : (
        <main className="flex-grow container mx-auto px-4 py-16 pt-24 lg:pt-32 space-y-8">
          {/* Hero Header */}
          <div className="bg-gradient-to-r from-gray-800 to-gray-700 rounded-xl shadow-lg p-5 text-white overflow-hidden relative">
            <div className="absolute top-0 right-0 w-64 h-64 bg-white opacity-5 rounded-full -mr-32 -mt-32"></div>
            <div className="absolute bottom-0 left-0 w-40 h-40 bg-white opacity-5 rounded-full -ml-20 -mb-20"></div>
            <div className="relative z-10">
              <div className="flex justify-between items-center mb-3">
                <h1 className="text-2xl md:text-3xl font-bold">{leagueData.name}</h1>
                <span className="inline-block px-3 py-1 text-xs bg-red-500 text-white rounded-full">
                  Arkiverad
                </span>
              </div>
              <div className="flex flex-wrap gap-3 mt-3">
                <div className="flex items-center gap-2">
                  <User className="w-4 h-4 text-blue-300" />
                  <span className="text-sm">{leagueData.organizer || 'Ej specificerad'}</span>
                </div>
                <div className="flex items-center gap-2">
                  <Calendar className="w-4 h-4 text-blue-300" />
                  <span className="text-sm">
                    {formatDate(leagueData.start_date)} - {formatDate(leagueData.end_date)}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Navigation Tabs */}
          <div className="bg-white rounded-lg shadow-md p-2">
            <div className="flex flex-wrap gap-2">
              <button
                onClick={() => setActiveTab('overview')}
                className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
                  activeTab === 'overview' ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:bg-gray-100'
                }`}
              >
                <Info className="w-5 h-5" />
                <span>Översikt</span>
              </button>
              
              <button
                onClick={() => setActiveTab('placements')}
                className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
                  activeTab === 'placements' ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:bg-gray-100'
                }`}
              >
                <ListOrdered className="w-5 h-5" />
                <span>Slutställning</span>
              </button>

              <button
                onClick={() => setActiveTab('stats')}
                className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
                  activeTab === 'stats' ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:bg-gray-100'
                }`}
              >
                <Trophy className="w-5 h-5" />
                <span>Statistik</span>
              </button>
            </div>
          </div>

          {/* Tab Content */}
          {activeTab === 'overview' && (
            <section>
              <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
                {/* Main content */}
                <div className="md:col-span-8">
                  <div className="bg-white rounded-xl shadow-lg p-6 mb-6">
                    <h2 className="text-2xl font-bold mb-4 flex items-center">
                      <Info className="w-6 h-6 mr-2 text-blue-600" />
                      Översikt
                    </h2>
                    
                    <p className="text-gray-700 mb-6">
                      {leagueData.leagueinfo || 
                       `${leagueData.name} var en curlingtävling som arrangerades av ${leagueData.organizer || 'okänd arrangör'} 
                        mellan ${formatDate(leagueData.start_date)} och ${formatDate(leagueData.end_date)}.`}
                    </p>
                    
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                      <div className="bg-gradient-to-br from-yellow-100 to-yellow-200 rounded-lg p-6">
                        <h3 className="text-lg font-semibold mb-4">Vinnare A-Slutspel</h3>
                        <div className="flex items-center space-x-4">
                          <Trophy className="w-8 h-8 text-yellow-600" />
                          <div>
                            <p className="font-bold text-lg">
                              {winner?.team.name || "Ingen data"}
                            </p>
                          </div>
                        </div>
                      </div>
                      
                      <div className="bg-gradient-to-br from-blue-100 to-blue-200 rounded-lg p-6">
                        <h3 className="text-lg font-semibold mb-4">Bästa lag grundserien</h3>
                        <div className="flex items-center space-x-4">
                          <Award className="w-8 h-8 text-blue-600" />
                          <div>
                            <p className="font-bold text-lg">
                              {bestTeamOverall && bestTeamOverall.name
                                ? bestTeamOverall.name
                                : "Ingen data"}
                            </p>
                            <p className="text-sm text-gray-600">
                              {bestTeamOverall && bestTeamOverall.points
                                ? `${bestTeamOverall.points} poäng  |  ${bestTeamOverall.stone_difference} stenskillnad`
                                : "0 poäng"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-4 p-4 bg-gray-50 rounded-lg">
                      <h3 className="text-lg font-semibold mb-2 flex items-center">
                        <MapPin className="w-5 h-5 mr-2 text-blue-600" />
                        Tävlingsinformation
                      </h3>
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-gray-700">
                        <div>
                          <p className="font-medium">Arrangör:</p>
                          <p>{leagueData.organizer || "Ej specificerad"}</p>
                        </div>
                        <div>
                          <p className="font-medium">Datum:</p>
                          <p>{formatDate(leagueData.start_date)} - {formatDate(leagueData.end_date)}</p>
                        </div>
                        <div>
                          <p className="font-medium">Antal lag:</p>
                          <p>{leagueData.teamscount || "Okänt"}</p>
                        </div>
                        <div>
                          <p className="font-medium">Antal deltagare:</p>
                          <p>{leagueData.participants || "Okänt"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Statistics sidebar */}
                <div className="md:col-span-4">
                  <div className="bg-white rounded-xl shadow-lg p-6 mb-6">
                    <h2 className="text-xl font-bold mb-4 flex items-center">
                      <Users className="w-5 h-5 mr-2 text-blue-600" />
                      Tävlingsstatistik
                    </h2>
                    
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-3 mb-6">
                      <StatisticsCard
                        icon={<Users className="w-5 h-5 sm:w-6 sm:h-6" />}
                        title="Deltagande lag"
                        value={leagueData.teamscount}
                      />
                      <StatisticsCard
                        icon={<User className="w-5 h-5 sm:w-6 sm:h-6" />}
                        title="Deltagare"
                        value={leagueData.participants}
                      />
                      <StatisticsCard
                        icon={<Trophy className="w-5 h-5 sm:w-6 sm:h-6" />}
                        title="Matcher spelade"
                        value={leagueData.gamesplayed}
                      />
                      <StatisticsCard
                        icon={<Award className="w-5 h-5 sm:w-6 sm:h-6" />}
                        title="MVP"
                        value={leagueData.mvp || "Ej utsedd"}
                      />
                    </div>
                    
                    <div className="bg-yellow-50 border border-yellow-100 rounded-lg p-4">
                      <div className="flex items-center mb-2">
                        <Archive className="w-5 h-5 text-yellow-600 mr-2" />
                        <h3 className="font-medium text-yellow-800">Arkiverad tävling</h3>
                      </div>
                      <p className="text-sm text-yellow-700">
                        Denna tävling är avslutad och arkiverad. Resultaten är slutgiltiga.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {activeTab === 'placements' && (
            <section>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <div>
                  <FinalPlacementsList
                    title="A-Slutspel"
                    finalPlacements={statistics?.finalPlacements || []}
                  />
                </div>

                {statistics?.loserBracketPlacements && statistics.loserBracketPlacements.length > 0 && (
                  <div>
                    <FinalPlacementsList
                      title="B-Slutspel"
                      finalPlacements={statistics.loserBracketPlacements}
                    />
                  </div>
                )}
              </div>

              <div className="mt-8 bg-white rounded-lg shadow-md p-6">
                <div className="flex items-center gap-3 mb-4">
                  <Flag className="w-5 h-5 text-blue-600" />
                  <h3 className="text-lg font-semibold">Om slutplaceringarna</h3>
                </div>
                <p className="text-gray-700">
                  Slutplaceringarna i denna tävling bestämdes genom slutspelet, där de bästa lagen från
                  gruppspelet möttes i utslagsmatcher. A-slutspelet bestämde de högsta placeringarna,
                  medan B-slutspelet avgjorde placeringarna för lagen som åkte ut tidigt i A-slutspelet.
                </p>
              </div>
            </section>
          )}

          {activeTab === 'stats' && (
            <section className="space-y-6">
              <div className="bg-white rounded-xl shadow-lg p-6">
                <h2 className="text-xl font-bold mb-4 flex items-center">
                  <Trophy className="w-5 h-5 mr-2 text-blue-600" />
                  Vinnare och utmärkelser
                </h2>
                
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  <div className="bg-yellow-50 border border-yellow-100 rounded-lg p-4">
                    <h3 className="font-medium text-yellow-900 mb-2">A-slutspelsvinnare</h3>
                    <p className="text-gray-900 font-bold">{winner?.team.name || "Ingen data"}</p>
                  </div>
                  
                  <div className="bg-blue-50 border border-blue-100 rounded-lg p-4">
                    <h3 className="font-medium text-blue-900 mb-2">Bästa grundserielag</h3>
                    <p className="text-gray-900 font-bold">
                      {bestTeamOverall?.name || "Ingen data"}
                    </p>
                  </div>
                  
                  <div className="bg-green-50 border border-green-100 rounded-lg p-4">
                    <h3 className="font-medium text-green-900 mb-2">MVP</h3>
                    <p className="text-gray-900 font-bold">{leagueData.mvp || "Ej utsedd"}</p>
                  </div>
                </div>
              </div>
              
              <div className="bg-white rounded-xl shadow-lg p-6">
                <h2 className="text-xl font-bold mb-4 flex items-center">
                  <ListOrdered className="w-5 h-5 mr-2 text-blue-600" />
                  Tävlingsstatistik
                </h2>
                
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                  <div className="bg-gray-50 rounded-lg p-4">
                    <h3 className="font-medium text-gray-700 mb-1">Deltagande lag</h3>
                    <p className="text-xl font-bold text-gray-900">{leagueData.teamscount || "0"}</p>
                  </div>
                  
                  <div className="bg-gray-50 rounded-lg p-4">
                    <h3 className="font-medium text-gray-700 mb-1">Deltagare</h3>
                    <p className="text-xl font-bold text-gray-900">{leagueData.participants || "0"}</p>
                  </div>
                  
                  <div className="bg-gray-50 rounded-lg p-4">
                    <h3 className="font-medium text-gray-700 mb-1">Matcher spelade</h3>
                    <p className="text-xl font-bold text-gray-900">{leagueData.gamesplayed || "0"}</p>
                  </div>
                  
                  <div className="bg-gray-50 rounded-lg p-4">
                    <h3 className="font-medium text-gray-700 mb-1">Tävlingsperiod</h3>
                    <p className="text-sm font-medium text-gray-900">
                      {formatDate(leagueData.start_date)} - <br />
                      {formatDate(leagueData.end_date)}
                    </p>
                  </div>
                </div>
              </div>
            </section>
          )}
        </main>
      )}
      <Footer />
    </div>
  );
};

export default ArchivedLeagueDetails;