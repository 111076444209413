// frontend/app.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import { HelmetProvider } from 'react-helmet-async';
import NavBar from './components/Navbar';
import Home from './pages/Home';
import Teams from './pages/Teams';
import LeagueDetail from './pages/LeagueDetail';
import PointsTable from './pages/PointsTable';
import MatchesPage from './pages/MatchesPage';
import MyCompetitions from './pages/MyCompetitions';
import MyMatches from './pages/MyMatches';
import LoginPage from './pages/Login';
import MyTeamPage from './pages/MyTeam';
import ProfilePage from './pages/ProfilePage';
import AdminPage from './pages/adminpage/AdminPage';
import Playoff from './pages/Playoffs';
import ManageLeague from './pages/adminpage/Leagues/ManageLeagues';
import AddLeagueTeams from './components/activeleagues/AddTeams';
import LeagueSchedule from './pages/LeagueSchedule';
import MobileMatchesSlider from './components/MobileMatchesSlider';
import ActiveCompetitions from './pages/ActiveCompetitions';
import ArchivedCompetitions from './pages/ArchivedCompetitions';
import ContactUs from './pages/ContactUs';
import SEO from './components/SEO'; // Import the new SEO component

// Create a Sitemap component
const Sitemap = () => {
  // This component will render sitemap XML when visiting /sitemap.xml
  // It returns the sitemap as raw XML inside a div to be captured by the server
  const baseUrl = 'https://sweepscore.se';
  
  // XML formatting with proper tags
  const xml = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  <url>
    <loc>${baseUrl}/</loc>
    <priority>1.0</priority>
    <changefreq>daily</changefreq>
  </url>
  <url>
    <loc>${baseUrl}/aktiva-tavlingar</loc>
    <priority>0.9</priority>
    <changefreq>daily</changefreq>
  </url>
  <url>
    <loc>${baseUrl}/arkiverade-tavlingar</loc>
    <priority>0.8</priority>
    <changefreq>weekly</changefreq>
  </url>
  <url>
    <loc>${baseUrl}/kontakta-oss</loc>
    <priority>0.7</priority>
    <changefreq>monthly</changefreq>
  </url>
  <url>
    <loc>${baseUrl}/login</loc>
    <priority>0.6</priority>
    <changefreq>monthly</changefreq>
  </url>
</urlset>`;

  return (
    <div dangerouslySetInnerHTML={{ __html: xml }} />
  );
};

const App = () => {
  return (
    <AuthProvider>
      <HelmetProvider>
        <Router>
          {/* Global SEO configuration */}
          <SEO 
            title="Digital resultatportal för curlingklubbar"
            description="SweepScore är den digitala resultatportalen för curlingklubbar i Sverige. Följ matcher, resultat och tabeller för curlingtävlingar i realtid."
            keywords="curling, resultatportal, curlingtävlingar, svenska curlingligor, curlingmatcher, curlingtabeller"
            canonicalUrl="https://sweepscore.se"
          />
          
          <Routes>
            {/* Sitemap route */}
            <Route path="/sitemap.xml" element={<Sitemap />} />
            
            <Route
              path="/"
              element={
                <div className="relative">
                  <div className="md:hidden fixed top-0 w-full z-50">
                    <MobileMatchesSlider />
                  </div>
                  <NavBar withLeagueLinks={false} />
                  <Home />
                </div>
              }
            />
            <Route
              path="/league/:id"
              element={
                <>
                  <NavBar withLeagueLinks={true} />
                  <LeagueDetail />
                </>
              }
            />
            <Route
              path="/league/:leagueId/teams"
              element={
                <>
                  <NavBar withLeagueLinks={true} />
                  <Teams />
                </>
              }
            />
            <Route
              path="/league/:leagueId/table"
              element={
                <>
                  <NavBar withLeagueLinks={true} />
                  <PointsTable />
                </>
              }
            />
            <Route
              path="/league/:leagueId/matches"
              element={
                <>
                  <NavBar withLeagueLinks={true} />
                  <MatchesPage />
                </>
              }
            />
            <Route
              path="/league/:leagueId/playoff"
              element={
                <>
                  <NavBar withLeagueLinks={true} />
                  <Playoff />
                </>
              }
            />
            <Route
              path="/league/:leagueId/schedule"
              element={
                <>
                  <NavBar withLeagueLinks={true} />
                  <LeagueSchedule />
                </>
              }
            />
            <Route
              path="/login"
              element={
                <>
                  <NavBar withLeagueLinks={false} />
                  <LoginPage />
                </>
              }
            />
            <Route
              path="/profile"
              element={
                <>
                  <NavBar withLeagueLinks={false} />
                  <ProfilePage />
                </>
              }
            />
            <Route
              path="/my-team"
              element={
                <>
                  <NavBar withLeagueLinks={false} />
                  <MyTeamPage />
                </>
              }
            />
            <Route
              path="/my-matches"
              element={
                <>
                  <NavBar withLeagueLinks={false} />
                  <MyMatches />
                </>
              }
            />
            <Route
              path="/my-competitions"
              element={
                <>
                  <NavBar withLeagueLinks={false} />
                  <MyCompetitions />
                </>
              }
            />
            <Route
              path="/admin"
              element={
                <>
                  <NavBar withLeagueLinks={false} />
                  <AdminPage />
                </>
              }
            />
            <Route
              path="/admin/manage-league/:id"
              element={
                <>
                  <NavBar withLeagueLinks={false} />
                  <ManageLeague />
                </>
              }
            />
            <Route
              path="/league/:leagueId/add-teams"
              element={
                <>
                  <NavBar withLeagueLinks={true} />
                  <AddLeagueTeams />
                </>
              }
            />
            {/* New routes for the three new pages */}
            <Route
              path="/aktiva-tavlingar"
              element={
                <>
                  <NavBar withLeagueLinks={false} />
                  <ActiveCompetitions />
                </>
              }
            />
            <Route
              path="/arkiverade-tavlingar"
              element={
                <>
                  <NavBar withLeagueLinks={false} />
                  <ArchivedCompetitions />
                </>
              }
            />
            <Route
              path="/kontakta-oss"
              element={
                <>
                  <NavBar withLeagueLinks={false} />
                  <ContactUs />
                </>
              }
            />
          </Routes>
        </Router>
      </HelmetProvider>
    </AuthProvider>
  );
};

export default App;