import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Calendar, User, Trophy, Clock, Award, Archive, Search } from 'lucide-react';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import SEO from '../components/SEO';

const ArchivedCompetitions = () => {
  const [leagues, setLeagues] = useState([]);
  const [filteredLeagues, setFilteredLeagues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedYear, setSelectedYear] = useState('all');

  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        setLoading(true);
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/leagues/');
        if (!response.ok) {
          throw new Error('Failed to fetch leagues');
        }
        const data = await response.json();

        // Loop through each league to fetch teams for both groups and count them
        const updatedLeagues = await Promise.all(
          data.map(async (league) => {
            const groupATeams = await fetchTeamsInGroup(league.id, 'A');
            const groupBTeams = await fetchTeamsInGroup(league.id, 'B');
            const totalTeams = (groupATeams?.length || 0) + (groupBTeams?.length || 0);
            return { ...league, totalTeams };
          })
        );

        // Filter for archived leagues only
        const archivedLeagues = updatedLeagues.filter(league => !league.active);

        // Fetch winners for each league
        const archivedLeaguesWithWinners = await Promise.all(
          archivedLeagues.map(async (league) => {
            try {
              const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/league-results/?playoff_group=A&league_ids=${league.id}`
              );
              if (response.ok) {
                const data = await response.json();
                const winner = data[0]?.results?.find(result => result.finalplacement === 1);
                return {
                  ...league,
                  winner: winner ? winner.team.name : 'Ingen vinnare registrerad'
                };
              }
              return league;
            } catch (error) {
              console.error(`Error fetching winner for league ${league.id}:`, error);
              return league;
            }
          })
        );

        setLeagues(archivedLeaguesWithWinners);
        setFilteredLeagues(archivedLeaguesWithWinners);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching leagues:', err);
      } finally {
        setLoading(false);
      }
    };

    const fetchTeamsInGroup = async (leagueId, group) => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/teams/?group=${group}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch teams for group ${group} in league ${leagueId}`);
        }
        const data = await response.json();
        return data;
      } catch (error) {
        return [];
      }
    };

    fetchLeagues();
  }, []);

  // Apply filters when search query or year changes
  useEffect(() => {
    let results = [...leagues];

    // Filter by search query
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      results = results.filter(league =>
        league.name.toLowerCase().includes(query) ||
        league.organizer?.toLowerCase().includes(query) ||
        league.winner?.toLowerCase().includes(query)
      );
    }

    // Filter by year
    if (selectedYear !== 'all') {
      results = results.filter(league => {
        const year = new Date(league.end_date).getFullYear().toString();
        return year === selectedYear;
      });
    }

    setFilteredLeagues(results);
  }, [searchQuery, selectedYear, leagues]);

  // Get unique years from leagues
  const getYears = () => {
    const years = leagues.map(league => new Date(league.end_date).getFullYear());
    return [...new Set(years)].sort((a, b) => b - a); // Sort descending
  };

  const uniqueYears = getYears();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <img
          src={require('../images/curling.gif')}
          alt="Loading..."
          className="w-24 h-24"
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-red-600">Error: {error}</div>
      </div>
    );
  }

  return (
    <>
      <SEO
        title="Arkiverade curlingtävlingar"
        description="Historiska resultat och statistik från tidigare curlingtävlingar i Sverige. Bläddra bland avslutade turneringar, curlingseriespel och matcher."
        keywords="arkiverade curlingmatcher, curlinghistorik, tidigare curlingtävlingar, curlingstatistik, avslutade curlingserier, curling härnösand"
        canonicalUrl="https://sweepscore.se/arkiverade-tavlingar"
      />

      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex flex-col">
        <Helmet>
          <title>Arkiverade Curlingtävlingar | SweepScore</title>
          <meta name="description" content="Utforska tidigare curlingtävlingar, mästerskap och ligor i Sverige. Se historiska resultat, vinnare och statistik från avslutade curlingturneringar." />
          <meta name="keywords" content="curling, historiska curlingtävlingar, avslutade curlingsäsonger, curlingligor, curlingmästerskap, curlinghistoria, curlingvinnare" />
          <link rel="canonical" href="https://sweepscore.se/arkiverade-tavlingar" />
        </Helmet>

        <main className="max-w-7xl mx-auto px-4 py-16 pt-28 space-y-12 flex-grow">
          <section>
            <div className="max-w-3xl mx-auto mb-12 text-center">
              <h1 className="text-4xl font-bold mb-6 text-gray-900">Arkiverade Curlingtävlingar</h1>
              <p className="text-lg text-gray-700">
                Utforska tidigare curlingtävlingar, resultat och vinnare genom åren.
                Dyk in i historien för Sveriges spännande curlingevenemang.
              </p>
            </div>

            <div className="mb-8 bg-white rounded-xl shadow-md p-6">
              <div className="flex flex-col md:flex-row gap-4 items-center justify-between mb-4">
                <div className="relative w-full md:w-1/2">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Search className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    placeholder="Sök efter tävling, arrangör eller vinnare..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="pl-10 pr-4 py-2 border border-gray-300 rounded-md w-full focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div className="w-full md:w-auto">
                  <select
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    className="block w-full md:w-auto px-4 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="all">Alla år</option>
                    {uniqueYears.map(year => (
                      <option key={year} value={year.toString()}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="text-gray-600 text-sm">
                Visar {filteredLeagues.length} av {leagues.length} tävlingar
              </div>
            </div>

            {filteredLeagues.length === 0 ? (
              <div className="bg-white rounded-xl shadow-lg p-8 text-center max-w-2xl mx-auto">
                <Archive className="w-16 h-16 text-gray-400 mx-auto mb-4" />
                <h2 className="text-2xl font-semibold text-gray-700 mb-2">Inga tävlingar hittades</h2>
                <p className="text-gray-600 mb-4">
                  {searchQuery || selectedYear !== 'all'
                    ? "Inga tävlingar matchar dina sökkriterier. Försök med en annan sökning eller filtrera efter ett annat år."
                    : "Det finns inga arkiverade tävlingar att visa ännu."}
                </p>
                {(searchQuery || selectedYear !== 'all') && (
                  <button
                    onClick={() => {
                      setSearchQuery('');
                      setSelectedYear('all');
                    }}
                    className="inline-block bg-gray-800 text-white py-2 px-6 rounded-lg hover:bg-gray-700 transition-colors"
                  >
                    Återställ filter
                  </button>
                )}
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {filteredLeagues.map((league) => (
                  <Link to={`/league/${league.id}`} key={league.id} className="h-full">
                    <div className="w-full h-full bg-white rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:shadow-xl hover:translate-y-[-5px] flex flex-col">
                      <div className="bg-gradient-to-r from-gray-700 to-gray-600 px-6 py-4 relative overflow-hidden">
                        <div className="relative z-10">
                          <div className="flex justify-between items-center">
                            <h2 className="text-xl font-bold text-white">{league.name}</h2>
                            <span className="text-xs text-white bg-gray-500 px-2 py-1 rounded-full">
                              {new Date(league.end_date).getFullYear()}
                            </span>
                          </div>
                          <span className="inline-block px-3 py-1 text-xs bg-red-500 text-white rounded-full mt-2">
                            Arkiverad
                          </span>
                        </div>
                        <div className="absolute top-0 right-0 w-32 h-32 bg-white opacity-5 rounded-full -mr-16 -mt-16"></div>
                        <div className="absolute bottom-0 left-0 w-24 h-24 bg-white opacity-5 rounded-full -ml-12 -mb-12"></div>
                      </div>

                      <div className="p-6 space-y-4 flex-grow flex flex-col">
                        <div className="flex items-start space-x-3">
                          <User className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-500">Arrangör</p>
                            <p className="text-gray-900 font-medium">{league.organizer || 'Ej specificerad'}</p>
                          </div>
                        </div>

                        <div className="flex items-start space-x-3">
                          <Trophy className="w-5 h-5 text-yellow-500 mt-0.5 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-500">Vinnare</p>
                            <p className="text-gray-900 font-medium">{league.winner || 'Ej registrerad'}</p>
                          </div>
                        </div>

                        <div className="flex items-start space-x-3">
                          <Calendar className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-500">Datum</p>
                            <p className="text-gray-900 font-medium">
                              {new Date(league.start_date).toLocaleDateString('sv-SE')} - {new Date(league.end_date).toLocaleDateString('sv-SE')}
                            </p>
                          </div>
                        </div>

                        <div className="flex items-start space-x-3">
                          <Award className="w-5 h-5 text-gray-900 mt-0.5 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-500">Lagantal</p>
                            <p className="text-gray-900 font-medium">{league.totalTeams || 'Uppgift saknas'}</p>
                          </div>
                        </div>

                        <div className="mt-auto pt-4">
                          <div className="bg-blue-50 border border-blue-100 rounded-lg p-3 text-center">
                            <span className="text-blue-800 font-medium">Se detaljer</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </section>

          <section className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg p-8 mt-16">
            <h2 className="text-2xl font-bold mb-6 text-gray-900">Curlinghistoria i Sverige</h2>
            <div className="prose prose-lg max-w-none text-gray-700">
              <p>
                Sverige har en rik historia inom curlingsporten, med många framgångsrika lag och turneringar genom åren.
                Vårt arkiv med avslutade tävlingar ger en fascinerande inblick i sportens utveckling i Sverige.
              </p>
              <p>
                Curling har utvecklats från en nischsport till en populär vinteraktivitet med växande intresse,
                särskilt efter svenska landslagens framgångar på internationell nivå. Denna samling av tidigare
                turneringar visar bredd, kompetens och engagemang inom sporten.
              </p>
              <p>
                Genom att utforska arkivet kan du upptäcka historiska segerlag, minnesvärda matcher och se hur
                sporten har utvecklats genom åren. Dessa tävlingar representerar inte bara historiska resultat utan
                också gemenskapen och passionen som definierar curlingsporten i Sverige.
              </p>
            </div>
          </section>

          <section className="max-w-4xl mx-auto mt-12">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow">
                <Trophy className="w-8 h-8 text-yellow-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">Historiska vinnare</h3>
                <p className="text-gray-700">
                  Utforska tidigare mästare och deras väg till framgång inom svensk curling.
                </p>
              </div>

              <div className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow">
                <Clock className="w-8 h-8 text-blue-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">Årliga tävlingar</h3>
                <p className="text-gray-700">
                  Se resultat från traditionella turneringar och följ deras utveckling genom åren.
                </p>
              </div>

              <div className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow">
                <Award className="w-8 h-8 text-purple-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">Statistik & rekord</h3>
                <p className="text-gray-700">
                  Upptäck anmärkningsvärda prestationer och statistik från tidigare curlingtävlingar.
                </p>
              </div>
            </div>
          </section>
        </main>

        <Footer />
      </div>
    </>
  );
};

export default ArchivedCompetitions;