import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import TeamPopup from '../components/Teams/TeamPopup';
import { ListOrdered, BarChart3, ArrowUpDown, AlertCircle, Info, Trophy, ThumbsDown } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import hckLogo from '../images/HCK.png';
import SEO from '../components/SEO';

const PointsTable = () => {
    const { leagueId } = useParams();
    const [leagueName, setLeagueName] = useState('');
    const [loading, setLoading] = useState(true);
    const [groupAData, setGroupAData] = useState([]);
    const [groupBData, setGroupBData] = useState([]);
    const [error, setError] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [teamMatches, setTeamMatches] = useState({});
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchLeagueName = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/`);
                if (!response.ok) {
                    throw new Error('Failed to fetch league name');
                }
                const data = await response.json();
                setLeagueName(data.name);
            } catch (error) {
                console.error('Error fetching league name:', error);
                setError(error.message);
            }
        };

        const fetchPointsForGroup = async (group) => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/table/?group=${group}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return await response.json();
            } catch (error) {
                console.error(`Error fetching points for group ${group}:`, error);
                setError(error.message);
                return [];
            }
        };

        const fetchMatches = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/matches/`);
                if (!response.ok) throw new Error('Failed to fetch matches');
                const data = await response.json();

                const matchesByTeam = {};
                data.forEach(match => {
                    if (!matchesByTeam[match.team1_name]) matchesByTeam[match.team1_name] = [];
                    if (!matchesByTeam[match.team2_name]) matchesByTeam[match.team2_name] = [];

                    const matchData = {
                        id: match.id,
                        date: match.date,
                        home_team: { name: match.team1_name },
                        away_team: { name: match.team2_name },
                        home_score: match.team1_score,
                        away_score: match.team2_score,
                        status: match.team1_score !== null && (match.team1_score > 0 || match.team2_score > 0) ? 'completed' : 'scheduled',
                        round_number: match.round_number
                    };

                    matchesByTeam[match.team1_name].push(matchData);
                    matchesByTeam[match.team2_name].push(matchData);
                });

                setTeamMatches(matchesByTeam);
            } catch (error) {
                console.error('Error fetching matches:', error);
            }
        };

        const fetchPoints = async () => {
            try {
                setLoading(true);
                const [groupA, groupB] = await Promise.all([
                    fetchPointsForGroup('A'),
                    fetchPointsForGroup('B')
                ]);

                // Calculate wins and losses for each team based on the matches
                const enrichedGroupA = await enrichTeamsWithWinsLosses(groupA);
                const enrichedGroupB = await enrichTeamsWithWinsLosses(groupB);

                setGroupAData(enrichedGroupA);
                setGroupBData(enrichedGroupB);

                await fetchLeagueName();
                await fetchMatches();
            } catch (error) {
                console.error('Error in fetchPoints:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        const enrichTeamsWithWinsLosses = async (teams) => {
            // Fetch all matches for the league
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/matches/`);
                if (!response.ok) throw new Error('Failed to fetch matches for win/loss calculation');
                const matches = await response.json();

                // Enrich each team with wins and losses
                return teams.map(team => {
                    let wins = 0;
                    let losses = 0;
                    let draws = 0;

                    // Find all matches where this team participated
                    const teamMatches = matches.filter(match =>
                        match.team1_name === team.name || match.team2_name === team.name
                    );

                    // Count wins, losses, and draws
                    teamMatches.forEach(match => {
                        // Skip unplayed matches or matches without scores
                        if (match.team1_score === null || match.team2_score === null ||
                            (match.team1_score === 0 && match.team2_score === 0)) {
                            return;
                        }

                        const isTeam1 = match.team1_name === team.name;
                        const teamScore = isTeam1 ? match.team1_score : match.team2_score;
                        const opponentScore = isTeam1 ? match.team2_score : match.team1_score;

                        if (teamScore > opponentScore) {
                            wins++;
                        } else if (teamScore < opponentScore) {
                            losses++;
                        } else {
                            draws++;
                        }
                    });

                    return {
                        ...team,
                        wins,
                        losses,
                        draws
                    };
                });
            } catch (error) {
                console.error('Error enriching teams with wins/losses:', error);
                return teams; // Return original teams if error
            }
        };

        fetchPoints();
    }, [leagueId]);

    const handleTeamClick = (team) => {
        setSelectedTeam(team);
    };

    const getHeadToHeadResult = (teamA, teamB) => {
        // Get matches between these teams
        const teamAMatches = teamMatches[teamA.name] || [];
        const matchesAgainstB = teamAMatches.filter(match =>
            (match.home_team.name === teamA.name && match.away_team.name === teamB.name) ||
            (match.away_team.name === teamA.name && match.home_team.name === teamB.name)
        );

        // If they haven't played each other, return null
        if (matchesAgainstB.length === 0) return null;

        // Count wins for each team
        let teamAWins = 0;
        let teamBWins = 0;

        matchesAgainstB.forEach(match => {
            if (match.status !== 'completed') return;

            const isTeamAHome = match.home_team.name === teamA.name;
            const teamAScore = isTeamAHome ? match.home_score : match.away_score;
            const teamBScore = isTeamAHome ? match.away_score : match.home_score;

            if (teamAScore > teamBScore) teamAWins++;
            else if (teamBScore > teamAScore) teamBWins++;
        });

        // Return the team with more wins, or null if tied
        if (teamAWins > teamBWins) return teamA;
        if (teamBWins > teamAWins) return teamB;
        return null;
    };

    const sortedTeams = (teams) => {
        return teams.sort((a, b) => {
            // First sort by points
            if (b.points !== a.points) {
                return b.points - a.points;
            }

            // If points are tied, check head-to-head result
            const headToHeadWinner = getHeadToHeadResult(a, b);
            if (headToHeadWinner) {
                return headToHeadWinner === a ? -1 : 1;
            }

            // If head-to-head is tied or they haven't played each other, sort by stone difference
            return b.stone_difference - a.stone_difference;
        });
    };

    // Function to truncate team name for mobile view
    const truncateTeamName = (name, maxLength = 15) => {
        if (!name) return '';
        return name.length <= maxLength ? name : `${name.substring(0, maxLength)}...`;
    };

    const renderDesktopTable = (teams, groupName) => {
        const sortedTeamsData = sortedTeams(teams);

        return (
            <div className="mb-8">
                <h3 className="text-xl font-bold mb-4 flex items-center">
                    <BarChart3 className="w-5 h-5 mr-2 text-blue-600" />
                    Grupp {groupName}
                </h3>

                <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                    <div className="overflow-x-auto">
                        <table className="min-w-full text-sm">
                            <thead>
                                <tr className="bg-gray-800 text-white">
                                    <th className="px-3 py-3 text-left">#</th>
                                    <th className="px-3 py-3 text-left">Lag</th>
                                    <th className="px-3 py-3 text-center">
                                        <div className="flex items-center justify-center">
                                            <Trophy className="w-4 h-4 mr-1" />
                                            <span>V</span>
                                        </div>
                                    </th>
                                    <th className="px-3 py-3 text-center">
                                        <div className="flex items-center justify-center">
                                            <ThumbsDown className="w-4 h-4 mr-1" />
                                            <span>F</span>
                                        </div>
                                    </th>
                                    <th className="px-3 py-3 text-center">
                                        <span>SM</span>
                                    </th>
                                    <th className="px-3 py-3 text-center">
                                        <span>SS</span>
                                    </th>
                                    <th className="px-3 py-3 text-center">
                                        <span>P</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedTeamsData.map((team, index) => (
                                    <tr
                                        key={team.id}
                                        className={`border-b hover:bg-gray-50 cursor-pointer transition-colors ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}
                                        onClick={() => handleTeamClick(team)}
                                    >
                                        <td className="py-3 px-3 font-medium">
                                            {index < 3 ? (
                                                <div className={`
                                                    w-6 h-6 rounded-full flex items-center justify-center
                                                    ${index === 0 ? 'bg-yellow-100 text-yellow-800' :
                                                        index === 1 ? 'bg-gray-100 text-gray-800' :
                                                            'bg-amber-100 text-amber-800'}
                                                `}>
                                                    {index + 1}
                                                </div>
                                            ) : (
                                                index + 1
                                            )}
                                        </td>
                                        <td className="py-3 px-3">
                                            <div className="flex items-center">
                                                <div className="w-8 h-8 rounded-full flex items-center justify-center mr-3">
                                                    <img src={hckLogo} alt="HCK logo" className="w-6 h-6 object-contain" />
                                                </div>
                                                <span className="font-medium">{team.name}</span>
                                            </div>
                                        </td>
                                        <td className="py-3 px-3 text-center text-green-600 font-medium">{team.wins || 0}</td>
                                        <td className="py-3 px-3 text-center text-red-600 font-medium">{team.losses || 0}</td>
                                        <td className="py-3 px-3 text-center">{team.matches_played}</td>
                                        <td className="py-3 px-3 text-center">
                                            <span className={
                                                team.stone_difference > 0 ? 'text-green-600' :
                                                    team.stone_difference < 0 ? 'text-red-600' : ''
                                            }>
                                                {team.stone_difference > 0 ? '+' : ''}{team.stone_difference}
                                            </span>
                                        </td>
                                        <td className="py-3 px-3 text-center font-bold">{team.points}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    };

    const renderMobileTable = (teams, groupName) => {
        const sortedTeamsData = sortedTeams(teams);

        return (
            <div className="mb-8">
                <h3 className="text-xl font-bold mb-2 flex items-center">
                    <BarChart3 className="w-5 h-5 mr-2 text-blue-600" />
                    Grupp {groupName}
                </h3>

                <div className="flex items-center gap-2 p-3 bg-blue-50 rounded-lg mb-4">
                    <Info className="w-4 h-4 text-blue-600 flex-shrink-0" />
                    <p className="text-xs text-blue-800">
                        Klicka på ett lag för att se detaljerad statistik och matchhistorik
                    </p>
                </div>

                <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                    <div className="overflow-x-auto">
                        <table className="min-w-full text-sm">
                            <thead>
                                <tr className="bg-gray-800 text-white">
                                    <th className="px-2 py-2 text-left w-8">#</th>
                                    <th className="px-2 py-2 text-left">Lag</th>
                                    <th className="px-2 py-2 text-center w-8">SM</th>
                                    <th className="px-2 py-2 text-center w-8">V</th>
                                    <th className="px-2 py-2 text-center w-8">SS</th>
                                    <th className="px-2 py-2 text-center w-8">P</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedTeamsData.map((team, index) => (
                                    <tr
                                        key={team.id}
                                        className={`border-b hover:bg-gray-50 cursor-pointer transition-colors ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}
                                        onClick={() => handleTeamClick(team)}
                                    >
                                        <td className="py-2 px-2 font-medium w-8">
                                            {index < 3 ? (
                                                <div className={`
                                                    w-5 h-5 rounded-full flex items-center justify-center text-xs
                                                    ${index === 0 ? 'bg-yellow-100 text-yellow-800' :
                                                        index === 1 ? 'bg-gray-100 text-gray-800' :
                                                            'bg-amber-100 text-amber-800'}
                                                `}>
                                                    {index + 1}
                                                </div>
                                            ) : (
                                                index + 1
                                            )}
                                        </td>
                                        <td className="py-2 px-2">
                                            <div className="flex items-center">
                                                <div className="w-6 h-6 rounded-full flex items-center justify-center mr-2 flex-shrink-0">
                                                    <img src={hckLogo} alt="HCK logo" className="w-6 h-6 object-contain" />
                                                </div>
                                                <span className="font-medium text-xs">{truncateTeamName(team.name)}</span>
                                            </div>
                                        </td>
                                        <td className="py-2 px-2 text-center text-xs w-8">{team.matches_played}</td>
                                        <td className="py-2 px-2 text-center text-xs w-8 text-green-600 font-medium">{team.wins || 0}</td>
                                        <td className="py-2 px-2 text-center text-xs w-8">
                                            <span className={
                                                team.stone_difference > 0 ? 'text-green-600' :
                                                    team.stone_difference < 0 ? 'text-red-600' : ''
                                            }>
                                                {team.stone_difference > 0 ? '+' : ''}{team.stone_difference}
                                            </span>
                                        </td>
                                        <td className="py-2 px-2 text-center font-bold text-xs w-8">{team.points}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    };


    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <img
                    src={require('../images/curling.gif')}
                    alt="Loading..."
                    className="w-24 h-24"
                />
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="bg-white rounded-xl shadow-lg p-8 text-center max-w-xl">
                    <AlertCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />
                    <h2 className="text-2xl font-semibold text-gray-800 mb-2">Ett fel har uppstått</h2>
                    <p className="text-gray-600 mb-4">{error}</p>
                </div>
            </div>
        );
    }

    const hasNoStandings = groupAData.length === 0 && groupBData.length === 0;

    return (
        <>
            <SEO
                title={`Poängtabell ${leagueName} | Curlingtävling`}
                description={`Aktuell tabell och ställning för ${leagueName}. Se lagens poäng, vinster, förluster och placeringar i Curlingtävlingen.`}
                keywords={`curlingtabell, ${leagueName} ställning, poängtabell curling, curlingresultat tabell, ligatabell curling`}
                canonicalUrl={`https://sweepscore.se/league/${leagueId}/table`}
            />

            <div className="min-h-screen bg-gray-100 flex flex-col">
                <Helmet>
                    <title>{`Tabell - ${leagueName}`} | SweepScore</title>
                    <meta name="description" content={`Se poängtabellen för ${leagueName}. Följ lagens resultat, stenskillnad och placeringar i realtid.`} />
                </Helmet>

                <main className="flex-grow container mx-auto px-4 py-16 pt-24 lg:pt-32">
                    <div className="bg-gradient-to-r from-gray-800 to-gray-700 rounded-xl shadow-lg p-5 text-white overflow-hidden mb-6 relative">
                        <div className="absolute top-0 right-0 w-64 h-64 bg-white opacity-5 rounded-full -mr-32 -mt-32"></div>
                        <div className="absolute bottom-0 left-0 w-40 h-40 bg-white opacity-5 rounded-full -ml-20 -mb-20"></div>
                        <div className="relative z-10">
                            <div className="flex justify-between items-center">
                                <h1 className="text-2xl md:text-3xl font-bold flex items-center">
                                    <ListOrdered className="mr-2 h-6 w-6" /> Tabell
                                </h1>

                                <span className="text-sm text-blue-300">{leagueName}</span>
                            </div>
                        </div>
                    </div>

                    {hasNoStandings ? (
                        <div className="bg-white rounded-xl shadow-lg p-8 text-center">
                            <AlertCircle className="w-16 h-16 text-gray-400 mx-auto mb-4" />
                            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Ingen tabell tillgänglig</h2>
                            <p className="text-gray-600 max-w-lg mx-auto">
                                Det finns inga poäng att visa ännu. Detta kan bero på att tävlingen
                                inte har börjat, eller att inga matcher har spelats.
                            </p>
                        </div>
                    ) : (
                        <>
                            {groupAData.length > 0 && (isMobile ?
                                renderMobileTable(groupAData, 'A') :
                                renderDesktopTable(groupAData, 'A')
                            )}

                            {groupBData.length > 0 && (isMobile ?
                                renderMobileTable(groupBData, 'B') :
                                renderDesktopTable(groupBData, 'B')
                            )}

                            <div className="mt-8 bg-white rounded-lg shadow-md p-6">
                                <h3 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
                                    <ArrowUpDown className="w-5 h-5 mr-2 text-blue-600" />
                                    Förklaringar
                                </h3>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
                                    <div className="space-y-2">
                                        <p className="flex items-center"><Trophy className="w-4 h-4 mr-2 text-green-600" /> <strong>V</strong> - Vinster</p>
                                        <p className="flex items-center"><ThumbsDown className="w-4 h-4 mr-2 text-red-600" /> <strong>F</strong> - Förluster</p>
                                        <p className="flex items-center"><span className="w-4 h-4 inline-block mr-2 text-center font-bold">SM</span> Spelade matcher</p>
                                    </div>
                                    <div className="space-y-2">
                                        <p className="flex items-center"><span className="w-4 h-4 inline-block mr-2 text-center font-bold">SS</span> Stenskillnad</p>
                                        <p className="flex items-center"><span className="w-4 h-4 inline-block mr-2 text-center font-bold">P</span> Poäng</p>
                                    </div>
                                </div>

                                <div className="mt-6 pt-4 border-t border-gray-200">
                                    <h4 className="font-medium text-gray-900 mb-2">Tiebreaker-regler:</h4>
                                    <ol className="list-decimal list-inside space-y-1 text-sm text-gray-700 ml-4">
                                        <li>Inbördes möten</li>
                                        <li>Stenskillnad</li>
                                    </ol>
                                </div>
                            </div>
                        </>
                    )}

                    {selectedTeam && (
                        <TeamPopup
                            team={selectedTeam}
                            matches={teamMatches[selectedTeam.name] || []}
                            onClose={() => setSelectedTeam(null)}
                            leagueId={leagueId}
                        />
                    )}
                </main>
                <Footer />
            </div>
        </>
    );
};

export default PointsTable;