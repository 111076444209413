import React from 'react';
import { Medal, Trophy, Award, Clock } from 'lucide-react';

export function FinalPlacementsList({ finalPlacements = [], title }) {
  const getMedalColor = (rank) => {
    switch (rank) {
      case 1: return 'text-yellow-500';  // Gold
      case 2: return 'text-gray-400';    // Silver
      case 3: return 'text-amber-600';   // Bronze
      default: return 'text-gray-400';   // No medal
    }
  };

  const getMedalBgColor = (rank) => {
    switch (rank) {
      case 1: return 'bg-yellow-100';  // Gold
      case 2: return 'bg-gray-100';    // Silver
      case 3: return 'bg-amber-100';   // Bronze
      default: return 'bg-gray-50';    // No medal
    }
  };

  const getMedalIcon = (rank) => {
    switch (rank) {
      case 1: return <Trophy className={`w-5 h-5 ${getMedalColor(rank)}`} />;
      case 2: return <Award className={`w-5 h-5 ${getMedalColor(rank)}`} />;
      case 3: return <Medal className={`w-5 h-5 ${getMedalColor(rank)}`} />;
      default: return <Clock className="w-5 h-5 text-gray-400" />;
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-6">
      <h3 className="text-xl font-bold mb-6 flex items-center">
        <Trophy className="w-5 h-5 mr-2 text-blue-600" />
        {title}
      </h3>
      
      {finalPlacements.length === 0 ? (
        <div className="text-center py-8 text-gray-500">
          Ingen slutställning tillgänglig
        </div>
      ) : (
        <div className="space-y-3">
          {finalPlacements.slice(0, 20).map((placement, index) => {
            // Use finalplacement for ranking if rank doesn't exist
            const rank = placement.rank || placement.finalplacement || (index + 1);
            
            // Ensure team is a string, use index as fallback key
            const teamName = placement.team?.name || placement.team || `Team ${index + 1}`;
            
            return (
              <div
                key={`${teamName}-${rank}`}
                className={`flex items-center p-3 rounded-lg transition-colors ${getMedalBgColor(rank)}`}
              >
                <div className="w-10 flex-shrink-0 flex justify-center">
                  {rank <= 3 ? (
                    <div className="w-8 h-8 rounded-full flex items-center justify-center">
                      {getMedalIcon(rank)}
                    </div>
                  ) : (
                    <span className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center text-gray-700 font-semibold">
                      {rank}
                    </span>
                  )}
                </div>
                
                <div className="ml-4 flex-grow">
                  <div className="flex items-center justify-between">
                    <p className="font-semibold text-gray-900">{teamName}</p>
                  </div>
                  
                  {placement.matches_played !== undefined && (
                    <p className="text-xs text-gray-500">
                      {placement.matches_played} matcher spelade
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}