import React, { useState, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const LatestMatchesSlider = () => {
  const [activeLeagues, setActiveLeagues] = useState([]);
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const sliderRef = useRef(null);
  
  // Group matches by date
  const matchesByDate = matches.reduce((acc, match) => {
    const date = match.date.split('T')[0];
    if (!acc[date]) acc[date] = [];
    acc[date].push(match);
    return acc;
  }, {});
  
  useEffect(() => {
    const fetchMatches = async () => {
      try {
        setLoading(true);
        
        // First fetch active leagues
        const leaguesResponse = await fetch(process.env.REACT_APP_API_URL + '/api/leagues/');
        if (!leaguesResponse.ok) throw new Error('Failed to fetch leagues');
        const leaguesData = await leaguesResponse.json();
        const activeLeaguesData = leaguesData.filter(league => league.active);
        setActiveLeagues(activeLeaguesData);
        
        // If no active leagues, stop here
        if (activeLeaguesData.length === 0) {
          setLoading(false);
          return;
        }
        
        // Fetch regular matches and playoff matches for all active leagues
        const allMatches = [];
        
        for (const league of activeLeaguesData) {
          // Fetch regular matches
          try {
            const matchesResponse = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${league.id}/matches/`);
            if (matchesResponse.ok) {
              const matchesData = await matchesResponse.json();
              
              // Include all matches, including those with 0-0 scores or null scores
              const matchesWithDetails = matchesData.map(match => ({
                ...match,
                league_name: league.name,
                isPlayed: match.team1_score !== null && match.team2_score !== null && 
                         (match.team1_score > 0 || match.team2_score > 0),
                date: match.date,
                isPlayoff: false
              }));
              
              allMatches.push(...matchesWithDetails);
            }
          } catch (error) {
            console.error(`Error fetching regular matches for league ${league.id}:`, error);
          }
          
          // Fetch playoff matches
          try {
            const playoffResponse = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${league.id}/playoffmatches/`);
            if (playoffResponse.ok) {
              const playoffData = await playoffResponse.json();
              
              // Process all playoff matches (round16, quarterfinals, semifinals, finals, third place, fifth place)
              // Check for traditional rounds with matches property
              const rounds = ['quarterfinals', 'semifinals', 'finals'];
              
              for (const round of rounds) {
                if (playoffData[round] && playoffData[round].matches) {
                  const playoffMatches = playoffData[round].matches.map(match => {
                    // Determine playoff round name
                    let roundName = '';
                    if (match.match_type === 'QF' || match.match_type === 'LR1') {
                      roundName = 'Kvartsfinal';
                    } else if (match.match_type === 'SF' || match.match_type === 'LR2') {
                      roundName = 'Semifinal';
                    } else if (match.match_type === 'F' || match.match_type === 'LF') {
                      roundName = 'Final';
                    } else if (match.match_type === '3P' || match.match_type === 'L3P') {
                      roundName = 'Match om 3:e plats';
                    } else if (match.match_type === '5P' || match.match_type === 'L5P') {
                      roundName = 'Match om 5:e plats';
                    } else if (match.match_type === 'R16' || match.match_type === 'LR16') {
                      roundName = 'Åttondelsfinal';
                    }
                    
                    // Determine bracket name
                    const bracketName = match.bracket_type === 'loser' ? 'B' : 'A';
                    
                    return {
                      id: `playoff-${match.id}`,
                      league_name: league.name,
                      isPlayoff: true,
                      round_name: roundName,
                      bracket_name: bracketName,
                      team1_name: match.team1_details?.team?.name || 'TBD',
                      team2_name: match.team2_details?.team?.name || 'TBD',
                      team1_score: match.team1_score,
                      team2_score: match.team2_score,
                      track: match.track || 1,
                      date: match.match_date,
                      isPlayed: match.team1_score !== null && match.team2_score !== null && 
                               (match.team1_score > 0 || match.team2_score > 0),
                      group: bracketName // Use bracket as group
                    };
                  });
                  
                  allMatches.push(...playoffMatches);
                }
              }
              
              // Process special rounds that are directly arrays (round16Matches, thirdPlaceMatches, fifthPlaceMatches)
              const specialRounds = [
                { data: playoffData.round16Matches, type: 'R16' },
                { data: playoffData.thirdPlaceMatches, type: '3P' },
                { data: playoffData.fifthPlaceMatches, type: '5P' }
              ];
              
              for (const specialRound of specialRounds) {
                if (specialRound.data && Array.isArray(specialRound.data)) {
                  const playoffMatches = specialRound.data.map(match => {
                    // Determine playoff round name
                    let roundName = '';
                    if (specialRound.type === 'R16') {
                      roundName = 'Åttondelsfinal';
                    } else if (specialRound.type === '3P') {
                      roundName = 'Match om 3:e plats';
                    } else if (specialRound.type === '5P') {
                      roundName = 'Match om 5:e plats';
                    }
                    
                    // Determine bracket name
                    const bracketName = match.bracket_type === 'loser' ? 'B' : 'A';
                    
                    return {
                      id: `playoff-${match.id}`,
                      league_name: league.name,
                      isPlayoff: true,
                      round_name: roundName,
                      bracket_name: bracketName,
                      team1_name: match.team1_details?.team?.name || 'TBD',
                      team2_name: match.team2_details?.team?.name || 'TBD',
                      team1_score: match.team1_score,
                      team2_score: match.team2_score,
                      track: match.track || 1,
                      date: match.match_date,
                      isPlayed: match.team1_score !== null && match.team2_score !== null && 
                               (match.team1_score > 0 || match.team2_score > 0),
                      group: bracketName // Use bracket as group
                    };
                  });
                  
                  allMatches.push(...playoffMatches);
                }
              }
            }
          } catch (error) {
            console.error(`Error fetching playoff matches for league ${league.id}:`, error);
          }
        }
        
        // Sort matches by date (newest first)
        const sortedMatches = allMatches.sort((a, b) => new Date(a.date) - new Date(b.date));
        setMatches(sortedMatches);
      } catch (error) {
        console.error('Error fetching matches:', error);
        setError('Failed to fetch matches');
      } finally {
        setLoading(false);
      }
    };
    
    fetchMatches();
  }, []);
  
  // Get today's date in YYYY-MM-DD format
  const todayObj = new Date();
  
  // Get all dates with matches and sort them chronologically
  const allDatesWithMatches = Object.keys(matchesByDate)
    .filter(date => matchesByDate[date].length > 0)
    .sort((a, b) => new Date(a) - new Date(b));
  
  // Find the date closest to today (or today itself)
  const findNextUpcomingDate = () => {
    if (allDatesWithMatches.length === 0) return 0;
    
    const todayTime = todayObj.getTime();
    let closestIndex = 0;
    let minDiff = Infinity;
    
    allDatesWithMatches.forEach((date, index) => {
      const diff = Math.abs(new Date(date).getTime() - todayTime);
      if (diff < minDiff) {
        minDiff = diff;
        closestIndex = index;
      }
    });
    
    return closestIndex;
  };
  
  // Get index of the closest date
  const closestDateIndex = findNextUpcomingDate();
  
  // Get 2 dates before and 2 dates after the closest date (respecting array bounds)
  const startIndex = Math.max(0, closestDateIndex - 2);
  const endIndex = Math.min(allDatesWithMatches.length, closestDateIndex + 3);
  const sortedDates = allDatesWithMatches.slice(startIndex, endIndex);
  
  // Set up auto-scrolling to the second date card on mount
  useEffect(() => {
    // Skip if there are no matches or slider isn't ready
    if (sortedDates.length === 0 || !sliderRef.current) return;
    
    // Set timeout to ensure DOM is fully rendered first
    const timeoutId = setTimeout(() => {
      // If we have at least two date cards, scroll to the second one
      if (sortedDates.length >= 2) {
        // Calculate how much to scroll to position the second date at the left edge
        // We need to scroll to the first date card + one date card width + spacing
        const dateCards = sliderRef.current.querySelectorAll('.date-card');
        
        if (dateCards.length >= 2) {
          // Get the position of the second date card relative to the container
          const containerRect = sliderRef.current.getBoundingClientRect();
          const secondCardRect = dateCards[1].getBoundingClientRect();
          
          // Calculate the scroll amount (position of second card relative to container)
          const scrollAmount = secondCardRect.left - containerRect.left;
          
          // Scroll to position the second date card at the left edge
          sliderRef.current.scrollLeft = scrollAmount;
        }
      }
    }, 100);
    
    return () => clearTimeout(timeoutId);
  }, [sortedDates]);
  
  
  // Format date for display
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    return {
      weekday: date.toLocaleDateString('sv-SE', { weekday: 'long' }),
      day: date.getDate(),
      month: date.toLocaleDateString('sv-SE', { month: 'long' }),
      year: date.getFullYear()
    };
  };
  
  // Format time for display
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' });
  };
  
  // Truncate team name to fit in the UI
  const truncateTeamName = (name) => {
    if (!name) return '';
    return name.length > 10 ? name.substring(0, 9) + '...' : name;
  };
  
  if (loading) {
    return (
      <div className="hidden lg:flex items-center justify-center p-4">
        <img
          src={require('../images/curling.gif')}
          alt="Loading..."
          className="w-12 h-12"
        />
      </div>
    );
  }
  
  // Don't render if there are no active leagues, no matches, or an error
  if (error || activeLeagues.length === 0 || sortedDates.length === 0) {
    return null;
  }
  
  
  // Only render desktop version
  return (
    <div className="game-header-container bg-gray-900 border-b border-white/10 pt-12">
      <div className="game-header-content max-w-7xl mx-auto relative py-8 pb-4">
        {/* Previous button - positioned further outside */}
        <button 
          onClick={() => {
            if (sliderRef.current) {
              sliderRef.current.scrollBy({ left: -300, behavior: 'smooth' });
            }
          }}
          className="absolute left-1 top-1/2 transform -translate-y-1/2 p-3 bg-gray-800/70 hover:bg-gray-700/90 rounded-lg transition-colors z-20"
          style={{ marginLeft: "-70px" }}
        >
          <ChevronLeft size={28} className="text-cyan-400" />
        </button>
        
        {/* Scrollable content with all dates and matches in a single row */}
        <div 
          ref={sliderRef}
          className="flex overflow-x-auto scrollbar-hide px-2 snap-x snap-mandatory scroll-smooth"
          style={{ scrollBehavior: 'smooth' }}
        >
          {sortedDates.map((date, dateIndex) => {
            const dateMatches = matchesByDate[date] || [];
            const formattedDate = formatDate(date);
            
            return (
              <React.Fragment key={date}>
                {/* Date card - smaller and vertically centered */}
                <div className="flex-shrink-0 w-28 h-32 bg-gray-800 rounded-xl p-2 mx-2 shadow-lg flex flex-col items-center justify-center snap-start date-card">
                  <h3 className="text-sm text-cyan-400 font-medium capitalize mb-1">{formattedDate.weekday}</h3>
                  <div className="flex items-center">
                    <span className="text-2xl font-bold text-white">{formattedDate.day}</span>
                    <div className="ml-1 text-left">
                      <p className="text-xs text-gray-300 capitalize">{formattedDate.month}</p>
                      <p className="text-xs text-gray-400">{formattedDate.year}</p>
                    </div>
                  </div>
                </div>
                
                {/* Match cards for this date */}
                {dateMatches.map((match) => (
                  <div 
                    key={match.id}
                    className="flex-shrink-0 w-72 bg-gray-800 rounded-xl p-3 pb-2 mx-2 shadow-lg hover:shadow-xl transition-all relative overflow-hidden snap-start"
                  >
                    {/* Background image - using CSS opacity for the background */}
                    <div className="absolute inset-0 bg-contain bg-center bg-no-repeat opacity-10" 
                        style={{backgroundImage: "url('/images/hck_logo.png')"}}></div>
                    
                    {/* Match header */}
                    <div className="flex items-center justify-between mb-1 relative z-10">
                      <span className="text-sm text-cyan-400">
                        {match.isPlayoff 
                          ? `${match.league_name} - ${match.round_name}` 
                          : match.league_name}
                      </span>
                      <div className="flex items-center space-x-2 bg-gray-700 px-2 py-1 rounded-md">
                        <span className="text-sm text-white font-medium">
                          {match.isPlayoff 
                            ? `Slutspel ${match.bracket_name}` 
                            : `Grupp ${match.group || match.team1_groupid?.charAt(0) || 'A'}`}
                        </span>
                      </div>
                    </div>
                    
                    {/* Match info */}
                    <div className="flex flex-col justify-center relative z-10">
                      <div className="flex justify-between items-center mb-2">
                        <div className="w-24 text-left">
                          <span className="text-sm text-gray-300">{truncateTeamName(match.team1_name)}</span>
                        </div>
                        
                        <div className="flex items-center">
                          <span className="text-2xl font-bold text-white">{match.team1_score !== null ? match.team1_score : '-'}</span>
                          <span className="text-2xl mx-2 text-white">-</span>
                          <span className="text-2xl font-bold text-white">{match.team2_score !== null ? match.team2_score : '-'}</span>
                        </div>
                        
                        <div className="w-24 text-right">
                          <span className="text-sm text-gray-300">{truncateTeamName(match.team2_name)}</span>
                        </div>
                      </div>
                      
                      {/* Track and Status info */}
                      <div className="flex justify-between items-center">
                        <div className="bg-gray-700 px-3 py-1 rounded-md">
                          <span className="text-xs text-white">Bana {match.track || '1'}</span>
                        </div>
                        
                        <div className={`px-3 py-1 rounded-md ${match.isPlayed ? 'bg-green-800' : 'bg-blue-800'}`}>
                          <span className="text-xs text-white">
                          {match.isPlayed ? 'Spelad' : formatTime(match.date)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </React.Fragment>
            );
          })}
        </div>
        
        {/* Next button - positioned further outside */}
        <button 
          onClick={() => {
            if (sliderRef.current) {
              sliderRef.current.scrollBy({ left: 300, behavior: 'smooth' });
            }
          }}
          className="absolute right-1 top-1/2 transform -translate-y-1/2 p-3 bg-gray-800/70 hover:bg-gray-700/90 rounded-lg transition-colors z-20"
          style={{ marginRight: "-70px" }}
        >
          <ChevronRight size={28} className="text-cyan-400" />
        </button>
      </div>
    </div>
  );
};

export default LatestMatchesSlider;