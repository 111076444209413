import React, { useState, useEffect, useRef } from 'react';

const MobileMatchesSlider = () => {
  const [activeLeagues, setActiveLeagues] = useState([]);
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const sliderRef = useRef(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  // Group matches by date
  const matchesByDate = matches.reduce((acc, match) => {
    const date = match.date.split('T')[0];
    if (!acc[date]) acc[date] = [];
    acc[date].push(match);
    return acc;
  }, {});

  // Date processing logic
  const todayObj = new Date();
  const allDatesWithMatches = Object.keys(matchesByDate)
    .filter(date => matchesByDate[date].length > 0)
    .sort((a, b) => new Date(a) - new Date(b));

  const findClosestDateIndex = () => {
    if (allDatesWithMatches.length === 0) return 0;
    const todayTime = todayObj.getTime();

    return allDatesWithMatches.reduce((closestIndex, date, index) => {
      const diff = Math.abs(new Date(date).getTime() - todayTime);
      return diff < closestIndex.minDiff
        ? { index, minDiff: diff }
        : closestIndex;
    }, { index: 0, minDiff: Infinity }).index;
  };

  const closestDateIndex = findClosestDateIndex();
  const startIndex = Math.max(0, closestDateIndex - 2);
  const endIndex = Math.min(allDatesWithMatches.length, closestDateIndex + 3);
  const sortedDates = allDatesWithMatches.slice(startIndex, endIndex);

  // Fetch matches effect
  useEffect(() => {
    const fetchMatches = async () => {
      try {
        setLoading(true);
        
        // Fetch active leagues
        const leaguesResponse = await fetch(process.env.REACT_APP_API_URL + '/api/leagues/');
        if (!leaguesResponse.ok) throw new Error('Failed to fetch leagues');

        const leaguesData = await leaguesResponse.json();
        const activeLeaguesData = leaguesData.filter(league => league.active);
        setActiveLeagues(activeLeaguesData);
        
        // If no active leagues, stop here
        if (activeLeaguesData.length === 0) {
          setLoading(false);
          return;
        }

        const allMatches = [];
        
        for (const league of activeLeaguesData) {
          try {
            const matchesResponse = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${league.id}/matches/`);
            if (matchesResponse.ok) {
              const matchesData = await matchesResponse.json();
              const matchesWithDetails = matchesData.map(match => ({
                ...match,
                league_name: league.name,
                isPlayed: match.team1_score !== null && match.team2_score !== null &&
                  (match.team1_score > 0 || match.team2_score > 0),
                isPlayoff: false
              }));
              allMatches.push(...matchesWithDetails);
            }
          } catch (error) {
            console.error(`Error fetching regular matches for league ${league.id}:`, error);
          }
          
          try {
            const playoffResponse = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${league.id}/playoffmatches/`);
            if (playoffResponse.ok) {
              const playoffData = await playoffResponse.json();
              
              // Process regular rounds (quarterfinals, semifinals, finals)
              const rounds = ['quarterfinals', 'semifinals', 'finals'];
              
              for (const round of rounds) {
                if (playoffData[round] && playoffData[round].matches) {
                  const playoffMatches = playoffData[round].matches.map(match => {
                    let roundName = '';
                    if (match.match_type === 'QF' || match.match_type === 'LR1') {
                      roundName = 'Kvartsfinal';
                    } else if (match.match_type === 'SF' || match.match_type === 'LR2') {
                      roundName = 'Semifinal';
                    } else if (match.match_type === 'F' || match.match_type === 'LF') {
                      roundName = 'Final';
                    } else if (match.match_type === '3P' || match.match_type === 'L3P') {
                      roundName = 'Match om 3:e plats';
                    } else if (match.match_type === '5P' || match.match_type === 'L5P') {
                      roundName = 'Match om 5:e plats';
                    } else if (match.match_type === 'R16' || match.match_type === 'LR16') {
                      roundName = 'Åttondelsfinal';
                    }
                    
                    const bracketName = match.bracket_type === 'loser' ? 'B' : 'A';
                    
                    return {
                      id: `playoff-${match.id}`,
                      league_name: league.name,
                      isPlayoff: true,
                      round_name: roundName,
                      bracket_type: bracketName,
                      team1_name: match.team1_details?.team?.name || 'TBD',
                      team2_name: match.team2_details?.team?.name || 'TBD',
                      team1_score: match.team1_score,
                      team2_score: match.team2_score,
                      track: match.track || 1,
                      date: match.match_date,
                      isPlayed: match.team1_score !== null && match.team2_score !== null && 
                               (match.team1_score > 0 || match.team2_score > 0),
                      group: bracketName
                    };
                  });
                  
                  allMatches.push(...playoffMatches);
                }
              }
              
              // Process special rounds that are directly arrays
              const specialRounds = [
                { data: playoffData.round16Matches, type: 'R16' },
                { data: playoffData.thirdPlaceMatches, type: '3P' },
                { data: playoffData.fifthPlaceMatches, type: '5P' }
              ];
              
              for (const specialRound of specialRounds) {
                if (specialRound.data && Array.isArray(specialRound.data)) {
                  const playoffMatches = specialRound.data.map(match => {
                    // Determine playoff round name
                    let roundName = '';
                    if (specialRound.type === 'R16') {
                      roundName = 'Åttondelsfinal';
                    } else if (specialRound.type === '3P') {
                      roundName = 'Match om 3:e plats';
                    } else if (specialRound.type === '5P') {
                      roundName = 'Match om 5:e plats';
                    }
                    
                    // Determine bracket name
                    const bracketName = match.bracket_type === 'loser' ? 'B' : 'A';
                    
                    return {
                      id: `playoff-${match.id}`,
                      league_name: league.name,
                      isPlayoff: true,
                      round_name: roundName,
                      bracket_type: bracketName,
                      team1_name: match.team1_details?.team?.name || 'TBD',
                      team2_name: match.team2_details?.team?.name || 'TBD',
                      team1_score: match.team1_score,
                      team2_score: match.team2_score,
                      track: match.track || 1,
                      date: match.match_date,
                      isPlayed: match.team1_score !== null && match.team2_score !== null && 
                               (match.team1_score > 0 || match.team2_score > 0),
                      group: bracketName
                    };
                  });
                  
                  allMatches.push(...playoffMatches);
                }
              }
            }
          } catch (error) {
            console.error(`Error fetching playoff matches for league ${league.id}:`, error);
          }
        }

        const sortedMatches = allMatches.sort((a, b) => new Date(a.date) - new Date(b.date));
        setMatches(sortedMatches);
      } catch (error) {
        console.error('Error fetching matches:', error);
        setError('Failed to fetch matches');
      } finally {
        setLoading(false);
      }
    };

    fetchMatches();
  }, []);

  // Scroll to second date effect
  useEffect(() => {
    if (sliderRef.current && sortedDates.length >= 2) {
      const secondDate = sortedDates[1];
      const dateElements = sliderRef.current.querySelectorAll('[data-date]');
      const targetElement = Array.from(dateElements).find(el => el.dataset.date === secondDate);

      if (targetElement) {
        sliderRef.current.scrollLeft = targetElement.offsetLeft;
      }
    }
  }, [sortedDates]);

  // LeagueSchedule-style swipe technique
  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider) return;

    const mouseDownHandler = (e) => {
      isDragging.current = true;
      startX.current = e.pageX - slider.offsetLeft;
      scrollLeft.current = slider.scrollLeft;
      slider.style.cursor = 'grabbing';
      slider.style.userSelect = 'none';
    };

    const mouseMoveHandler = (e) => {
      if (!isDragging.current) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX.current) * 2; // Faster scroll speed
      slider.scrollLeft = scrollLeft.current - walk;
    };

    const mouseUpHandler = () => {
      isDragging.current = false;
      slider.style.cursor = 'grab';
      slider.style.removeProperty('user-select');
    };

    const touchStartHandler = (e) => {
      isDragging.current = true;
      startX.current = e.touches[0].pageX - slider.offsetLeft;
      scrollLeft.current = slider.scrollLeft;
      // Prevents native browser behavior that would interfere
      e.preventDefault();
    };

    const touchMoveHandler = (e) => {
      if (!isDragging.current) return;
      e.preventDefault();
      const x = e.touches[0].pageX - slider.offsetLeft;
      const walk = (x - startX.current) * 2;
      slider.scrollLeft = scrollLeft.current - walk;
    };

    // Add all event listeners
    slider.addEventListener('mousedown', mouseDownHandler, { passive: false });
    slider.addEventListener('mousemove', mouseMoveHandler, { passive: false });
    slider.addEventListener('mouseup', mouseUpHandler);
    slider.addEventListener('mouseleave', mouseUpHandler);
    slider.addEventListener('touchstart', touchStartHandler, { passive: false });
    slider.addEventListener('touchmove', touchMoveHandler, { passive: false });
    slider.addEventListener('touchend', mouseUpHandler);

    return () => {
      // Remove all event listeners on cleanup
      slider.removeEventListener('mousedown', mouseDownHandler);
      slider.removeEventListener('mousemove', mouseMoveHandler);
      slider.removeEventListener('mouseup', mouseUpHandler);
      slider.removeEventListener('mouseleave', mouseUpHandler);
      slider.removeEventListener('touchstart', touchStartHandler);
      slider.removeEventListener('touchmove', touchMoveHandler);
      slider.removeEventListener('touchend', mouseUpHandler);
    };
  }, []);

  // Helper functions
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return {
      weekday: date.toLocaleDateString('sv-SE', { weekday: 'short' }),
      day: date.getDate(),
      month: date.toLocaleDateString('sv-SE', { month: 'short' }),
      year: date.getFullYear()
    };
  };

  const formatTime = (dateString) => {
    return new Date(dateString).toLocaleTimeString('sv-SE', {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const truncateTeamName = (name) => {
    return name?.length > 10 ? name.substring(0, 9) + '...' : name || '';
  };

  // Render states
  if (loading) {
    return (
      <div className="flex justify-center p-2">
        <img
          src={require('../images/curling.gif')}
          alt="Loading..."
          className="w-8 h-8"
        />
      </div>
    );
  }

  // Don't render if there are no active leagues, matches, or an error
  if (error || activeLeagues.length === 0 || sortedDates.length === 0) {
    return null;
  }

  return (
    <div className="mobile-match-slider bg-gray-800 border-b-2 border-gray-700 w-full z-50 fixed top-0 left-0 h-36 transform translate-y-0">
      {/* Added container shadow to create visual separation */}
      <div className="relative h-full shadow-md">
        <div
          ref={sliderRef}
          className="flex overflow-x-auto overflow-y-hidden py-3 px-2 scrollbar-hide snap-x snap-mandatory h-full items-center"
          style={{ 
            scrollBehavior: 'smooth',
            overscrollBehaviorY: 'none',
            touchAction: 'pan-x',
            cursor: 'grab' // Add grab cursor to indicate slider is draggable
          }}
        >
          {sortedDates.map((date) => {
            const dateMatches = matchesByDate[date] || [];
            const formattedDate = formatDate(date);

            return (
              <React.Fragment key={date}>
                {/* Added border and improved styling for date card */}
                <div
                  className="flex-shrink-0 w-20 h-28 bg-gray-900 rounded-lg p-1 mx-1 flex flex-col items-center justify-center text-center snap-start date-card border border-gray-700 shadow-lg"
                  data-date={date}
                >
                  <h3 className="text-xs text-cyan-400 font-medium capitalize">
                    {formattedDate.weekday}
                  </h3>
                  <div className="flex items-center justify-center">
                    <span className="text-xl font-bold text-white">
                      {formattedDate.day}
                    </span>
                    <div className="ml-1 text-left">
                      <p className="text-xs text-gray-300 capitalize">
                        {formattedDate.month}
                      </p>
                    </div>
                  </div>
                </div>

                {dateMatches.map((match) => (
                  // Added border styling to match cards for better visual distinction
                  <div
                    key={match.id}
                    className="flex-shrink-0 w-64 bg-gray-900 rounded-lg p-3 mx-1 relative overflow-hidden snap-start border border-gray-700 shadow-lg"
                  >
                    <div
                      className="absolute inset-0 bg-contain bg-center bg-no-repeat opacity-10"
                      style={{ backgroundImage: "url('/images/hck_logo.png')" }}
                    />

                    <div className="flex items-center justify-between mb-2 relative z-10">
                      <span className="text-xs text-cyan-400">
                        {match.isPlayoff 
                          ? `${match.league_name} - ${match.round_name}` 
                          : match.league_name}
                      </span>
                      <div className="flex items-center space-x-2 bg-gray-700 px-1.5 py-0.5 rounded">
                        <span className="text-xs text-white">
                          {match.isPlayoff 
                            ? `Slutspel ${match.bracket_type}` 
                            : `Grupp ${match.group || match.team1_groupid?.charAt(0) || 'A'}`}
                        </span>
                      </div>
                    </div>

                    <div className="flex flex-col justify-center relative z-10">
                      <div className="flex justify-between items-center mb-2">
                        <div className="w-20 text-left">
                          <span className="text-xs text-gray-300">
                            {truncateTeamName(match.team1_name)}
                          </span>
                        </div>

                        <div className="flex items-center">
                          <span className="text-lg font-bold text-white">
                            {match.team1_score ?? '-'}
                          </span>
                          <span className="text-lg mx-1.5 text-white">-</span>
                          <span className="text-lg font-bold text-white">
                            {match.team2_score ?? '-'}
                          </span>
                        </div>

                        <div className="w-20 text-right">
                          <span className="text-xs text-gray-300">
                            {truncateTeamName(match.team2_name)}
                          </span>
                        </div>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="bg-gray-700 px-2 py-0.5 rounded">
                          <span className="text-xs text-white">
                            Bana {match.track || '1'}
                          </span>
                        </div>

                        <div className={`px-2 py-0.5 rounded ${match.isPlayed ? 'bg-green-800' : 'bg-blue-800'}`}>
                          <span className="text-xs text-white">
                          {match.isPlayed ? 'Spelad' : formatTime(match.date)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MobileMatchesSlider;