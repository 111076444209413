import React from 'react';

const SubTabButton = ({ onClick, label, icon, isActive }) => (
  <button 
    className={`tablinks flex items-center px-4 py-2 rounded-t-lg transition-colors border-b-2 ${
      isActive 
        ? 'border-blue-600 text-blue-600 font-medium'
        : 'border-transparent text-gray-700 hover:text-blue-600 hover:bg-gray-50'
    }`}
    onClick={onClick}
  >
    {icon && <span className="mr-2">{icon}</span>}
    {label}
  </button>
);

export default SubTabButton;