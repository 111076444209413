import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Save, ArrowLeft, Trophy, Users, Calendar, ClipboardList, Clock, Info, User, Award } from 'lucide-react';

const ManageLeague = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [league, setLeague] = useState({
        name: '',
        organizer: '',
        leagueinfo: '',
        start_date: '',
        end_date: '',
        active: true,
        create_playoffs: false,
        has_loser_bracket: false,
        // Winner bracket dates
        round16_datetime: '',
        quarterfinal_datetime: '',
        semifinal_datetime: '',
        final_datetime: '',
        third_place_datetime: '',
        fifth_place_datetime: '',
        // Loser bracket dates
        loser_round16_datetime: '',
        loser_round1_datetime: '',
        loser_round2_datetime: '',
        loser_final_datetime: '',
        loser_third_place_datetime: '',
        loser_fifth_place_datetime: '',
        // Statistics
        teamscount: 0,
        participants: 0,
        gamesplayed: 0,
        mvp: ''
    });

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [dateTimeFields, setDateTimeFields] = useState({
        // Winner bracket
        round16: { date: '', time: '' },
        quarterfinal: { date: '', time: '' },
        semifinal: { date: '', time: '' },
        final: { date: '', time: '' },
        third_place: { date: '', time: '' },
        fifth_place: { date: '', time: '' },
        // Loser bracket
        loser_round16: { date: '', time: '' },
        loser_round1: { date: '', time: '' },
        loser_round2: { date: '', time: '' },
        loser_final: { date: '', time: '' },
        loser_third_place: { date: '', time: '' },
        loser_fifth_place: { date: '', time: '' }
    });

    const getCsrfToken = () => {
        const match = document.cookie.match(/csrftoken=([^;]+)/);
        return match ? match[1] : '';
    };

    useEffect(() => {
        const fetchLeague = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/leagues/${id}/`);
                if (!response.ok) throw new Error('Kunde inte hämta ligainformation');
                const data = await response.json();

                // Split datetime strings into separate date and time
                const splitDateTime = (dateTimeStr) => {
                    if (!dateTimeStr) return { date: '', time: '' };
                    const [datePart, timePart] = dateTimeStr.split('T');
                    return {
                        date: datePart,
                        time: timePart ? timePart.substring(0, 5) : ''  // Extract HH:mm
                    };
                };

                // Process all datetime fields
                const newDateTimeFields = { ...dateTimeFields };
                
                // Process winner bracket fields
                newDateTimeFields.round16 = splitDateTime(data.round16_datetime);
                newDateTimeFields.quarterfinal = splitDateTime(data.quarterfinal_datetime);
                newDateTimeFields.semifinal = splitDateTime(data.semifinal_datetime);
                newDateTimeFields.final = splitDateTime(data.final_datetime);
                newDateTimeFields.third_place = splitDateTime(data.third_place_datetime);
                newDateTimeFields.fifth_place = splitDateTime(data.fifth_place_datetime);
                
                // Process loser bracket fields
                newDateTimeFields.loser_round16 = splitDateTime(data.loser_round16_datetime);
                newDateTimeFields.loser_round1 = splitDateTime(data.loser_round1_datetime);
                newDateTimeFields.loser_round2 = splitDateTime(data.loser_round2_datetime);
                newDateTimeFields.loser_final = splitDateTime(data.loser_final_datetime);
                newDateTimeFields.loser_third_place = splitDateTime(data.loser_third_place_datetime);
                newDateTimeFields.loser_fifth_place = splitDateTime(data.loser_fifth_place_datetime);
                
                setDateTimeFields(newDateTimeFields);

                // Set league data
                setLeague({
                    name: data.name || '',
                    organizer: data.organizer || '',
                    leagueinfo: data.leagueinfo || '',
                    start_date: data.start_date || '',
                    end_date: data.end_date || '',
                    active: data.active ?? true,
                    create_playoffs: data.Playoff ?? false,
                    has_loser_bracket: data.has_loser_bracket ?? false,
                    // All datetime fields
                    round16_datetime: data.round16_datetime || '',
                    quarterfinal_datetime: data.quarterfinal_datetime || '',
                    semifinal_datetime: data.semifinal_datetime || '',
                    final_datetime: data.final_datetime || '',
                    third_place_datetime: data.third_place_datetime || '',
                    fifth_place_datetime: data.fifth_place_datetime || '',
                    loser_round16_datetime: data.loser_round16_datetime || '',
                    loser_round1_datetime: data.loser_round1_datetime || '',
                    loser_round2_datetime: data.loser_round2_datetime || '',
                    loser_final_datetime: data.loser_final_datetime || '',
                    loser_third_place_datetime: data.loser_third_place_datetime || '',
                    loser_fifth_place_datetime: data.loser_fifth_place_datetime || '',
                    // Statistics
                    teamscount: data.teamscount || 0,
                    participants: data.participants || 0,
                    gamesplayed: data.gamesplayed || 0,
                    mvp: data.mvp || ''
                });
            } catch (err) {
                setError('Ett fel uppstod vid hämtning av liga');
                console.error(err);
            }
        };

        if (id) fetchLeague();
        // eslint-disable-next-line react-hooks/exhaustive-deps
}, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Combine date and time fields for all datetime fields
            const combineDateTime = (date, time) => {
                return date && time ? `${date}T${time}` : '';
            };

            const submissionData = {
                name: league.name,
                organizer: league.organizer,
                leagueinfo: league.leagueinfo,
                start_date: league.start_date,
                end_date: league.end_date,
                active: league.active,
                create_playoffs: league.create_playoffs,
                has_loser_bracket: league.has_loser_bracket,
                teamscount: parseInt(league.teamscount) || 0,
                participants: parseInt(league.participants) || 0,
                gamesplayed: parseInt(league.gamesplayed) || 0,
                mvp: league.mvp || ''
            };
    
            // Only include playoff dates if playoffs are enabled
            if (league.create_playoffs) {
                // Winner bracket dates
                submissionData.round16_datetime = combineDateTime(dateTimeFields.round16.date, dateTimeFields.round16.time);
                submissionData.quarterfinal_datetime = combineDateTime(dateTimeFields.quarterfinal.date, dateTimeFields.quarterfinal.time);
                submissionData.semifinal_datetime = combineDateTime(dateTimeFields.semifinal.date, dateTimeFields.semifinal.time);
                submissionData.final_datetime = combineDateTime(dateTimeFields.final.date, dateTimeFields.final.time);
                submissionData.third_place_datetime = combineDateTime(dateTimeFields.third_place.date, dateTimeFields.third_place.time);
                submissionData.fifth_place_datetime = combineDateTime(dateTimeFields.fifth_place.date, dateTimeFields.fifth_place.time);
    
                // Only include loser bracket dates if loser bracket is enabled
                if (league.has_loser_bracket) {
                    submissionData.loser_round16_datetime = combineDateTime(dateTimeFields.loser_round16.date, dateTimeFields.loser_round16.time);
                    submissionData.loser_round1_datetime = combineDateTime(dateTimeFields.loser_round1.date, dateTimeFields.loser_round1.time);
                    submissionData.loser_round2_datetime = combineDateTime(dateTimeFields.loser_round2.date, dateTimeFields.loser_round2.time);
                    submissionData.loser_final_datetime = combineDateTime(dateTimeFields.loser_final.date, dateTimeFields.loser_final.time);
                    submissionData.loser_third_place_datetime = combineDateTime(dateTimeFields.loser_third_place.date, dateTimeFields.loser_third_place.time);
                    submissionData.loser_fifth_place_datetime = combineDateTime(dateTimeFields.loser_fifth_place.date, dateTimeFields.loser_fifth_place.time);
                } else {
                    // If loser bracket is disabled, explicitly set these to null
                    submissionData.loser_round16_datetime = null;
                    submissionData.loser_round1_datetime = null;
                    submissionData.loser_round2_datetime = null;
                    submissionData.loser_final_datetime = null;
                    submissionData.loser_third_place_datetime = null;
                    submissionData.loser_fifth_place_datetime = null;
                }
            } else {
                // If playoffs are disabled, explicitly set all playoff datetime fields to null
                submissionData.round16_datetime = null;
                submissionData.quarterfinal_datetime = null;
                submissionData.semifinal_datetime = null;
                submissionData.final_datetime = null;
                submissionData.third_place_datetime = null;
                submissionData.fifth_place_datetime = null;
                
                submissionData.loser_round16_datetime = null;
                submissionData.loser_round1_datetime = null;
                submissionData.loser_round2_datetime = null;
                submissionData.loser_final_datetime = null;
                submissionData.loser_third_place_datetime = null;
                submissionData.loser_fifth_place_datetime = null;
            }
    
            console.log("Submitting data:", submissionData);

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/leagues/manage/${id}/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCsrfToken(),
                    'Authorization': `Token ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(submissionData),
                credentials: 'include'
            });

            if (!response.ok) throw new Error('Kunde inte uppdatera ligan');
            setSuccess('Ligan har uppdaterats');
        } catch (err) {
            setError('Ett fel uppstod vid uppdatering av ligan');
            console.error(err);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setLeague(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked :
                type === 'number' ? (value === '' ? 0 : Number(value)) :
                    value
        }));
    };

    const handleDateTimeChange = (field, subfield, value) => {
        setDateTimeFields(prev => ({
            ...prev,
            [field]: {
                ...prev[field],
                [subfield]: value
            }
        }));
    };

    // Helper component for date-time input pairs
    const DateTimeInputPair = ({ field, label, isRequired = false }) => (
        <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
                <Trophy className="inline-block w-4 h-4 mr-1" />
                {label} {isRequired && <span className="text-red-500">*</span>}
            </label>
            <div className="flex gap-2">
                <div className="flex-1">
                    <div className="flex items-center">
                        <Calendar className="w-4 h-4 mr-2 text-gray-500" />
                        <input
                            type="date"
                            value={dateTimeFields[field].date}
                            onChange={(e) => handleDateTimeChange(field, 'date', e.target.value)}
                            required={isRequired}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                </div>
                <div className="flex-1">
                    <div className="flex items-center">
                        <Clock className="w-4 h-4 mr-2 text-gray-500" />
                        <input
                            type="time"
                            value={dateTimeFields[field].time}
                            onChange={(e) => handleDateTimeChange(field, 'time', e.target.value)}
                            required={isRequired}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                </div>
            </div>
        </div>
    );


    return (
        <div className="max-w-6xl mx-auto py-16 p-4">
            <div className="bg-white rounded-xl shadow-lg p-6">
                <div className="mb-6 pt-5">
                    <h1 className="text-2xl font-bold mb-2 flex items-center">
                        <Trophy className="w-6 h-6 mr-2 text-blue-600" />
                        Hantera Tävling
                    </h1>
                    <div className="h-1 w-20 bg-gray-500"></div>
                </div>

                <form onSubmit={handleSubmit} className="space-y-6">
                    {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">{error}</div>}
                    {success && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded">{success}</div>}

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                                <Trophy className="w-4 h-4 mr-1 text-gray-600" />
                                Namn
                            </label>
                            <input
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                name="name"
                                value={league.name}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                                <User className="w-4 h-4 mr-1 text-gray-600" />
                                Organisatör
                            </label>
                            <input
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                name="organizer"
                                value={league.organizer}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                            <Info className="w-4 h-4 mr-1 text-gray-600" />
                            Ligainformation
                        </label>
                        <textarea
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-24"
                            name="leagueinfo"
                            value={league.leagueinfo}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                                <Calendar className="w-4 h-4 mr-1 text-gray-600" />
                                Startdatum
                            </label>
                            <input
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                type="date"
                                name="start_date"
                                value={league.start_date}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                                <Calendar className="w-4 h-4 mr-1 text-gray-600" />
                                Slutdatum
                            </label>
                            <input
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                type="date"
                                name="end_date"
                                value={league.end_date}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="flex flex-col space-y-2">
                        <div className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                id="active"
                                className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                name="active"
                                checked={league.active}
                                onChange={handleChange}
                            />
                            <label htmlFor="active" className="text-sm font-medium text-gray-700">
                                Aktiv
                            </label>
                        </div>
                        
                        <div className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                id="create_playoffs"
                                className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                name="create_playoffs"
                                checked={league.create_playoffs}
                                onChange={handleChange}
                            />
                            <label htmlFor="create_playoffs" className="text-sm font-medium text-gray-700">
                                A-Slutspel
                            </label>
                        </div>
                        
                        <div className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                id="has_loser_bracket"
                                className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                name="has_loser_bracket"
                                checked={league.has_loser_bracket}
                                onChange={handleChange}
                            />
                            <label htmlFor="has_loser_bracket" className="text-sm font-medium text-gray-700">
                                B-Slutspel
                            </label>
                        </div>
                    </div>

                    {/* A-Slutspel Section */}
                    {league.create_playoffs && (
                        <div className="space-y-4 bg-gray-50 p-4 rounded-lg">
                            <h3 className="text-lg font-medium text-gray-700 flex items-center">
                                <Trophy className="w-5 h-5 mr-2 text-blue-600" />
                                A-Slutspel
                            </h3>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                <DateTimeInputPair field="round16" label="Åttondelsfinal" />
                                <DateTimeInputPair field="quarterfinal" label="Kvartsfinal" />
                                <DateTimeInputPair field="semifinal" label="Semifinal" />
                                <DateTimeInputPair field="final" label="Final" />
                                <DateTimeInputPair field="third_place" label="Match om 3:e plats" />
                                <DateTimeInputPair field="fifth_place" label="Match om 5:e plats" />
                            </div>
                        </div>
                    )}

                    {/* B-Slutspel Section */}
                    {league.create_playoffs && league.has_loser_bracket && (
                        <div className="space-y-4 bg-gray-50 p-4 rounded-lg">
                            <h3 className="text-lg font-medium text-gray-700 flex items-center">
                                <Trophy className="w-5 h-5 mr-2 text-blue-600" />
                                B-Slutspel
                            </h3>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                <DateTimeInputPair field="loser_round16" label="Åttondelsfinal" />
                                <DateTimeInputPair field="loser_round1" label="Kvartsfinal" />
                                <DateTimeInputPair field="loser_round2" label="Semifinal" />
                                <DateTimeInputPair field="loser_final" label="Final" />
                                <DateTimeInputPair field="loser_third_place" label="Match om 3:e plats" />
                                <DateTimeInputPair field="loser_fifth_place" label="Match om 5:e plats" />
                            </div>
                        </div>
                    )}

                    {/* Statistics Section */}
                    <div className="space-y-4 bg-gray-50 p-4 rounded-lg">
                        <h3 className="text-lg font-medium text-gray-700 flex items-center">
                            <Award className="w-5 h-5 mr-2 text-blue-600" />
                            Statistik
                        </h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                                    <Users className="w-4 h-4 mr-1 text-gray-600" />
                                    Antal lag
                                </label>
                                <input
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    type="number"
                                    name="teamscount"
                                    value={league.teamscount}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                                    <Users className="w-4 h-4 mr-1 text-gray-600" />
                                    Antal deltagare
                                </label>
                                <input
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    type="number"
                                    name="participants"
                                    value={league.participants}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                                    <ClipboardList className="w-4 h-4 mr-1 text-gray-600" />
                                    Spelade matcher
                                </label>
                                <input
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    type="number"
                                    name="gamesplayed"
                                    value={league.gamesplayed}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                                    <Trophy className="w-4 h-4 mr-1 text-gray-600" />
                                    MVP
                                </label>
                                <input
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    name="mvp"
                                    value={league.mvp}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end space-x-4 pt-4">
                        <button
                            type="button"
                            onClick={() => navigate(-1)}
                            className="flex items-center px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <ArrowLeft className="w-4 h-4 mr-2" />
                            Avbryt
                        </button>
                        <button
                            type="submit"
                            className="flex items-center px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <Save className="w-4 h-4 mr-2" />
                            Spara ändringar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ManageLeague;