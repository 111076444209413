import React, { useState, useEffect } from 'react';
import { X, TrendingUp, Calendar, Clock, Users, Award, Activity, Mail, Phone, UserRound } from 'lucide-react';
import hckLogo from '../../images/HCK.png';

const TeamPopup = ({ team, matches, onClose, leagueId }) => {
  const [activeTab, setActiveTab] = useState('overview');
  const [fullTeam, setFullTeam] = useState(null);
  const [isLoadingTeam, setIsLoadingTeam] = useState(false);
  const [teamError, setTeamError] = useState(null);

  // Fetch team details including members
  useEffect(() => {
    const fetchTeamDetails = async () => {
      if (!team || !team.id || !leagueId) return;
      
      setIsLoadingTeam(true);
      try {
        // Use the same endpoint that Teams.js uses but filter for this specific team
        const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/teams/?group=${team.group}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch team details: ${response.status}`);
        }
        
        const teams = await response.json();
        
        // Find the specific team that matches our current team id
        const foundTeam = teams.find(t => t.team.id === team.id);
        if (foundTeam) {
          //console.log("Found full team details:", foundTeam);
          setFullTeam(foundTeam);
        } else {
          console.warn("Team not found in the response");
          setTeamError("Kunde inte hitta laginformation");
        }
      } catch (error) {
        console.error('Error fetching team details:', error);
        setTeamError('Kunde inte hämta laginformation');
      } finally {
        setIsLoadingTeam(false);
      }
    };

    fetchTeamDetails();
  }, [team, leagueId]);

  const calculateTeamForm = (matches, teamName) => {
    if (!matches) return [];

    const playedMatches = matches.filter(match =>
      match.status === 'completed' &&
      (match.home_score !== 0 || match.away_score !== 0) &&
      match.home_score !== null &&
      match.away_score !== null
    );

    const sortedMatches = playedMatches.sort((a, b) =>
      new Date(b.date) - new Date(a.date)
    );

    const recentMatches = sortedMatches.slice(0, 5);

    return recentMatches.map(match => {
      const isHomeTeam = match.home_team.name === teamName;
      const teamScore = isHomeTeam ? match.home_score : match.away_score;
      const opponentScore = isHomeTeam ? match.away_score : match.home_score;

      if (teamScore > opponentScore) return 'W';
      if (teamScore < opponentScore) return 'L';
      return 'D';
    }).reverse();
  };

  const getFormColor = (result) => {
    switch (result) {
      case 'W': return 'bg-green-500';
      case 'L': return 'bg-red-500';
      case 'D': return 'bg-yellow-500';
      default: return 'bg-gray-300';
    }
  };

  const teamForm = calculateTeamForm(matches, team.name);

  // Calculate team stats
  const calculateTeamStats = () => {
    let wins = 0;
    let losses = 0;
    let draws = 0;
    let totalStonesFor = 0;
    let totalStonesAgainst = 0;

    const playedMatches = matches.filter(match =>
      match.status === 'completed' &&
      match.home_score !== null &&
      match.away_score !== null
    );

    playedMatches.forEach(match => {
      const isHomeTeam = match.home_team.name === team.name;
      const teamScore = isHomeTeam ? match.home_score : match.away_score;
      const opponentScore = isHomeTeam ? match.away_score : match.home_score;

      totalStonesFor += teamScore;
      totalStonesAgainst += opponentScore;

      if (teamScore > opponentScore) wins++;
      else if (teamScore < opponentScore) losses++;
      else draws++;
    });

    return {
      played: playedMatches.length,
      wins,
      losses,
      draws,
      stoneDifference: totalStonesFor - totalStonesAgainst,
      totalStonesFor,
      totalStonesAgainst,
      winPercentage: playedMatches.length > 0 ? Math.round((wins / playedMatches.length) * 100) : 0
    };
  };

  const teamStats = calculateTeamStats();

  // Get the latest PLAYED matches for overview (with actual results, not 0-0)
  const getLatestMatches = () => {
    if (!matches) return [];
    
    // Filter for completed matches with actual results (not 0-0)
    const playedMatches = matches.filter(match => 
      match.status === 'completed' && 
      match.home_score !== null && 
      match.away_score !== null && 
      (match.home_score > 0 || match.away_score > 0)
    );
    
    // Sort by date, newest first
    return playedMatches
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(0, 3);
  };

  const latestMatches = getLatestMatches();

  const MatchCard = ({ match, teamName }) => (
    <div className="bg-white rounded-lg shadow border border-gray-100 p-4 hover:shadow-md transition-all">
      {/* Match date */}
      <div className="flex justify-between items-center mb-3 text-xs text-gray-500">
        <span>
          {new Date(match.date).toLocaleDateString('sv-SE')}
        </span>
        <span className="px-2 py-1 bg-gray-100 rounded-full">
          {match.round_number ? `Omgång ${match.round_number}` : 'Match'}
        </span>
      </div>

      {/* Teams and scores */}
      <div className="flex flex-col">
        <div className="flex justify-between items-center mb-2">
          <div className={`font-medium ${match.home_team.name === teamName ? 'text-blue-700' : 'text-gray-700'}`}>
            {match.home_team.name}
          </div>
          <div className="font-bold text-lg px-3 py-1 bg-gray-100 rounded-md">
            {match.status === 'completed' ? match.home_score : '-'}
          </div>
        </div>

        <div className="flex justify-between items-center">
          <div className={`font-medium ${match.away_team.name === teamName ? 'text-blue-700' : 'text-gray-700'}`}>
            {match.away_team.name}
          </div>
          <div className="font-bold text-lg px-3 py-1 bg-gray-100 rounded-md">
            {match.status === 'completed' ? match.away_score : '-'}
          </div>
        </div>
      </div>

      {/* Status */}
      <div className="mt-3 flex justify-end">
        <span className={`text-xs px-2 py-1 rounded-full ${match.status === 'completed'
            ? 'bg-green-100 text-green-700'
            : 'bg-blue-100 text-blue-700'
          }`}>
          {match.status === 'completed' ? 'Spelad' : 'Kommande'}
        </span>
      </div>
    </div>
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        {/* Header with team name and close button */}
        <div className="bg-gradient-to-r from-gray-800 to-gray-700 rounded-t-xl p-4 text-white flex justify-between items-center relative overflow-hidden">
          <div className="absolute top-0 right-0 w-32 h-32 bg-white opacity-5 rounded-full -mr-16 -mt-16"></div>
          <div className="absolute bottom-0 left-0 w-24 h-24 bg-white opacity-5 rounded-full -ml-12 -mb-12"></div>

          <div className="relative z-10 flex items-center">
            <div className="rounded-full w-10 h-10 flex items-center justify-center mr-3">
              <img src={hckLogo} alt="HCK logo" className="w-7 h-7 object-contain" />
            </div>
            <h2 className="text-xl font-bold">{team.name}</h2>
          </div>

          <button
            onClick={onClose}
            className="relative z-10 p-1 hover:bg-gray-700 rounded-full transition-colors"
          >
            <X className="w-6 h-6 text-white" />
          </button>
        </div>

        {/* Tabs Navigation */}
        <div className="border-b border-gray-200">
          <div className="flex">
            <button
              onClick={() => setActiveTab('overview')}
              className={`px-4 py-3 font-medium text-sm flex items-center gap-2 ${activeTab === 'overview'
                  ? 'border-b-2 border-blue-500 text-blue-600'
                  : 'text-gray-500 hover:text-gray-700'
                }`}
            >
              <Award className="w-4 h-4" />
              <span>Översikt</span>
            </button>

            <button
              onClick={() => setActiveTab('matches')}
              className={`px-4 py-3 font-medium text-sm flex items-center gap-2 ${activeTab === 'matches'
                  ? 'border-b-2 border-blue-500 text-blue-600'
                  : 'text-gray-500 hover:text-gray-700'
                }`}
            >
              <Calendar className="w-4 h-4" />
              <span>Matcher</span>
            </button>

            <button
              onClick={() => setActiveTab('members')}
              className={`px-4 py-3 font-medium text-sm flex items-center gap-2 ${activeTab === 'members'
                  ? 'border-b-2 border-blue-500 text-blue-600'
                  : 'text-gray-500 hover:text-gray-700'
                }`}
            >
              <Users className="w-4 h-4" />
              <span>Medlemmar</span>
            </button>
          </div>
        </div>

        {/* Tab Content */}
        <div className="p-5">
          {/* Overview Tab */}
          {activeTab === 'overview' && (
            <div className="space-y-5">
              {/* Team form */}
              <div className="flex items-center gap-3 p-4 bg-gray-50 rounded-lg">
                <TrendingUp className="w-5 h-5 text-blue-600" />
                <div>
                  <h3 className="font-medium text-gray-900 mb-1">Senaste form</h3>
                  <div className="flex gap-1">
                    {teamForm.length > 0 ? (
                      teamForm.map((result, index) => (
                        <span
                          key={index}
                          className={`w-7 h-7 ${getFormColor(result)} rounded-full flex items-center justify-center text-white text-sm font-bold`}
                        >
                          {result}
                        </span>
                      ))
                    ) : (
                      <span className="text-sm text-gray-500">Ingen matchhistorik</span>
                    )}
                  </div>
                </div>
              </div>

              {/* Team statistics */}
              <div>
                <h3 className="font-medium text-gray-900 mb-3 flex items-center">
                  <Activity className="w-5 h-5 mr-2 text-blue-600" />
                  Statistik
                </h3>

                <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
                  <div className="bg-gray-50 rounded-lg p-3 text-center">
                    <p className="text-xs text-gray-500 mb-1">Matcher</p>
                    <p className="text-lg font-bold text-gray-900">{teamStats.played}</p>
                  </div>

                  <div className="bg-green-50 rounded-lg p-3 text-center">
                    <p className="text-xs text-green-700 mb-1">Vinster</p>
                    <p className="text-lg font-bold text-green-800">{teamStats.wins}</p>
                  </div>

                  <div className="bg-red-50 rounded-lg p-3 text-center">
                    <p className="text-xs text-red-700 mb-1">Förluster</p>
                    <p className="text-lg font-bold text-red-800">{teamStats.losses}</p>
                  </div>

                  <div className="bg-blue-50 rounded-lg p-3 text-center">
                    <p className="text-xs text-blue-700 mb-1">Stenskillnad</p>
                    <p className={`text-lg font-bold ${teamStats.stoneDifference > 0 ? 'text-green-600' :
                        teamStats.stoneDifference < 0 ? 'text-red-600' : 'text-gray-600'
                      }`}>
                      {teamStats.stoneDifference > 0 ? '+' : ''}{teamStats.stoneDifference}
                    </p>
                  </div>
                </div>
              </div>

              {/* Latest matches preview */}
              <div>
                <h3 className="font-medium text-gray-900 mb-3 flex items-center">
                  <Calendar className="w-5 h-5 mr-2 text-blue-600" />
                  Senaste spelade matcher
                </h3>

                <div className="space-y-4">
                  {latestMatches.length > 0 ? (
                    latestMatches.map((match, index) => (
                      <MatchCard key={`latest-${index}`} match={match} teamName={team.name} />
                    ))
                  ) : (
                    <div className="text-center py-4 bg-gray-50 rounded-lg">
                      <p className="text-gray-500">Inga spelade matcher</p>
                    </div>
                  )}
                </div>

                <button
                  className="mt-3 text-sm text-blue-600 hover:text-blue-800 flex items-center"
                  onClick={() => setActiveTab('matches')}
                >
                  <span>Se alla matcher</span>
                  <svg className="w-4 h-4 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M5 12h14M12 5l7 7-7 7" />
                  </svg>
                </button>
              </div>
            </div>
          )}

          {/* Matches Tab */}
          {activeTab === 'matches' && (
            <div className="space-y-4">
              <div className="flex items-center gap-3 px-3 py-2 bg-blue-50 rounded-lg mb-4">
                <Clock className="w-4 h-4 text-blue-600" />
                <p className="text-sm text-blue-800">
                  Visar {matches.length} matcher för {team.name}
                </p>
              </div>

              <div className="grid grid-cols-1 gap-3 max-h-[50vh] overflow-y-auto pr-2">
                {matches.map((match) => (
                  <MatchCard key={match.id} match={match} teamName={team.name} />
                ))}
              </div>
            </div>
          )}

          {/* Members Tab */}
          {activeTab === 'members' && (
            <div className="space-y-4">
              <div className="flex items-center justify-between mb-4">
                <h3 className="font-medium text-gray-900 flex items-center">
                  <Users className="w-5 h-5 mr-2 text-blue-600" />
                  Lagmedlemmar
                </h3>
                <span className="text-sm bg-blue-100 text-blue-800 px-2 py-1 rounded-full">
                  {fullTeam?.team?.members?.length || 0} medlemmar
                </span>
              </div>

              {isLoadingTeam ? (
                <div className="flex justify-center py-8">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-700"></div>
                </div>
              ) : teamError ? (
                <div className="text-center py-8 bg-red-50 rounded-lg">
                  <p className="text-red-600">{teamError}</p>
                </div>
              ) : fullTeam?.team?.members?.length > 0 ? (
                <div>
                  <div className="mb-4 bg-blue-50 p-3 rounded-lg">
                    <h4 className="font-medium text-blue-800 mb-1 flex items-center">
                      <UserRound className="w-4 h-4 mr-2" />
                      Lagmedlemmar
                    </h4>
                    <p className="text-sm text-blue-700">
                      {fullTeam.team.members.length} registrerade spelare
                    </p>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    {fullTeam.team.members.map(member => (
                      <div
                        key={member.id}
                        className="bg-gray-50 rounded-lg p-3 hover:bg-gray-100 transition-colors"
                      >
                        <div className="flex items-start">
                          <div className="bg-gray-200 rounded-full w-10 h-10 flex items-center justify-center mr-3 flex-shrink-0">
                            <span className="font-semibold text-gray-700">
                              {member.first_name?.charAt(0) || ''}{member.last_name?.charAt(0) || ''}
                            </span>
                          </div>
                          <div>
                            <p className="font-medium text-gray-900">
                              {member.first_name} {member.last_name}
                            </p>
                            {member.role && (
                              <p className="text-sm text-gray-500">{member.role}</p>
                            )}
                            <div className="mt-1 space-y-1">
                              {member.email && (
                                <div className="flex items-center text-xs text-gray-500">
                                  <Mail className="w-3 h-3 mr-1" />
                                  {member.email}
                                </div>
                              )}
                              {member.phone && (
                                <div className="flex items-center text-xs text-gray-500">
                                  <Phone className="w-3 h-3 mr-1" />
                                  {member.phone}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="text-center py-8 bg-gray-50 rounded-lg">
                  <p className="text-gray-500">Ingen information om lagmedlemmar tillgänglig</p>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="bg-gray-50 rounded-b-xl p-4 text-center border-t border-gray-100">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition-colors"
          >
            Stäng
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeamPopup;