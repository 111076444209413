import React, { useState, useEffect } from 'react';
import { useAuth } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';
import Overview from './Overview/Overview';
import MatchesTab from './Matches/MatchesTab';
import LeaguesTab from './Leagues/LeaguesTab';
import TeamsTab from './Teams/TeamsTab';
import UsersTab from './Users/UsersTab';
import TabButton from './Common/TabButton';
import '../../css/style.css';
import { LayoutDashboard, Calendar, Trophy, Users, UserCog } from 'lucide-react';

const AdminPage = () => {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState('overview');
  const [activeSubTab, setActiveSubTab] = useState('CreateNewUser');
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect if user is not logged in or has no admin rights
    if (!user || (!user.is_staff && !user.permissions?.is_league_admin)) {
      navigate('/');
    }
  }, [user, navigate]);
  

  const getAvailableTabs = () => {
    if (user?.is_staff) {
      return [
        { id: 'overview', label: 'Översikt', icon: LayoutDashboard, defaultSubTab: 'Overview' },
        { id: 'matches', label: 'Matcher', icon: Calendar, defaultSubTab: 'MatchResults' },
        { id: 'leagues', label: 'Tävlingar', icon: Trophy, defaultSubTab: 'ActiveLeagues' },
        { id: 'teams', label: 'Lag', icon: Users, defaultSubTab: 'CreateNewTeams' },
        { id: 'users', label: 'Användare', icon: UserCog, defaultSubTab: 'CreateNewUser' }
      ];
    } else {
      // League Admin tabs
      return [
        { id: 'overview', label: 'Översikt', icon: LayoutDashboard, defaultSubTab: 'Overview' },
        { id: 'matches', label: 'Matcher', icon: Calendar, defaultSubTab: 'MatchResults' }
      ];
    }
  };

  const tabs = getAvailableTabs();

  const handleTabClick = (tabId, defaultSubTab) => {
    setActiveTab(tabId);
    setActiveSubTab(defaultSubTab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'overview':
        return <Overview 
          setActiveTab={setActiveTab} 
          setActiveSubTab={setActiveSubTab} 
        />;
      case 'matches':
        return <MatchesTab activeSubTab={activeSubTab} />;
      case 'leagues':
        return <LeaguesTab activeSubTab={activeSubTab} />;
      case 'teams':
        return <TeamsTab activeSubTab={activeSubTab} />;
      case 'users':
        return <UsersTab activeSubTab={activeSubTab} />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <div className="container mx-auto p-6 pt-20 max-w-7xl">
        {/* Hero section with gradient background */}
        <div className="bg-gradient-to-r from-gray-800 to-gray-700 rounded-xl shadow-lg p-5 text-white overflow-hidden mb-6 relative">
          <div className="absolute top-0 right-0 w-64 h-64 bg-white opacity-5 rounded-full -mr-32 -mt-32"></div>
          <div className="absolute bottom-0 left-0 w-40 h-40 bg-white opacity-5 rounded-full -ml-20 -mb-20"></div>
          <div className="relative z-10">
            <h1 className="text-2xl md:text-3xl font-bold">Administrationspanel</h1>
            <p className="text-sm text-blue-300 mt-1">
              {user?.is_staff ? 'Administratör' : 'Liga administratör'}
            </p>
          </div>
        </div>

        {/* Main content section */}
        <div className="bg-white rounded-xl shadow-lg p-6 mb-6">
          <div className="flex flex-wrap gap-2 mb-6 border-b border-gray-200 pb-4">
            {tabs.map(tab => (
              <TabButton
                key={tab.id}
                isActive={activeTab === tab.id}
                onClick={() => handleTabClick(tab.id, tab.defaultSubTab)}
                label={tab.label}
                icon={<tab.icon className="w-5 h-5 mr-2" />}
              />
            ))}
          </div>
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;