import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Footer from '../components/Footer';
import { Calendar, MapPin, AlertCircle, Printer, ArrowRight } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import SEO from '../components/SEO';

const getPlayoffRoundName = (matchType) => {
    switch (matchType) {
        case 'R16':
        case 'LR16':
            return 'Åttondelsfinal';
        case 'QF':
        case 'LR1':
            return 'Kvartsfinal';
        case 'SF':
        case 'LR2':
            return 'Semifinal';
        case 'F':
        case 'LF':
            return 'Final';
        case '3P':
        case 'L3P':
            return 'Match om 3:e plats';
        case '5P':
        case 'L5P':
            return 'Match om 5:e plats';
        default:
            return matchType;
    }
};

const LeagueSchedule = () => {
    const { leagueId } = useParams();
    const [leagueName, setLeagueName] = useState('');
    const [matchesByDate, setMatchesByDate] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortedDates, setSortedDates] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                setLoading(true);

                // Fetch league details
                try {
                    const response = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/`);
                    if (!response.ok) {
                        throw new Error('Failed to fetch league name');
                    }
                    const data = await response.json();
                    setLeagueName(data.name);
                } catch (error) {
                    console.error('Error fetching league name:', error);
                    setError(error.message);
                }

                // Fetch regular season matches
                const regularMatchesResponse = await fetch(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/matches/`);
                if (!regularMatchesResponse.ok) throw new Error('Network response was not ok');
                const regularMatchesData = await regularMatchesResponse.json();

                // Fetch playoff matches
                let playoffMatches = [];
                try {
                    const playoffResponse = await axios.get(process.env.REACT_APP_API_URL + `/api/leagues/${leagueId}/playoffmatches/`);

                    // Combine all playoff matches into a single array
                    const allPlayoffMatches = [
                        ...(playoffResponse.data.round16Matches || []),
                        ...(playoffResponse.data.quarterfinals?.matches || []),
                        ...(playoffResponse.data.semifinals?.matches || []),
                        ...(playoffResponse.data.finals?.matches || []),
                        ...(playoffResponse.data.thirdPlaceMatches || []),
                        ...(playoffResponse.data.fifthPlaceMatches || [])
                    ];

                    // Transform playoff matches to match the regular season format
                    playoffMatches = allPlayoffMatches.map(match => ({
                        date: match.match_date,
                        track: match.track,
                        team1_name: match.team1_details?.team?.name || 'TBD 1',
                        team2_name: match.team2_details?.team?.name || 'TBD 2',
                        team1_id: match.team1_details?.team?.id,
                        team2_id: match.team2_details?.team?.id,
                        is_playoff: true,
                        round: match.bracket_type === 'winner' ?
                            `${getPlayoffRoundName(match.match_type)} - A Slutspel` :
                            `${getPlayoffRoundName(match.match_type)} - B Slutspel`
                    }));
                } catch (error) {
                    console.error("Error fetching playoff data:", error);
                    // Continue even if playoff matches fail to load
                }

                // Combine and process all matches
                const allMatches = [...regularMatchesData, ...playoffMatches];
                const groupedMatches = processMatches(allMatches);
                setMatchesByDate(groupedMatches);

                // Create an array of all dates sorted chronologically
                const dates = Object.keys(groupedMatches).sort((a, b) => {
                    return new Date(a) - new Date(b);
                });
                setSortedDates(dates);
            } catch (error) {
                console.error('Error fetching league data:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchAllData();
    }, [leagueId]);

    const processMatches = (matches) => {
        const grouped = {};

        matches.forEach(match => {
            // Handle both regular matches (match.date) and playoff matches (match.match_date)
            const dateString = match.date;
            const date = new Date(dateString);

            // Format date to YYYY-MM-DD
            const dateStr = date.toLocaleDateString('sv-SE');

            // Format time to HH:mm
            const timeStr = date.toLocaleTimeString('sv-SE', {
                hour: '2-digit',
                minute: '2-digit'
            });

            if (!grouped[dateStr]) {
                grouped[dateStr] = {};
            }
            if (!grouped[dateStr][timeStr]) {
                grouped[dateStr][timeStr] = {
                    1: null,
                    2: null,
                    3: null,
                    4: null,
                    5: null,
                    6: null
                };
            }

            grouped[dateStr][timeStr][match.track] = {
                team1: match.team1_name,
                team2: match.team2_name,
                team1Id: match.team1_id,
                team2Id: match.team2_id,
                isPlayoff: match.is_playoff,
                round: match.round
            };
        });

        return grouped;
    };

    // Truncate team name for mobile to prevent overflow
    const truncateTeamName = (name, maxLength = 10) => {
        if (!name) return '';
        if (name.length <= maxLength) return name;
        return name.substring(0, maxLength - 1) + '…';
    };

    const renderMatchCell = (match) => {
        if (!match) {
            return (
                <td className="border border-gray-200 p-2 text-center text-gray-400">
                    -
                </td>
            );
        }

        return (
            <td className="border border-gray-200 p-0">
                <div className="h-full">
                    {match.isPlayoff && (
                        <div className="bg-green-600 text-white text-xs p-1 text-center">
                            {match.round}
                        </div>
                    )}
                    <div className="flex h-12 items-center justify-center text-center text-sm bg-red-800 text-white p-2">
                        {match.team1Id ? (
                            <a
                                className="hover:underline"
                                href={`/league/${leagueId}/teams?expandedTeam=${match.team1Id}`}
                                title={match.team1}
                            >
                                {isMobile ? truncateTeamName(match.team1) : match.team1}
                            </a>
                        ) : (
                            <span title={match.team1}>
                                {isMobile ? truncateTeamName(match.team1) : match.team1}
                            </span>
                        )}
                    </div>
                    <div className="flex h-12 items-center justify-center text-center text-sm bg-yellow-300 text-gray-800 p-2">
                        {match.team2Id ? (
                            <a
                                className="hover:underline"
                                href={`/league/${leagueId}/teams?expandedTeam=${match.team2Id}`}
                                title={match.team2}
                            >
                                {isMobile ? truncateTeamName(match.team2) : match.team2}
                            </a>
                        ) : (
                            <span title={match.team2}>
                                {isMobile ? truncateTeamName(match.team2) : match.team2}
                            </span>
                        )}
                    </div>
                </div>
            </td>
        );
    };

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toLocaleDateString('sv-SE', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };

    const handlePrint = () => {
        window.print();
    };

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <img
                    src={require('../images/curling.gif')}
                    alt="Loading..."
                    className="w-24 h-24"
                />
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="bg-white rounded-xl shadow-lg p-8 text-center max-w-xl">
                    <AlertCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />
                    <h2 className="text-2xl font-semibold text-gray-800 mb-2">Ett fel har uppstått</h2>
                    <p className="text-gray-600 mb-4">{error}</p>
                </div>
            </div>
        );
    }

    if (sortedDates.length === 0) {
        return (
            <div className="min-h-screen bg-gray-100 flex flex-col">
                <Helmet>
                    <title>{`Spelschema - ${leagueName}`} | SweepScore</title>
                    <meta name="description" content={`Se hela spelschemat för ${leagueName}. Följ matcher, tider och isbanor för turneringen.`} />
                </Helmet>

                <main className="flex-grow container mx-auto px-4 py-16 pt-24">
                    <div className="bg-gradient-to-r from-gray-800 to-gray-700 rounded-xl shadow-lg p-5 text-white overflow-hidden mb-6 relative">
                        <div className="absolute top-0 right-0 w-64 h-64 bg-white opacity-5 rounded-full -mr-32 -mt-32"></div>
                        <div className="absolute bottom-0 left-0 w-40 h-40 bg-white opacity-5 rounded-full -ml-20 -mb-20"></div>
                        <div className="relative z-10">
                            <div className="flex justify-between items-center">
                                <h1 className="text-2xl md:text-3xl font-bold flex items-center">
                                    <Calendar className="mr-2 h-6 w-6" /> Spelschema
                                </h1>

                                <span className="text-sm text-blue-300">{leagueName}</span>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white rounded-xl shadow-lg p-8 text-center">
                        <AlertCircle className="w-16 h-16 text-gray-400 mx-auto mb-4" />
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Inget spelschema tillgängligt</h2>
                        <p className="text-gray-600 max-w-lg mx-auto">
                            Det finns ännu inget spelschema tillgängligt för denna tävling.
                            Spelschemat kommer att visas när matcher har schemalagts.
                        </p>
                    </div>
                </main>
                <Footer />
            </div>
        );
    }

    return (
        <>
            <SEO
                title={`Spelschema ${leagueName} | Curlingtävling`}
                description={`Komplett spelschema för ${leagueName}. Planera dina besök och se när ditt favoritlag spelar sina curlingmatcher.`}
                keywords={`curlingschema, ${leagueName} spelschema, matchplanering curling, kommande curlingmatcher, curlingkalender`}
                canonicalUrl={`https://sweepscore.se/league/${leagueId}/schedule`}
            />

            <div className="min-h-screen bg-gray-100 flex flex-col">
                <Helmet>
                    <title>{`Spelschema - ${leagueName}`} | SweepScore</title>
                    <meta name="description" content={`Se hela spelschemat för ${leagueName}. Följ matcher, tider och isbanor för turneringen.`} />
                    <style type="text/css">
                        {`
                    @media print {
                        nav, footer, .no-print {
                            display: none !important;
                        }
                        .print-only {
                            display: block !important;
                        }
                        body {
                            background-color: white !important;
                        }
                        .page-break {
                            page-break-before: always;
                        }
                        .schedule-header {
                            position: fixed;
                            top: 0;
                            width: 100%;
                        }
                        .print-container {
                            padding-top: 0 !important;
                        }
                        .print-date-header {
                            background-color: #f3f4f6 !important;
                            color: black !important;
                            padding: 10px !important;
                            margin-top: 20px !important;
                            font-weight: bold !important;
                        }
                        table {
                            border-collapse: collapse !important;
                        }
                        th {
                            background-color: #1f2937 !important;
                            color: white !important;
                        }
                    }
                    `}
                    </style>
                </Helmet>

                <main className="flex-grow container mx-auto px-4 py-16 pt-28 lg:pt-32 print-container">
                    <div className="bg-gradient-to-r from-gray-800 to-gray-700 rounded-xl shadow-lg p-5 text-white overflow-hidden mb-6 relative no-print">
                        <div className="absolute top-0 right-0 w-64 h-64 bg-white opacity-5 rounded-full -mr-32 -mt-32"></div>
                        <div className="absolute bottom-0 left-0 w-40 h-40 bg-white opacity-5 rounded-full -ml-20 -mb-20"></div>
                        <div className="relative z-10">
                            <div className="flex justify-between items-center">
                                <h1 className="text-2xl md:text-3xl font-bold flex items-center">
                                    <Calendar className="mr-2 h-6 w-6" /> Spelschema
                                </h1>

                                <span className="text-sm text-blue-300">{leagueName}</span>
                            </div>
                        </div>
                    </div>

                    {/* Print-only header */}
                    <div className="hidden print-only">
                        <h1 className="text-2xl font-bold mb-4">Spelschema: {leagueName}</h1>
                        <p className="text-sm text-gray-600 mb-6">Utskrivet {new Date().toLocaleDateString('sv-SE')}</p>
                    </div>

                    <div className="bg-white rounded-xl shadow-lg p-6 mb-6">
                        <div className="flex justify-between items-center mb-4 no-print">
                            <h2 className="text-lg font-semibold text-gray-800 flex items-center">
                                <Calendar className="w-5 h-5 mr-2 text-blue-600" />
                                Fullständigt spelschema
                            </h2>

                            <button
                                onClick={handlePrint}
                                className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                            >
                                <Printer className="w-4 h-4 mr-2" />
                                Skriv ut
                            </button>
                        </div>

                        {/* Mobile hint */}
                        {isMobile && (
                            <div className="flex items-center gap-2 bg-blue-50 p-3 rounded-lg mb-4 no-print">
                                <ArrowRight className="w-5 h-5 text-blue-600" />
                                <p className="text-sm text-blue-700">
                                    Swipa i sidled för att se alla banor
                                </p>
                            </div>
                        )}

                        {sortedDates.map((date, index) => (
                            <div key={date} className={index > 0 ? 'mt-8' : ''}>
                                <h3 className="text-lg font-medium text-gray-800 bg-gray-100 p-3 rounded-t-lg print-date-header">
                                    {formatDate(date)}
                                </h3>

                                <div className="overflow-x-auto" style={{ WebkitOverflowScrolling: 'touch' }}>
                                    <table className="w-full table-fixed border-collapse" style={{ minWidth: isMobile ? '700px' : 'auto' }}>
                                        <thead>
                                            <tr className="bg-gray-800 text-white">
                                                <th className="w-20 py-2 px-3 text-left border border-gray-300 sticky left-0 z-10">
                                                    Tid
                                                </th>
                                                {[1, 2, 3, 4, 5, 6].map(sheet => (
                                                    <th key={sheet} className="py-2 border border-gray-300 w-24 md:w-auto">
                                                        Bana {sheet}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(matchesByDate[date])
                                                .sort((a, b) => a[0].localeCompare(b[0])) // Sort by time
                                                .map(([time, sheetMatches]) => (
                                                    <tr key={time}>
                                                        <td className="bg-gray-100 text-gray-800 font-medium p-2 border border-gray-300 sticky left-0 z-10">
                                                            {time}
                                                        </td>
                                                        {[1, 2, 3, 4, 5, 6].map(sheet => (
                                                            <React.Fragment key={sheet}>
                                                                {renderMatchCell(sheetMatches[sheet])}
                                                            </React.Fragment>
                                                        ))}
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Legend */}
                    <div className="bg-white rounded-xl shadow-lg p-6">
                        <h3 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
                            <MapPin className="w-5 h-5 mr-2 text-blue-600" />
                            Teckenförklaring
                        </h3>

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            <div className="flex items-center space-x-3">
                                <div className="w-8 h-8 bg-red-800"></div>
                                <span className="text-gray-700">Hemmalag</span>
                            </div>

                            <div className="flex items-center space-x-3">
                                <div className="w-8 h-8 bg-yellow-300"></div>
                                <span className="text-gray-700">Bortalag</span>
                            </div>

                            <div className="flex items-center space-x-3">
                                <div className="w-8 h-8 bg-green-600"></div>
                                <span className="text-gray-700">Slutspelsmatch</span>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        </>
    );
};

export default LeagueSchedule;